<template>
    <tr>
        <td>
            <span>{{ date }}</span>
            <span class="mgl_5">{{ time }}</span>
        </td>
        <td>{{ count }}</td>
        <td>{{ reason }}</td>
        <td class="txt_center">{{ requestUserName }}</td>
    </tr>
</template>


<script>
const moment = require('../../../../../../../../util/MPMoment')
export default {
    props: {
        item: Object
    },
    computed: {
        count: function() {
            if (this.item?.requestedAccountCount == undefined) { return '-' }
            return this.item?.requestedAccountCount
        },
        date: function() {
            if (this.item == undefined || this.item?.createdAt == undefined || this.item?.createdAt == '') { return '-' }
            return moment(this.item.createdAt).format('YYYY-MM-DD')
        },
        time: function() {
            if (this.item == undefined || this.item?.createdAt == undefined || this.item?.createdAt == '') { return '-' }
            return moment(this.item.createdAt).format('HH:mm')
        },
        reason: function() {
            if (this.item?.tuningReason == undefined || this.item?.tuningReason == '') { return '-' }
            if (this.item?.tuningReason == '직접입력') { return this.item.customTuningReason }
            return this.item.tuningReason
        },
        requestUserName: function() {
            if (this.item?.accountCountTuningRequesterName == undefined || this.item?.accountCountTuningRequesterName == '') { return '-' }
            return this.item.accountCountTuningRequesterName
        }
    }
}
</script>