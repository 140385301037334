<template>
    <tr>
        <td>
            <div class="flex_aic_spb gap_5">
                <button class="change_btn flex_aic_jcc" :disabled="isFirst" @click="action_Up">
                    <v-icon style="font-size: 24px;">mdi-arrow-up-bold</v-icon>
                </button>
                <button class="change_btn flex_aic_jcc" :disabled="isLast" @click="action_Down">
                    <v-icon style="font-size: 24px;">mdi-arrow-down-bold</v-icon>
                </button>
            </div>
        </td>
        <td>
            <select class="w_full" name="입력타입" id="" v-model="chatType">
                <option :value="100">text</option>
                <option :value="300">image</option>
            </select>
        </td>
        <td v-if="chatType==100">
            <textarea rows="5" name="" id="" class="w_full" placeholder="내용 입력" maxlength="1000" v-model="content"></textarea>
            <small class="red_txt" v-if="!isValidText">내용을 입력해 주세요.</small>
        </td>
        <td class="img_preview_wrap" v-if="chatType==300">
            <div class="flex_aic_spb">
                    <span v-for="(fileID, index) in fileIDs" :key="index">ID: {{ fileID._id }}</span>
                    <a class="btn btn_s btn_blue" @click="action_SelectFile">파일선택</a>
                    <FilePicker v-model="selectFile" v-bind:only-image="true" v-bind:is-multiple="false" v-bind:is-open="isShowFilePicker"/>
                    <img
                        class="img_preview"
                        :src="imagePath"
                        v-if="isValidFile"
                        style="max-width: 200px; max-height: 200px;"
                    >
                <small class="red_txt" v-if="!isValidFile">파일을 업로드해주세요</small>
            </div>
        </td>
        <td>
            <!-- TODO: File Upload -->
            <button class="delete_btn btn_gray_line w_full" @click="action_Delete">삭제</button>
        </td>
    </tr>
</template>


<script>
import FilePicker from '@/views/main/ShareComponents/FilePicker.vue'
import { FileUploadController } from '@/controller/FileUploadController'

export default {
    components: {
        FilePicker,
    },
    props: {
        index: Number,
        chat: Object,
        chatCount: Number,
    },
    data() {
        return {
            chatType: undefined,
            content: undefined,
            fileIDs: undefined,

            isShowFilePicker: false,
            selectFile: undefined,

            updateBlock: false
        }
    },
    watch: {
        chat: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.updateBlock = true
                this.chatType = newValue.chatType ?? 100
                this.content = newValue.content ?? undefined
                if (newValue.fileIDs == undefined || newValue.fileIDs === '') { this.fileIDs = [] }
                else { this.fileIDs = newValue.fileIDs }
                this.updateBlock = false
            }
        },
        chatType: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateValue()
            }
        },
        content: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateValue()
            }
        },
        fileIDs: { 
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue === oldValue) { return }
                this.updateValue()
            }
        },
        selectFile: {
            immediate: true,
            deep: true,
            handler: async function (newValue) {
                if (newValue == undefined) {
                    return
                }
                this.selectedFileViewOnly = newValue[0]?.name
                await this.changeImageFile(newValue)
                this.isShowFilePicker = false
            }
        }
    },
    computed: {
        isFirst: function() {
            if (this.index == 0) { return true }
            return false
        },
        isLast: function() {
            if (this.index == this.chatCount-1) { return true }
            return false
        },
        isValidText: function() {
            if (this.chatType == 100 && this.content == undefined || this.content == '') { return false }
            return true
        },
        isValidFile: function() {
            if (this.chatType == 300 && (this.fileIDs == undefined || this.fileIDs.length == 0)) { return false }
            return true
        },
        imagePath: function() {
            if (this.fileIDs == undefined || this.fileIDs.length == 0) { return }
            return this.fileIDs[0]?.paths[0]
        },
        hasImage: function() {
            return this.imagePath != undefined
        }
    },
    methods: {
        async changeImageFile(files) {
            if (files == undefined || files.length == 0) {
                return
            }
            this.isUploading = true
            let fileModel = await this.uploadFile(files[0])
            this.fileIDs = [fileModel]
            this.isUploading = false
        },
        async uploadFile(file) {
            return new Promise(function (resolve, reject) {
                let files = [file]
                FileUploadController.uploadFiles(files, [], async (results) => {
                    let datas = results.data
                    if (datas.length == 0) {
                        reject()
                        return
                    }

                    let result = datas[0]
                    if (result.data.code != 0) {
                        reject()
                        return
                    }
                    resolve(result.data.data)
                })
            })
        },

        // MARK: - Actions
        action_SelectFile() {
            this.selectFile = undefined
            this.isShowFilePicker = true
        },
        action_Up() {
            this.$emit('up', this.index)
        },
        action_Down() {
            this.$emit('down', this.index)
        },
        action_Delete() {
            this.$emit('deleteChat', this.index)
        },

        // MARK: - Emit
        updateValue() {
            if (this.updateBlock == true) { return }
            this.$emit('updateChat', {
                chatType: this.chatType,
                content: this.content,
                fileIDs: this.fileIDs,
                index: this.index
            })
        }
    }
}
</script>