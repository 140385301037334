<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="max_account_edit_pop_wrap">
            <div class="pop_title">
                <span class="title">최대 계정 수 변경</span>
                <a class="mgl_auto">
                    <v-icon :size="24" @click="action_Close">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="form_list">
                    <div class="title">
                        최대 계정 수<span class="red_txt">*</span>
                </div>
                    <div class="cont">
                        <input type="number" class="w_full" v-model="count">
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        사유<span class="red_txt">*</span>
                </div>
                    <div class="cont">
                        <select name="사유" id="" class="w_full" v-model="reason">
                            <option :value="undefined" disabled>--선택--</option>
                            <option value="최초 세팅">최초 세팅</option>
                            <option value="계정 추가 결제">계정 추가 결제</option>
                            <option value="서비스 제공">서비스 제공</option>
                            <option value="이용료 미납">삭제(이용료 미납)</option>
                            <option value="직접입력">직접입력</option>
                        </select>
                        <input class="input_s w_full mgt_5" type="text" placeholder="사유를 입력해 주세요." v-model="customTuningReason" v-if="isCustomTuningReason">
                        <small class="red_txt" v-if="isEmptyReason">사유를 선택해 주세요.</small>
                    </div>
                </div>
                <div class="form_list">
                    <div class="title">
                        수정자<span class="red_txt">*</span>
                </div>
                    <div class="cont">
                        <input class="input_s w_full" type="text" placeholder="이름" v-model="userName">
                        <small class="red_txt" v-if="isEmptyUserName">수정자를 입력해 주세요.</small>
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from 'vuex'

export default {
    props: {
        isShow: Boolean,
        hospitalID: String
    },
    data() {
        return {
            serviceSetting: undefined,

            count: undefined,
            reason: undefined,
            customTuningReason: undefined,
            userName: undefined,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue != true) { return }
                this.userName = this.realName
                this.loadServiceSetting()
            }
        }
    },
    computed: {
        ...mapState(['user']),
        realName: function() {
            return this.user?.realName ?? '-'
        },
        isCustomTuningReason: function() {
            return this.reason == '직접입력'
        },
        isEmptyReason: function() {
            if (this.reason == undefined || this.reason == '') { return true }
            if (this.isCustomTuningReason) {
                if (this.customTuningReason == undefined || this.customTuningReason == '') { return true }
            }
            return false
        },
        isEmptyUserName: function() {
            if (this.userName == undefined || this.userName == '') { return true }
            return false
        }
    },
    methods: {
        // MARK: - Network
        async loadServiceSetting() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API_V3.ServiceSetting.findOne({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.serviceSetting = result.data.data
            this.count = this.serviceSetting.maximumCreatableAccountCount ?? 5
        },

        // MARK: - Actions
        action_Close() { this.$emit('close') },
        async action_Save() {
            let model = {
                hospitalID: this.hospitalID,
                count: this.count,
                reason: this.reason,
                customTuningReason: this.customTuningReason,
                userName: this.userName
            }
            let result = await this.API_V3.ServiceSetting.changeAccountCount(model)
            if (result.data.code != 0) {
                alert(result)
                return
            }

            this.$emit('updateAccountCount', result?.data?.data?.maximumCreatableAccountCount)
            this.$emit('close')
        }
    }
}
</script>