<template>
    <div style="max-width:360px ;">
        <div class="flex_column gap_5 mgb_5">
            <span class="large_txt" style="font-size: 18px;">
                현재 설정된 치료태그
            </span>
            <small class="dgray_txt">
                이미 등록된 태그는 “설정됨”으로 표시됩니다. 설정된 태그를 삭제하는 경우 진행중인 알고리즘이 중단되며 그 외 다른 영향이 있을 수 있음.
            </small>
        </div>
        <li class="settings_list">
            <div class="settings_list_option tag_template_cont flex_column gap_0">
                <div class="tag_template_search flex_aic gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="태그명으로 검색" v-model="keyword" @keydown.enter="loadTreatmentCategory">
                    <button class="btn_s btn_blue_line" @click="loadTreatmentCategory">검색</button>
                </div>
                <ul class="h_scroll tag_template_wrap gap_10">
                    <TreatmentTagSettingHospitalListItem
                        v-for="(item, index) in allTags"
                        :key="index"
                        :item="item"
                        :index="index"
                        @removeTag="removeTag"
                    />
                </ul>
                <div class="tag_template_btn_wrap flex_aic_fle gap_5">
                    <button class="btn_s btn_blue" @click="action_Save" :disabled="!auth.v2HospitalManagement_w">저장</button>
                </div>
            </div>
        </li>
    </div>
</template>


<script>
import TreatmentTagSettingHospitalListItem from './TreatmentTagSettingHospitalListItem.vue'
import { convertEditorToReact } from '../../../../../../../../util/HTMLEditorConvertor'

export default {
    components: { TreatmentTagSettingHospitalListItem },
    props: {
        hospitalID: String,
        selectedTags: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            keyword: '',

            tags: [],
            removalTags: [],
        }
    },
    watch: {},
    computed: {
        filter: function() {
            return {
                hospitalID: this.hospitalID,
                keyword: this.keyword,
                isRemoved: false,
                isCommon: false,
            }
        },
        allTags: function() {
            return this.tags.concat(this.selectedTags)
        }
    },
    mounted() {
        this.loadTreatmentCategory()
    },
    methods: {
        // MARK: - Network
        async loadTreatmentCategory() {
            let result = await this.API_V3.TreatmentCategory.find(this.filter)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.tags = result.data.data
            this.removalTags = []
        },
        async createCategories(array) {
            for (let i=0 ; i<array.length ; i++) {
                let item = array[i]
                let convertedContent = convertEditorToReact(item.manualContent)
                let model = {
                    hospitalID: this.hospitalID,
                    categoryName: item.categoryName,
                    description: '',
                    paletteInde: 0,
                    manualContent: convertedContent,
                    fileID: item.fileID?._id,
                    isCommon: false,
                    isRemoved: false,
                }
                let result = await this.API_V3.TreatmentCategory.create(model)
                if (result.data.code != 0) {
                    alert(model.categoryName + ' 생성 실패 : ' + result.data.message)
                }
            }

        },
        async removeCategories(array) {
            for (let i=0 ; i<array.length ; i++) {
                let item = array[i]
                console.log('remove : ', item)
                if (item.hospitalID == undefined) { continue }
                let result = await this.API_V3.TreatmentCategory.remove({_id: item._id})
                if (result.data.code != 0) {
                    alert(item.categoryName + ' 삭제 실패 : ' + result.data.message)
                }
            }
        },

        // MARK: - Actions
        async action_Save() {
            let creatable = this.allTags.filter(item => item.hospitalID == undefined)
            await this.createCategories(creatable)
            await this.removeCategories(this.removalTags)

            this.loadTreatmentCategory()
            this.$emit('updateSelectedTags', [])
        },

        // MARK: - Emit
        removeTag(index) {
            console.log(index)
            if (index < this.tags.length) {
                this.removalTags.push(this.tags.splice(index, 1)[0])
            }
            else {
                let i = index - this.tags.length
                let tempArray = JSON.parse(JSON.stringify(this.selectedTags))
                tempArray.splice(i, 1)
                this.$emit('updateSelectedTags', tempArray)
            }
        },
    },
}
</script>