<template>
    <table class="h_setting_table">
        <thead>
            <tr>
                <th width="300" class="txt_left">병원명</th>
                <th width="300" class="txt_left">검색용 아이디</th>
                <th width="" class="txt_left">상담 가능 일시</th>
                <th width="100" class="txt_left">신청일</th>
                <th width="75">상태</th>
                <th width="80">갱신</th>
            </tr>
        </thead>
        <tr>
            <td colspan="6">검색된 병원이 없습니다.</td>
        </tr>
    </table>
</template>


<script>
export default {
    
}
</script>