<template>
    <div class="v2_content_wrap">
        <!-- 알림톡 선택 버튼 클릭 시 나타나는 팝업 -->
        
        <div class="page_title">
            <span>서비스 알림톡 설정</span>
        </div>

        <ul class="settings_wrap service_arlimtalk_settings flex_column pd_20 gap_20">
            <ServiceAlrimtalkItem
                :title="'치료태그 미입력 시 다음날'"
                :desc="'어제 고객관리에서 등록된 모든 고객에게 치료태그가 미입력 되었을 때 알림을 발송합니다.'"
                :type="'NON_TREATMENT_TAGS'"
            />
            <ServiceAlrimtalkItem
                :title="'치료태그 완료 시 다음날'"
                :desc="'어제 고객관리에서 등록된 고객 중 치료태그가 입력된 고객이 있을 때 알림을 발송합니다.'"
                :type="'COMPLATE_TREATMENT_TAGS'"
            />
            <ServiceAlrimtalkItem
                :title="'어제 입력된 고객이 없을 때'"
                :desc="'어제가 병원운영일이며, 고객관리에서 등록된 고객이 없는 경우 발송되는 알림입니다.'"
                :type="'NON_PATIENTS'"
            />
        </ul>
    </div>
</template>

<script>
import ServiceAlrimtalkItem from './Subviews/ServiceAlrimtalkItem.vue';
export default {
    components: {
        ServiceAlrimtalkItem,
    },
    props: {
        isShow: Boolean
    }
}</script>