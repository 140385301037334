<template>
    <div class="table_header_fix">
        <!-- 검색 데이터 없을 때 -->
        <table class="table_default" v-if="isEmpty">
            <thead>
                <tr>
                    <th width="160" class="txt_left">발신프로필/그룹</th>
                    <th width="160" class="txt_left">템플릿명</th>
                    <th width="160" class="txt_left">템플릿코드</th>
                    <th width="75" class="txt_left">검수상태</th>
                    <th width="140" class="txt_left">등록일시</th>
                    <th width="140" class="txt_left">수정일시</th>
                    <th width="">삭제/수정</th>
                </tr>
            </thead>
            <tr>
                <td colspan="8">검색된 내용이 없습니다.</td>
            </tr>
        </table>
        <!-- hospital data table -->
        <table class="table_default" v-if="!isEmpty">
            <thead>
                <tr>
                    <th width="20%" class="txt_left">발신프로필/그룹</th>
                    <th width="20%" class="txt_left">템플릿명</th>
                    <th width="20%" class="txt_left">템플릿코드</th>
                    <th width="10%" class="txt_left">검수상태</th>
                    <th width="10%" class="txt_left">등록일시</th>
                    <th width="10%" class="txt_left">수정일시</th>
                    <th width="10%">삭제/수정</th>
                </tr>
            </thead>
            <AlrimtalkMessageListCell
                v-for="(template, index) in templates" :key="index"
                :template="template"
                :groups="groups"
                :selectedProfile="selectedProfile"
                @setSelectedEditTemplate="setSelectedEditTemplate"
                @setSelectedDeleteTemplate="setSelectedDeleteTemplate"
            />
        </table>
    </div>
</template>


<script>
import AlrimtalkMessageListCell from './AlrimtalkMessageListCell.vue'
export default {
    components: {
        AlrimtalkMessageListCell
    },
    props: {
        templates: {
            type: Array,
            default: () => []
        },
        groups: {
            type: Array,
            default: () => []
        },
        selectedProfile: Object,
    },
    watch: {},
    computed: {
        isEmpty: function() { return this.templates?.length == 0 }
    },
    methods: {

        // MARK: - Emit
        setSelectedEditTemplate(template) {
            this.$emit('setSelectedEditTemplate', template)
        },
        setSelectedDeleteTemplate(template) {
            this.$emit('setSelectedDeleteTemplate', template)
        }
    },
}
</script>