<template>
    <div class="content_wrap">
        <div class="page_title">
            <span>서비스 매니저 계정 설정</span>
        </div>
        <div class="h_scroll pd_15">
            <div class="table_title">
                <p class="dgray_txt">
                    총
                    <span class="black_txt">{{ nTotalCount }}</span>
                    명
                </p>
                <div class="table_title_btn">
                    <input type="text" class="input_s" v-model="keyword" placeholder="이름 검색" @keyup.enter="loadANurse(0)" />
                    <button class="btn_s btn_blue_line mgl_5" @click="loadANurse(0)">검색</button>
                    <button class="btn btn_s btn_blue" @click="action_ShowCreateANursePopup()" :disabled="!auth.serviceManager_w">등록</button>
                </div>
            </div>
            <div class="table_header_fix">
                <!-- sample -->
                <table class="table_default">
                    <thead>
                        <tr>
                            <th>이름</th>
                            <th>고객이 보는 이름</th>
                            <th>서비스 매니저 _id</th>
                            <th>ID</th>
                            <th>생성일</th>
                            <th>타입</th>
                            <th>계정 이용여부</th>
                            <th>수정</th>
                            <th>삭제</th>
                        </tr>
                    </thead>
                    <tr v-if="nData.length == 0">
                        <td colspan="9">데이터 로드 중 ..</td>
                    </tr>
                    <thead v-if="nData.length > 0">
                        <tr v-for="n in nData" :key="n._id">
                            <td>{{ n.realName }}</td>
                            <td>{{ n.name }}</td>
                            <td>{{ n._id }}</td>
                            <td>{{ n.id }}</td>
                            <td>{{ ISODatetoDate(n.createdAt) }}</td>
                            <td>{{ n.type }}</td>
                            <td>
                                <label
                                    class="label"
                                    :class="{
                                        lblue: n.isLeave == false,
                                        lgray: n.isLeave != false
                                    }"
                                >
                                    {{ n.isLeave != undefined ? (n.isLeave != false ? '미사용' : '사용중') : '-' }}
                                </label>
                            </td>
                            <td>
                                <button class="btn btn_s btn_blue_line" @click="action_ShowEditANursePopup(n)" :disabled="!auth.serviceManager_w">수정</button>
                            </td>
                            <td>
                                <button class="btn btn_s btn_gray" :disabled="!auth.serviceManager_w">삭제</button>
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <Pagenation
            v-bind:totalCount="nTotalCount"
            v-bind:currentPage="nCurrentPage"
            v-bind:countPerPage="nCountPerPage"
            v-bind:countPerRow="5"
            v-on:changePage="nChangePage"
        />
        <ANurseAccountSettingPopup v-on:loadANurse="loadANurse" v-on:loadTotalANurseCount="loadTotalANurseCount" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ANurseAccountSettingPopup from './Components/ANurseAccountSettingPopup'
import moment from '@/util/MPMoment'
import PageController from '@/controller/PageController'
import Pagenation from '@/views/main/ShareComponents/PagenationComponent'

export default {
    components: { Pagenation, ANurseAccountSettingPopup },

    data() {
        return {
            nData: [],
            keyword: '',
            nCurrentPage: 0,
            nTotalCount: 0,
            nCountPerPage: 10
        }
    },
    watch: {
        keyword: {
            handler: function (newValue) {
                if (newValue == '') {
                    this.loadANurse(0)
                }
            }
        }
    },
    computed: {
        ...mapState(['anurseaccountsettingpopup'])
    },
    mounted() {
        this.anursePageController = new PageController(this.API.Nurse.find)
        this.anursePageController.countPerPage = this.nCountPerPage
        this.initData()
    },
    methods: {
        ...mapActions(['setANurseAccountSettingPopupState']),
        async loadANurse(page) {
            this.anursePageController.filter = {}
            if (this.keyword) {
                this.anursePageController.filter.keyword = this.keyword
            }
            await this.anursePageController.loadPageIndex(page)
            await this.loadTotalANurseCount()

            this.nData = this.anursePageController.data
            console.log('nData')
            console.log(this.nData)
        },

        async loadTotalANurseCount() {
            let result = await this.API.Nurse.count(this.anursePageController.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.nTotalCount = result.data.data
            console.log(this.nTotalCount)
        },
        nChangePage(page) {
            this.nCurrentPage = page
            this.loadANurse(this.nCurrentPage)
        },
        initData() {
            this.isLoading = false
            this.loadANurse(this.nCurrentPage)
        },
        action_ShowCreateANursePopup() {
            this.setANurseAccountSettingPopupState({
                isShow: true,
                selectedANurse: {},
                selectedANurseID: ''
            })
        },
        action_ShowEditANursePopup(nurse) {
            this.setANurseAccountSettingPopupState({
                isShow: true,
                selectedANurse: nurse,
                selectedANurseID: nurse._id
            })
        },

        // TODO : make these methods below to common function..? + Vue.mixin?
        ISODatetoDate(date) {
            let modifiedDate = moment(date).format('YYYY-MM-DD HH:mm')
            return modifiedDate
        }
    }
}
</script>
