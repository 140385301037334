import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const findSimple = (query) => {
    return axios({
        method: 'GET',
        url: URL.NURSE_FIND_SIMPLE,
        params: query,
        headers: Util.getHeaders()
    })
}