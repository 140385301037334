<template>
    <ul v-if="isShow" class="settings_wrap flex_column gap_20">
        <LoadingIndicate
            :isLoading="isLoading"
        />
        <!-- 상담톡 검색용 아이디 불러오기 버튼 클릭 시 나타나는 팝업 -->
        <KakaoIntegrationConsultLoadPopup 
            :isShow="isShowConsultLoadPopup"
            :hospital="hospital"
            @selectConsultHistory="selectConsultHistory"
            @close="closePopup"
        />

        <!-- 알림톡/친구톡 검색용 아이디 불러오기 버튼 클릭 시 나타나는 팝업 -->
        <KakaoIntegrationFriendLoadPopup
            :isShow="isShowFriendLoadPopup"
            :hospital="hospital"
            @close="closePopup"
            @selectFriendHistory="selectFriendHistory"
        />
        <KakaoIntegrationSelectedConsult
            :history="selectedConsultHistory"
            @openConsultLoadPopup="action_OpenConsultLoadPopup"
            @updateConsultHistory="updateConsultHistory"
            @saveConsultHistory="saveConsultHistory"
            @clearConsultHistory="clearConsultHistory"
        />
        
        <KakaoIntegrationFriendSelectedFriendVue
            :serviceSetting="serviceSetting"
            :history="selectedFriendHistory"
            @openFriendLoadPopup="action_OpenFriendLoadPopup"
            @updateServiceSetting="updateServiceSetting"
            @updateFriendHistory="updateFriendHistory"
            @saveFriendHistory="saveFriendHistory"
            @clearFiendHistory="clearFiendHistory"
        />
    </ul>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import KakaoIntegrationConsultLoadPopup from './KakaoIntegrationConsultLoadPopup.vue'
import KakaoIntegrationSelectedConsult from './KakaoIntegrationSelectedConsult.vue'
import KakaoIntegrationFriendLoadPopup from './KakaoIntegrationFriendLoadPopup'
import KakaoIntegrationFriendSelectedFriendVue from './KakaoIntegrationFriendSelectedFriend.vue'

export default {
    components: {
        LoadingIndicate,
        KakaoIntegrationConsultLoadPopup,
        KakaoIntegrationSelectedConsult,
        KakaoIntegrationFriendLoadPopup,
        KakaoIntegrationFriendSelectedFriendVue,
    },
    props: {
        isShow: Boolean,
        hospital: Object,
    },
    data() {
        return {
            isLoading: false,
            isShowConsultLoadPopup: false,
            isShowFriendLoadPopup: false,

            serviceSetting: undefined,

            selectedConsultHistory: undefined,
            selectedFriendHistory: undefined,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { return }
                this.loadServiceSetting()
            }
        }
    },
    computed: {},
    methods: {
        // MARK: - Network
        async loadServiceSetting() {
            let hospitalID = this.hospital?._id
            if (hospitalID == undefined) { return }
            this.isLoading = true
            let result = await this.API_V3.ServiceSetting.findOne({hospitalID: hospitalID})
            this.isLoading = false
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.serviceSetting = result.data.data
            await this.loadConsultLinkage()
            await this.loadFriendLinkage()
        },
        async loadConsultLinkage() {
            if (this.serviceSetting.hasKakaoConsultationTalkLinkage == false) { return }
            let result = await this.API_V3.KakaoLinkageHistory.detail({
                isRequestForAlimAndFriendTalkLinkage: false,
                isRequestForConsultationTalkLinkage: true,
                hospitalID: this.hospital?._id,
                isLinked: true,
            })
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.selectedConsultHistory = result.data.data
        },
        async loadFriendLinkage() {
            if (this.serviceSetting.hasKakaoAlimAndFriendTalkLinkage == false) { return }
            let result = await this.API_V3.KakaoLinkageHistory.detail({
                isRequestForAlimAndFriendTalkLinkage: true,
                isRequestForConsultationTalkLinkage: false,
                hospitalID: this.hospital?._id,
                isLinked: true,
            })
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.selectedFriendHistory = result.data.data
        },
        async patchConsultLinkage() {
            if (this.selectedConsultHistory == undefined) { return }
            let model = {
                _id: this.selectedConsultHistory._id,
                hospitalID: this.hospital._id,
                searchableChannelId: this.selectedConsultHistory.searchableChannelId,
                consultationInformation: this.selectedConsultHistory.consultationInformation,
                accessKey:  this.selectedConsultHistory.accessKey,
                isRequestForAlimAndFriendTalkLinkage: false,
                isRequestForConsultationTalkLinkage: true,
            }
            this.isLoading = true
            let result = await this.API_V3.KakaoLinkageHistory.update(model)
            if (result.data.code != 0) {
                this.isLoading = false
                alert(result.data.message)
                return
            }
            this.loadServiceSetting()
        },
        async clearConsulting() {
            if (this.selectedConsultHistory == undefined) { return }
            let model = {
                _id: this.selectedConsultHistory._id,
                hospitalID: this.hospital._id,
                isRequestForAlimAndFriendTalkLinkage: false,
                isRequestForConsultationTalkLinkage: true,
            }
            this.isLoading = true
            let result = await this.API_V3.KakaoLinkageHistory.clear(model)
            if (result.data.code != 0) {
                this.isLoading = false
                alert(result.data.message)
                return
            }
            this.selectedConsultHistory = undefined
            this.loadServiceSetting()
        },
        async patchFriendLinkage() {
            if (this.selectedFriendHistory == undefined) { return }
            let model = {
                _id: this.selectedFriendHistory._id,
                hospitalID: this.hospital._id,
                isRequestForAlimAndFriendTalkLinkage: true,
                isRequestForConsultationTalkLinkage: false,
                usingKakaoAlimAndFriendTalkLinkage: this.serviceSetting.usingKakaoAlimAndFriendTalkLinkage,
            }
            let result = await this.API_V3.KakaoLinkageHistory.update(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadServiceSetting()
        },
        async clearFriend() {
            if (this.selectedFriendHistory == undefined) { return }
            let model = {
                _id: this.selectedFriendHistory._id,
                hospitalID: this.hospital._id,
                isRequestForAlimAndFriendTalkLinkage: true,
                isRequestForConsultationTalkLinkage: false,
                usingKakaoAlimAndFriendTalkLinkage: false,
            }
            let result = await this.API_V3.KakaoLinkageHistory.clear(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.selectedFriendHistory = undefined
            this.loadServiceSetting()
        },

        // MARK: - Actions
        action_OpenConsultLoadPopup() {
            this.isShowConsultLoadPopup = true
        },
        action_OpenFriendLoadPopup() {
            this.isShowFriendLoadPopup = true
        },
        // MARK: - Emit
        closePopup() {
            this.isShowConsultLoadPopup = false
            this.isShowFriendLoadPopup = false
        },
        selectConsultHistory(history) {
            this.selectedConsultHistory = history
        },
        updateConsultHistory(newHistory) {
            this.selectedConsultHistory = newHistory
        },
        saveConsultHistory() {
            this.patchConsultLinkage()
        },
        clearConsultHistory() {
            this.clearConsulting()
        },
        selectFriendHistory(history) {
            this.selectedFriendHistory = history
        },
        updateFriendHistory(newHistory) {
            this.selectedFriendHistory = newHistory
        },
        saveFriendHistory() {
            this.patchFriendLinkage()
        },
        clearFiendHistory() {
            this.clearFriend()
        },
        updateFriendCategoryCode(categoryInformation) {
            if (this.selectedFriendHistory == undefined) { return }
            this.selectedFriendHistory.categoryInformation = categoryInformation
        },
        updateServiceSetting(usingKakaoAlimAndFriendTalkLinkage) {
            this.serviceSetting.usingKakaoAlimAndFriendTalkLinkage = usingKakaoAlimAndFriendTalkLinkage
        }
    }
}
</script>