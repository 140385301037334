<template>
    <div class="consultauto_add_content">
        <div class="msg_noti_wrap">
            <div v-if="isNoSelect" class="no_select">
                <v-icon large>mdi-alert-circle-outline</v-icon>
                좌측에서 입력할 메시지를 선택해 주세요.
            </div>

            <!-- 선택된 알림톡 정보 -->
            <ConsultAutomationEditKakaoTemplateInformationVue
                :kakaoGroups="kakaoGroups"
                :kakaoProfiles="kakaoProfiles"
                :template="selectedKakaoTemplate"
            />
            <button class="btn_s btn_blue_line" @click="action_ShowKakaoTemplateList" :disabled="disableLoadKakaoTemplate">다른 알림톡 선택</button>
        </div>
        <div class="msg_setting_wrap">
            <div class="scroll_wrap">
                <div>
                    <b class="dgray_txt">일렉트론 필수 설정</b>
                    <table class="table_default small">
                        <colgroup>
                            <col width="15%">
                            <col width="25%">
                            <col width="15%">
                            <col width="">
                        </colgroup>
                        <tr>
                            <th>메시지 명칭</th>
                            <td><input type="text" class="input_s" placeholder="1~50자" maxlength="50" v-model="name">
                            </td>
                            <th>전송일자</th>
                            <td>
                                <select class="input_s" v-model="daysAfter">
                                    <option
                                        v-for="(day, index) in dayOptions" :key="index"
                                        :value="day"
                                    >{{ day }}일차
                                    </option>
                                </select>
                                <select class="input_s" v-model="selectedHour">
                                    <option
                                        v-for="(hour, index) in hourOptions" :key="index"
                                        :value="hour"
                                    >{{ hour }}
                                    </option>
                                </select>
                                <span> : </span>
                                <select class="input_s" v-model="selectedMinute">
                                    <option value="00" v-if="selectedHour!='08'">00</option>
                                    <option value="30">30</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>타입</th>
                            <td>
                                <select class="input_s" v-model="sendingType">
                                    <option :value="undefined">--선택--</option>
                                    <option value="BASIC">기본</option>
                                    <option value="REVISIT_INDUCEMENT">필수</option>
                                    <option value="SURVEY">설문</option>
                                </select>
                                <!-- 기본 템플릿에서는 홈케어콘텐츠/이벤트타입 못만든다 -->
                                <!-- 아래 셀렉트박스는 위에 기본, 필수를 선택했을때만 표시 -->
                                <select class="input_s" v-if="isShowContentType" v-model="contentType">
                                    <option :value="undefined">--선택--</option>
                                    <option value="TEXT">텍스트</option>
                                    <option value="IMAGE">이미지</option>
                                    <option value="VIDEO">동영상</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                </div>
                <!-- 아래 알림톡 기본 설정은 위에서 타입을 기본, 텍스트 or 필수, 텍스트를 선택했을 때에만 표시 -->
                <ConsultAutomationEditMessageKakaoTemplateVue
                    v-if="isShowKakaoOptions"
                    v-model="kakaoTemplate"
                    :serviceSetting="serviceSetting"
                    :kakaoGroups="kakaoGroups"
                    :kakaoProfiles="kakaoProfiles"
                    :kakaoCategories="kakaoCategories"
                />
                <hr>
                <!-- 텍스트타입 -->
                <div class="mgt_15" v-if="isTextContentType">
                    <b class="dgray_txt">내용</b>
                    <textarea rows="7" placeholder="내용을 입력해 주세요.(1~1000자)" maxlength="1000" class="input_s w_full"
                              v-model="content.text">
                    </textarea>
                    <div class="var_sample">
                        <b><small class="dgray_txt">치환문자</small></b>
                        <span class="label lgray">고객명</span>
                        <span class="label lgray">병원명</span>
                        <span class="label lgray">시술명</span>
                        <span class="label lgray">시술일+N일</span>
                        <span class="label lgray">경과일</span>
                    </div>
                </div>
                <!-- 이미지 타입 -->
                <div class="mgt_15" v-if="isImageContentType">
                    <b class="dgray_txt">내용</b>
                    <!-- 하다희 - CSS 이쁘게 만져 주세요. -->
                    <div>
                        <button class="input_s btn_s" @click="action_ShowImageFilePicker">파일선택</button>
                        <small class="img_preview_wrap lgray_txt" style="width: fit-content;">현재 저장된 File ID:
                            {{ imageFileID }}
                            <div class="img_preview">
                                <img
                                    v-if="imageURL"
                                    :src="imageURL"
                                    alt=""
                                />
                            </div>
                        </small>
                    </div>
                    <textarea rows="5" placeholder="내용을 입력해 주세요.(1~1000자)" maxlength="1000" class="input_s w_full"
                              v-model="content.text">
                    </textarea>
                    <div class="var_sample">
                        <b><small class="dgray_txt">치환문자</small></b>
                        <span class="label lgray">고객명</span>
                        <span class="label lgray">병원명</span>
                        <span class="label lgray">시술명</span>
                        <span class="label lgray">시술일+N일</span>
                        <span class="label lgray">경과일</span>
                    </div>
                </div>
                <!-- 동영상 타입 -->
                <div class="mgt_15" v-if="isVideoContentType">
                    <b class="dgray_txt">내용</b>
                    <!-- 하다희 - CSS 이쁘게 만져 주세요. -->
                    <div class="w_full mgb_10">
                        <button class="input_s btn_s" @click="action_ShowFilePicker">파일선택</button>
                        <small class="img_preview_wrap lgray_txt" style="width: fit-content;">현재 저장된 File ID:
                            {{ videoFileID }}
                        </small>
                    </div>
                    <textarea rows="5" placeholder="내용을 입력해 주세요.(1~1000자)" maxlength="1000" class="input_s w_full"
                              v-model="content.text">
                    </textarea>
                    <div class="var_sample">
                        <b><small class="dgray_txt">치환문자</small></b>
                        <span class="label lgray">고객명</span>
                        <span class="label lgray">병원명</span>
                        <span class="label lgray">시술명</span>
                        <span class="label lgray">시술일+N일</span>
                        <span class="label lgray">경과일</span>
                    </div>
                </div>
                <!-- 설문 타입 -->
                <ConsultAutomationEditMessageSurvey
                    v-model="content.survey"
                    :isShow="isSurveyContentType"
                    :questionErrorMessage="questionErrorMessage"
                    :answerErrorMessage="answerErrorMessage"
                    @update:questionErrorMessage="questionErrorMessage = ''"
                    @update:answerErrorMessage="answerErrorMessage = ''"
                />

                <hr>
                <div class="mgt_15">
                    <b class="dgray_txt">상담가이드</b>
                    <div>
                        <label class="radio_wrap">
                            <input type="radio" name="showConsultationGuide" id="" :value="false" v-model="showConsultationGuide">
                            표시하지 않음
                            <span class="checkmark"></span>
                        </label>
                        <label class="radio_wrap">
                            <input type="radio" name="showConsultationGuide" id="" :value="true" v-model="showConsultationGuide">
                            표시함
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <!-- 위에서 라디오 "표시함" 선택시 텍스트 에디터 표시 -->
                <div class="tag_manual_edit_pop_wrap" :style="{'width': '100%', 'height': 'auto'}">
                    <div class="manual_text_editor" v-if="showConsultationGuide">
                        <HTMLEditor
                            ref="Editor"
                            v-model="consultationGuide"
                            :message="defaultContent"
                        />
                    </div>
                </div>
                <div class="btn_wrap left">
                    <button class="btn_s btn_blue_line mgl_auto" @click="action_Save">이 메시지 저장</button>
                </div>
            </div>
        </div>
        <!-- 알림톡 템플릿 불러오기 -->
        <KakaoTemplateListPopup
            :isShow="isShowKakaoListPopup"
            :isOnlyGroups="onlyGroups"
            :selectableKakaoProfiles="kakaoProfiles"
            @close="closeKakaoTemplateList"
            @select="selectKakaoTemplate"
        />
        <FilePicker v-model="selectedImageFile" v-bind:only-image="true" v-bind:is-multiple="false"
                    v-bind:is-open="isShowImageFilePicker" />
        <FilePicker v-model="selectedVideoFile" v-bind:only-image="false" v-bind:is-multiple="false"
                    v-bind:is-open="isShowFilePicker" acceptExt="video/mp4" />
    </div>
</template>


<script>
import FilePicker from '@/views/main/ShareComponents/FilePicker.vue'
import ConsultAutomationEditKakaoTemplateInformationVue from '@/views/main/SubViews/BasicTemplate/Views/ConsultAutomation/EditPopup/ConsultAutomationEditKakaoTemplateInformation.vue'
import KakaoTemplateListPopup from '@/views/main/SubViews/BasicTemplate/Views/ConsultAutomation/EditPopup/KakaoTemplateListPopup.vue'
import ConsultAutomationEditMessageKakaoTemplateVue from '@/views/main/SubViews/BasicTemplate/Views/ConsultAutomation/EditPopup/ConsultAutomationEditMessageKakaoTemplate.vue'
import ConsultAutomationEditMessageSurvey from '@/views/main/SubViews/BasicTemplate/Views/ConsultAutomation/EditPopup/ConsultAutomationEditMessageSurvey.vue'
import HTMLEditor from '@/views/main/ShareComponents/HTMLEditor.vue'
import { FileUploadController } from '@/controller/FileUploadController'

export default {
    components: {
        FilePicker,
        ConsultAutomationEditKakaoTemplateInformationVue,
        KakaoTemplateListPopup,
        ConsultAutomationEditMessageKakaoTemplateVue,
        ConsultAutomationEditMessageSurvey,
        HTMLEditor,
    },
    props: {
        serviceSetting: { type: Object, default: () => { return {} } },
        kakaoGroups: { type: Array, default: () => [] },
        kakaoProfiles: { type: Array, default: () => [] },
        kakaoCategories: { type: Array, default: () => [] },
        selectedMessage: Object,
        selectedMessageIndex: Number,
        onlyGroups: {
            type: Boolean,
            default: () => true
        },
    },
    data() {
        return {
            isShowKakaoListPopup: false,

            sendingType: '',
            name: '',
            contentType: 'TEXT',
            content: {
                text: undefined,
                image: undefined,
                video: undefined,
                survey: {
                    question: '',
                    answers: ['', '']
                }
            },
            daysAfter: 1,
            timeOfDay: '08:30',
            selectedHour: '08',
            selectedMinute: '30',
            showConsultationGuide: false,
            consultationGuide: '',
            defaultConsultationGuide: '',
            kakaoTemplateHistoryId: undefined,
            hasApprovedAlimtalkTemplate: false,

            selectedKakaoProfile: undefined,
            selectedKakaoCategory: undefined,

            imageFile: undefined,
            videoFile: undefined,

            selectedImageFile: undefined,
            isShowImageFilePicker: false,

            selectedVideoFile: undefined,
            isShowFilePicker: false,

            questionErrorMessage: '',
            answerErrorMessage: '',

            selectedKakaoTemplate: undefined,
            kakaoTemplate: undefined,
        }
    },
    watch: {
        selectedMessage: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.parseMessage(newValue)
            }
        },
        selectedHour: function(newValue) { this.timeOfDay = newValue + ':' + this.selectedMinute },
        selectedMinute: function(newValue) { this.timeOfDay = this.selectedHour + ':' + newValue },
        isShowContentType: function(newValue) {
            if (newValue == false) {
                this.contentType = undefined
            }
        },
        isSurveyContentType: function(newValue) {
            if (newValue) {
                this.contentType = 'SURVEY'
            } else {
                this.contentType = 'TEXT'
            }
        },

        selectedImageFile: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.isShowImageFilePicker = false
                if (newValue == undefined || newValue?.length == 0) {
                    return
                }
                this.updateImageFile(newValue)
            }
        },
        selectedVideoFile: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.isShowFilePicker = false
                if (newValue == undefined || newValue?.length == 0) {
                    return
                }
                this.updateVideoFile(newValue)
            }
        },
        kakaoTemplate: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue?.templateName != this.selectedKakaoTemplate?.templateName ||
                    newValue?.templateCode != this.selectedKakaoTemplate?.templateCode ||
                    newValue?.templateContent != this.selectedKakaoTemplate?.templateContent ||
                    newValue?.categoryCode != this.selectedKakaoTemplate?.categoryCode
                ) {
                    this.kakaoTemplateHistoryId = undefined
                }
            }
        },
        'kakaoTemplate.registKakaoTemplate': function(newValue) {
            if (newValue == false) {
                if (newValue?.templateName == undefined &&
                    newValue?.templateCode == undefined &&
                    newValue?.templateContent == undefined &&
                    newValue?.senderKey == undefined &&
                    newValue?.categoryCode == undefined
                ) {
                    this.selectedKakaoTemplate = undefined
                }
            }
        },
        
        content: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                console.log('content changed : ', newValue)
            }
        }
    },
    computed: {
        disableLoadKakaoTemplate: function() {
            if (this.serviceSetting.kakaoProfileSenderKeyAtReady == undefined) { return true }
            if (this.contentType != 'TEXT') { return true }
            return false
        },
        dayOptions: function() {
            let array = []
            for (let i = 0; i < 365; i++) {
                array.push(i + 1)
            }
            return array
        },
        hourOptions: function() {
            let array = []
            for (let i = 8; i < 21; i++) {
                array.push(String(i).padStart(2, '0'))
            }
            return array
        },
        isNoSelect: function() { return this.selectedMessage == undefined || this.selectedMessage == {} },
        isShowContentType: function() {
            return this.sendingType == undefined || this.sendingType == 'BASIC' || this.sendingType == 'REVISIT_INDUCEMENT'
        },
        isShowKakaoOptions: function() { return !this.disableLoadKakaoTemplate },
        isTextContentType: function() { return this.contentType == 'TEXT' },
        isImageContentType: function() { return this.contentType == 'IMAGE' },
        isVideoContentType: function() { return this.contentType == 'VIDEO' },
        isSurveyContentType: function() { return this.sendingType == 'SURVEY' },

        isTemplateInspection: function() { return false },
        imageURL: function() { return this.imageFile?.paths[0] },
        imageFileID: function() {
            if (this.imageFile == undefined) {
                return '-'
            }
            return this.imageFile._id
        },
        videoFileID: function() {
            if (this.videoFile == undefined) {
                return '-'
            }
            return this.videoFile._id
        },
        kakaoGroupProfiles: function() {
            if (this.onlyGroups == true) { return this.kakaoGroupProfiles }
            return this.kakaoGroups.concat(this.kakaoProfiles)
        }
    },
    methods: {
        // MARK: - Private
        async parseMessage(message) {
            this.sendingType = message?.sendingType ?? 'BASIC'
            this.name = message?.name ?? ''
            this.daysAfter = message?.daysAfter ?? 1
            this.timeOfDay = message?.timeOfDay ?? '08:30'
            this.selectedHour = this.timeOfDay.split(':')[0]
            this.selectedMinute = this.timeOfDay.split(':')[1]
            this.showConsultationGuide = message?.showConsultationGuide ?? false
            this.consultationGuide = message?.consultationGuide ?? ''
            this.defaultConsultationGuide = this.consultationGuide
            this.kakaoTemplateHistoryId = message?.kakaoTemplateHistoryId ?? undefined
            this.hasApprovedAlimtalkTemplate = message?.hasApprovedAlimtalkTemplate ?? false
            this.contentType = message?.contentType ?? undefined

            if (this.kakaoTemplateHistoryId != undefined) { // load kakao template
                let senderKeyType = 'G'
                let senderKey = this.kakaoTemplateHistoryId.senderKey
                let currentGroupOrProfile = this.kakaoGroupProfiles.find( (p) => p.groupKey == senderKey || p.senderKey == senderKey )
                if (currentGroupOrProfile.senderKey != undefined) { senderKeyType = 'S' }
                let model = {
                    senderKey: senderKey,
                    senderKeyType: senderKeyType,
                    templateCode: this.kakaoTemplateHistoryId.templateCode
                }
                let result = await this.API_V3.KakaoTemplate.findDetail(model)
                if (result.data.code != 0) {
                    alert(result.data.message)
                    return
                }
                let resultData = result.data.data
                resultData.serviceStatus = this.kakaoTemplateHistoryId?.serviceStatus
                if (this.kakaoTemplate?.senderKey != undefined) {
                    resultData.senderKey = this.kakaoTemplate.senderKey
                }
                this.selectKakaoTemplate(resultData)
            } else {
                this.selectedKakaoTemplate = undefined
                this.kakaoTemplate = undefined
            }

            if (message?.kakaoTemplate != undefined) {
                this.kakaoTemplate = message.kakaoTemplate
            }

            if (this.contentType == undefined) {
                this.contentType = (() => {
                    const { content, sendingType } = message || {}
                    if (content?.image) return 'IMAGE'
                    if (content?.video) return 'VIDEO'
                    if (sendingType === 'SURVEY') return 'SURVEY'
                    return 'TEXT'
                })()
            }

            this.content = {
                ...JSON.parse(JSON.stringify(message?.content ?? {
                    survey: {
                        question: '',
                        answers: ['', '']
                    }
                }))
            }

            this.parseFiles()
        },
        async parseFiles() {
            if (this.contentType == 'IMAGE') {
                if (this.content?.image != undefined && typeof this.content?.image == 'string') {
                    this.imageFile = await this.loadFile(this.content.image)
                    this.content.image = this.imageFile._id
                } else {
                    this.imageFile = undefined
                }
            } else if (this.contentType == 'VIDEO') {
                if (typeof this.content?.video == 'string') {
                    this.videoFile = await this.loadFile(this.content.video)
                    this.content.video = this.videoFile._id
                } else {
                    this.videoFile = undefined
                }
            } else {
                this.imageFile = undefined
                this.videoFile = undefined
            }
        },
        async updateImageFile(files) {
            this.imageFile = await this.uploadFile(files[0])
            this.selectedImageFile = undefined
            this.content.image = this.imageFile._id
        },
        async updateVideoFile(files) {
            this.videoFile = await this.uploadFile(files[0])
            this.selectedVideoFile = undefined
            this.content.video = this.videoFile._id
        },

        // MARK: - Network
        async loadFile(fileID) {
            let result = await this.API.File.getFile({ _id: fileID })
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            return result.data.data[0]
        },
        async uploadFile(file) {
            return new Promise(function(resolve, reject) {
                FileUploadController.uploadFiles([file], [], async (results) => {
                    let files = results.data
                    if (files.length == 0) {
                        reject()
                        return
                    }

                    let result = files[0]
                    if (result.data.code != 0) {
                        reject()
                        return
                    }
                    resolve(result.data.data)
                })
            })
        },

        // MARK: - Actions
        action_Save() {
            this.updateValue()
        },
        action_ShowImageFilePicker() {
            this.isShowImageFilePicker = true
        },
        action_ShowFilePicker() {
            this.isShowFilePicker = true
        },
        action_ShowKakaoTemplateList() {
            this.isShowKakaoListPopup = true
        },

        // MARK: - Emit
        updateValue() {
            if (this.selectedMessage == undefined) { return }

            const targetContent = this.getTargetContent()

            if (!this.validateContent()) return

            if (this.kakaoTemplate?.templateContent != undefined && this.kakaoTemplate?.templateContent != '') {
                this.kakaoTemplate.templateContent = this.content.text
            }

            let messageObject = {
                index: this.selectedMessageIndex,
                message: {
                    ...this.selectedMessage,
                    sendingType: this.sendingType,
                    contentType: this.contentType,
                    name: this.name,
                    content: targetContent,
                    daysAfter: this.daysAfter,
                    timeOfDay: this.timeOfDay,
                    showConsultationGuide: this.showConsultationGuide,
                    consultationGuide: this.consultationGuide,
                    kakaoTemplateHistoryId: this.kakaoTemplateHistoryId,
                    hasApprovedAlimtalkTemplate: this.hasApprovedAlimtalkTemplate,
                    kakaoTemplate: this.kakaoTemplate,
                }
            }
            this.$emit('updateMessage', messageObject)
        },
        getTargetContent() {
            const commonContent = { text: this.content.text }

            const contentMapping = {
                IMAGE:  { ...commonContent, image: this.content.image },
                VIDEO:  { ...commonContent, video: this.content.video },
                TEXT:   { ...commonContent },
                SURVEY: { survey: this.content.survey }
            }
            return contentMapping[this.contentType] || {}
        },
        validateContent() {
            // TODO
            if (this.contentType === 'SURVEY') {
                if (!this.content.survey.question) {
                    this.questionErrorMessage = '설문 내용을 작성해 주세요.'
                    return false
                }
                if (this.content.survey.answers.length < 2) {
                    this.answerErrorMessage = '답변을 최소 두 개 이상 작성해 주세요.'
                    return false
                }
                if (this.content.survey.answers.some((answer) => answer.trim().length === 0)) {
                    this.answerErrorMessage = '모든 답변을 빠짐없이 작성해 주세요.'
                    return false
                }
            }
            return true
        },
        closeKakaoTemplateList() {
            this.isShowKakaoListPopup = false
        },
        selectKakaoTemplate(template) {
            if (this.contentType != 'TEXT') { return }
            if (template.templateName == this.kakaoTemplate?.templateName &&
                template.templateCode == this.kakaoTemplate?.templateCode &&
                template.senderKey == this.kakaoTemplate.senderKey &&
                template.categoryCode == this.kakaoTemplate.categoryCode &&
                template.messageContent == this.kakaoTemplate.messageContent
            ) {
                return
            }
            this.selectedKakaoTemplate = template
            this.kakaoTemplate = template
            if (this.name == undefined || this.name == '') {
                this.name = template.templateName
            }
            this.content.text = template.templateContent
            this.closeKakaoTemplateList()
        }
    }
}
</script>