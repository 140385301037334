<template>
    <div class="mp_container">
        <div class="lg_wrapper">
            <img :src="require(`@/assets/img/BaseLogo.png`)" alt="afterdoc_logo" class="lg_logo" />
            <div class="lg_info">
                <span class="title">아이디</span>
                <span class="err_txt">{{ errTxt }}</span>
            </div>
            <input class="lg_input" type="text" placeholder="아이디" v-model="id" @keyup.enter="executeLogin" @focus="focus_Input" @input="focus_Input" />
            <div class="lg_info">
                <span class="title">비밀번호</span>
                <span class="err_txt">{{ errTxt }}</span>
            </div>
            <input
                class="lg_input"
                type="password"
                placeholder="비밀번호"
                v-model="pass"
                @keyup.enter="executeLogin"
                @focus="focus_Input"
                @input="focus_Input"
            />
            <a class="btn_lg" @click="executeLogin">로그인</a>
        </div>
        <div class="version_txt">
            <small class="lgray_txt">2024. Medipal Inc. All Rights Reserved.</small>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
    data() {
        return {
            id: '',
            pass: '',
            errTxt: ''
        }
    },
    computed: {
        ...mapState(['user'])
    },
    methods: {
        ...mapActions(['AC_SetUser']),
        ...mapMutations(['MU_SetAdminAuthorization']),
        executeLogin: async function () {
            if (navigator.onLine == false) {
                //네트워크 오류
                this.alert(false, '예기치 않은 네트워크 오류가 발생했습니다.')
            } else {
                if (this.id !== '' && this.pass !== '') {
                    try {
                        let result = await this.API.Auth.login(this.id, this.pass, this.$store.state.user.HOSPITAL_ID)
                        if (result.data.code != 0) {
                            // Handle Error
                            console.log(result.data.message)
                            this.errTxt = result.data.message
                            return
                        }

                        await this.$store.dispatch('AC_SetUser', result.data.data)

                        let authorizationResult = await this.API_V3.AdminAuthorizationType.findOne({userID: this.user._id})
                        if (authorizationResult.data.code != 0) {
                            alert(authorizationResult.data.message)
                            return
                        }
                        this.MU_SetAdminAuthorization(authorizationResult.data.data)
                    } catch (err) {
                        console.log(err)
                        this.textAlert('서버와의 접속이 원활하지 않습니다.\n이 문제가 반복되면 메디팔로 문의해주세요.\n 문의전화 : 070-5180-4070')
                        return
                    }

                    if (this.user._id !== undefined) {
                        this.$router.push({ name: 'HospitalSetting' }).catch(() => {})
                    } else {
                        this.errTxt = '가입되지 않은 아이디이거나, 잘못된 비밀번호 입니다.'
                    }
                } else {
                    // 로그인에 필요한 정보 미입력 시
                    if (this.id == '') {
                        this.errTxt = '아이디를 입력해주세요.'
                    } else if (this.pass == '') {
                        this.errTxt = '비밀번호를 입력해주세요.'
                    }
                }
            }
        },
        // action_Login() {
        //     this.$router.push({ name: 'HospitalManagement' }).catch(() => { })
        // },
        focus_Input() {
            this.errTxt = ''
        }
    }
}
</script>

<style scoped></style>
