<template>
    <div class="v2_custom_select" v-click-outside="closeList">
        <div class="flex_aic_spb" @click="action_ClickTitle">
            <div class="flex_aic gap_5">
                <span class="color_circle" :style="styleString"></span>
                <span class="txt">{{ selectedItemTitle }}</span>
            </div>
            <span class=""><v-icon>mdi-chevron-down</v-icon></span>
        </div>
        <div class="list" v-show="isShowList">
            <SmartDoctorIntegrationStatusListItem
                v-for="(item, index) in list"
                :key="index"
                :item="item"
                :selectedValue="value"
                @selectItem="selectItem"
            />
        </div>  
    </div>
</template>


<script>
import SmartDoctorIntegrationStatusListItem from './SmartDoctorIntegrationStatusListItem.vue'
export default {
    components: { SmartDoctorIntegrationStatusListItem },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        value: Object,
    },
    data() {
        return {
            isShowList: false,
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
            }
        }
    },
    computed: {
        selectedItemTitle: function() {
            return this.value?.name ?? '--선택--'
        },
        selectedItemColor: function() {
            return this.value?.color ?? '#FFFFFF'
        },
        styleString: function() {
            return 'background-color: ' + this.selectedItemColor + ';'
        }
    },
    methods: {
        closeList() {
            this.isShowList = false
        },

        // MARK: - Actions
        action_ClickTitle() {
            this.isShowList = !this.isShowList
        },

        // MARK: - Emit
        selectItem(item) {
            this.$emit('input', item)
            this.closeList()
        }
    },
}
</script>