<template>
    <tr>
        <td>{{ hospitalName }}</td>
        <td>{{ pf_id }}</td>
        <td>{{ phone }}</td>
        <td>
            <span>{{ categoryTitleL }}</span>/<span>{{ categoryTitleM }}</span>/<span>{{ categoryTitleS }}</span>
        </td>
        <td class="txt_center">
            <small class="lblue service_status" v-if="isLinked">사용중</small>
        </td>
        <td v-if="hasDetailInfo">{{ groupTitle }}</td>
        <td v-if="!hasDetailInfo"><button class="table_btn btn_gray" @click="action_Detail">그룹 조회</button></td>
        <td class="txt_center">
            <button class="btn_s btn_blue_line" @click="action_Select">선택</button>
        </td>
    </tr>
</template>


<script>
export default {
    props: {
        index: Number,
        history: {
            type: Object,
            default: () => { return {} }
        }
    },
    computed: {
        hospitalName: function() {
            if (this.history.hospitalName) { return this.history.hospitalName }
            if (this.history.tempHospitalName) { return this.history.tempHospitalName }
            return '-'
        },
        pf_id: function() {
            return this.history.searchableChannelId ?? '-'
        },
        phone: function() {
            return this.history.channelAdminPhone ?? '-'
        },
        categoryInformation: function() {
            return this.history.categoryInformation ?? {}
        },
        categoryTitleL: function() {
            return this.categoryInformation.broadCategory ?? '-'
        },
        categoryTitleM: function() {
            return this.categoryInformation.mediumCategory ?? '-'
        },
        categoryTitleS: function() {
            return this.categoryInformation.smallCategory ?? '-'
        },
        detailInfo: function() {
            return this.history.detailInfo
        },
        hasDetailInfo: function() {
            return this.detailInfo != undefined
        },
        groups: function() {
            return this.detailInfo.groups ?? []
        },
        groupTitle: function() {
            let title = this.groups?.map( (g) => g.name ).join(', ')
            if (title == undefined || title == '') { title = '-' }
            return title
        },
        isLinked: function() {
            return this.history.isLinked
        }
    },
    methods: {
        action_Select() {
            this.$emit('selectFriendHistory', this.history)
        },
        action_Detail() {
            this.$emit('detail', this.index)
        },
    }
}
</script>