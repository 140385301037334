<template>
    <label class="flex_aic checkbox_wrap">
        <input type="checkbox" name="사용할 콘텐츠" id="통증 및 재활" v-model="isSelected" :disabled="!isEnable">
        <strong>{{ categoryName }}<small class="lgray_txt">({{ subCategoryNames }})</small></strong>
    </label>
</template>


<script>
export default {
    props: {
        index: Number,
        category: Object,
        isEnable: {
            type: Boolean,
            default: () => false
        }
    },
    data() {
        return {
            isSelected: false
        }
    },
    watch: {
        category: {
            immediate: true,
            handler: function(newValue) {
                this.isSelected = newValue.isSelected
            }
        },
        isSelected: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.updateCategory()
            }
        }
    },
    computed: {
        categoryName: function() {
            return this.category?.categoryName ?? '-'
        },
        subCategoryNames: function() {
            if (this.category?.subCategoryNames == '') { return '-' }
            return this.category?.subCategoryNames ?? '-'
        }
    },
    methods: {
        updateCategory() {
            let copied = JSON.parse(JSON.stringify(this.category))
            copied.isSelected = this.isSelected
            copied.index = this.index
            this.$emit('updateCategory', copied)
        }
    }
}
</script>