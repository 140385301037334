<template>
    <tr>
        <td :title="title"><p class="ellipsis" style="width: 120px;">{{ title }}</p></td>
        <td :title="templateName"><p class="ellipsis" style="width: 160px;">{{ templateName }}</p></td>
        <td :title="templateCode"><p class="ellipsis" style="width: 130px;">{{ templateCode }}</p></td>
        <td><small class="label" :class="statusClass">{{ statusName }}</small></td>
        <td>{{ createdAt }}</td>
        <td>{{ updatedAt }}</td>
        <td><button class="btn_s btn_blue_line w_full" @click="action_Select">선택</button></td>
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')

export default {
    props: {
        item: Object,
        selectedProfile: {
            type: Object,
            default: () => { return {} }
        },
        groups: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        title: function() {
            if (this.item?.senderKeyType == 'G') { return this.groupTitle }
            else { return this.profileTitle }
        },
        groupTitle: function() {
            let group = this.groups.find( (g) => g.groupKey == this.item?.senderKey)
            return group?.name ?? '-'
        },
        profileTitle: function() {
            return this.selectedProfile?.name ?? '-'
        },
        templateName: function() { return this.item.templateName },
        templateCode: function() { return this.item.templateCode },
        statusKind: function() {
            return [
                {name: '등록',      key: 'REG', class: 'lgray'},
                {name: '검수요청',  key: 'REQ', class: 'lgray'},
                {name: '반려',      key: 'REJ', class: 'lred'},
                {name: '차단',      key: 'STP', class: 'lred'},
                {name: '발송전',    key: 'RDY', class: 'lgreen'},
                {name: '정상',      key: 'ACT', class: 'lgreen'},
                {name: '휴면',      key: 'DMT', class: 'lgray'},
                {name: '차단',      key: 'BLK', class: 'lgray'},
            ]
        },
        status: function() { return this.statusKind.find( (s) => s.key == this.item?.serviceStatus) },
        statusName: function() { return this.status?.name ?? '-' },
        statusClass: function() { return this.status?.class ?? undefined},
        createdAt: function() { return moment(this.item.createdAt).format('YYYY-MM-DD HH:mm') },
        updatedAt: function() {
            if (this.item.modifiedAt == undefined) { return '-' }
            return moment(this.item.modifiedAt).format('YYYY-MM-DD HH:mm')
        },
    },
    methods: {
        action_Select() {
            this.$emit('selectTemplate', this.item)
        }
    }
}
</script>