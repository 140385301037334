const state = {
    isShow: false,
    selectedHospital: undefined,
    token: undefined
}

const actions = {
    setAlFeatureConfigPopupState: (store, param) => {
        store.commit('muAlFeatrueConfigPopupState', param)
    }
}

const mutations = {
    muAlFeatrueConfigPopupState: (state, param) => {
        state.isShow = param.isShow
        state.selectedHospital = param.selectedHospital
        if (param.selectedHospital?.integrationSetting?.clientToken) {
            state.token = param.selectedHospital?.integrationSetting?.clientToken
        }
        else if (param.token) {
            state.token = param.token
        }
    }
}

export default { state, actions, mutations }