<template>
    <div class="h_scroll">
        <div class="table_header_fix">
            <!-- 검색된 데이터 없을 떄 -->
            <!-- <table class="table_default consult_talk">
                <thead>
                    <tr>
                        <th width="250" class="txt_left">병원명</th>
                        <th width="190" class="txt_left">상담톡 검색용 아이디</th>
                        <th width="" class="txt_left">상담 가능 일시</th>
                        <th width="60">상태</th>
                        <th width="80">선택</th>
                    </tr>
                </thead>
                <tr>
                    <td colspan="5">검색된 병원이 없습니다.</td>
                </tr>
            </table> -->
            <!-- hospital data table -->
            <table class="table_default consult_talk">
                <thead>
                    <tr>
                        <th width="" class="txt_left">GroupKey</th>
                        <th width="240" class="txt_left">이름</th>
                        <th width="80">선택</th>
                    </tr>
                </thead>
                <tr v-for="(group, index) in groups" :key=index>
                    <td>{{ group.groupKey }}</td>
                    <td>
                        {{ group.name }}
                    </td>
                    <td>
                        <button class="btn_s btn_blue_line w_full" @click="action_SelectGroup(group)">선택</button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        groups: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {

        }
    },
    methods: {
        action_SelectGroup(group) {
            this.$emit('updateSelectedGroup', group)
        }
    }
}
</script>