<template>
    <li class="settings_list flex_spb">
        <div class="settings_list_title">
            병원 운영시간
        </div>
        <div class="settings_list_option flex_column">
            <ResponseAutomationListItem
                :isEnable="false"
                :schedule="{}"
                :index="0"
                v-if="list.length == 0"

                @addSchedule="addSchedule"
            />
            <ResponseAutomationListItem
                v-for="(schedule, index) in list"
                :key="index"
                :index="index"
                :schedule="schedule"
                @updateSchedule="updateSchedule"
                @addSchedule="addSchedule"
                @removeSchedule="removeSchedule"
            />
        </div>
    </li>
</template>


<script>
import ResponseAutomationListItem from './ResponseAutomationListItem.vue'

export default {
    components: { ResponseAutomationListItem },
    props: {
        value: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            list: [],
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function(newValue) {
                this.list = [...newValue]
            }
        }
    },
    computed: {
        isEmpty() {
            return this.list.length == 0
        }
    },
    methods: {
        // MARK: - Emit
        addSchedule(index) {
            this.list.splice(index+1, 0, {})
        },
        removeSchedule(index) {
            this.list.splice(index, 1)
        },
        updateSchedule(schedule) {
            let index = schedule.index
            delete schedule.index
            this.list.splice(index, 1, schedule)

            this.$emit('input', this.list)
        }
    }
}
</script>