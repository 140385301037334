<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="tag_manual_edit_pop_wrap">
            <div class="pop_title">
                <span class="title">매뉴얼 관리</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon :size="24">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont flex_column gap_20 h_scroll">
                <div class="flex gap_20 pdb_10 bd_bottom">
                    <div class="manual_name flex gap_10">
                        <b>태그명:</b>
                        <div class="flex_column" style="flex-grow: 1;">
                            <input type="text" class="input_s " v-model="categoryName">
                            <small class="red_txt" v-if="isEmpty_CategoryName">태그명을 입력해 주세요.</small>
                        </div>
                    </div>
                    <div class="manual_memo flex gap_10" style="flex-grow: 1;">
                        <b>메모:</b>
                        <input type="text" class="input_s" style="flex-grow: 1;" v-model="description" placeholder="내용 입력">
                    </div>
                </div>
                <div class="flex_column mgb_10 ">
                    <div class="manual_text_editor">
                        <HTMLEditor
                            ref="Editor"
                            v-model="manualContent"
                            :message="defaultContent"
                        />
                    </div>
                    <small class="red_txt" v-if="isEmpty_ManualContent">내용을 입력해 주세요.</small>
                </div>
                <TreatmentTagManualEditPopupImageList
                    v-model="fileID"
                />
            </div>
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>


<script>
import HTMLEditor from '../../../../ShareComponents/HTMLEditor.vue'
import TreatmentTagManualEditPopupImageList from './TreatmentTagManualEditPopupImageList.vue'
export default {
    components: { HTMLEditor, TreatmentTagManualEditPopupImageList },
    props: {
        isShow: Boolean,
        template: {
            type: Object,
            default: () => undefined
        }
    },
    data() {
        return {
            categoryName: undefined,
            description: undefined,
            manualContent: '',
            fileID: undefined,

            defaultContent: '',
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { return }
                this.initVariables()
            }
        }
    },
    computed: {
        isEmpty_CategoryName: function() {
            return this.categoryName == undefined || this.categoryName == ''
        },
        isEmpty_ManualContent: function() {
            return this.manualContent == undefined || this.manualContent == ''
        }
    },
    methods: {
        initVariables() {
            this.categoryName = this.template?.categoryName ?? undefined
            this.description = this.template?.description ?? undefined
            this.defaultContent = this.template?.manualContent ?? ''
            this.manualContent = this.template?.manualContent ?? ''
            this.fileID = this.template?.fileID ?? undefined
        },

        // MARK: - Network
        async createCategory() {
            let model = {
                categoryName: this.categoryName,
                description: this.description,
                manualContent: this.manualContent,
                isCommon: true,
                fileID: this.fileID?._id
            }
            let result = await this.API_V3.TreatmentCategory.create(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.reloadData()
            this.action_Close()
        },
        async updateCategory() {
            let model = {
                _id: this.template._id,
                categoryName: this.categoryName,
                description: this.description,
                manualContent: this.manualContent,
                fileID: this.fileID?._id
            }
            console.log('here', model)
            let result = await this.API_V3.TreatmentCategory.update(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.reloadData()
            this.action_Close()
        },
        async createFile() {
            let result = await this.API_V3.File.create(this.fileID)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.fileID = result.data.data
            console.log('create file : ', this.fileID)
        },
        async updateFile() {
            let result = await this.API_V3.File.update(this.fileID)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.fileID = result.data.data
            console.log('update file : ', this.fileID)
        },


        // MARK: - Actions
        async action_Save() {
            if (this.isEmpty_CategoryName || this.isEmpty_ManualContent) { return }
            if (this.fileID?.aws != undefined && (this.fileID?.aws?.length ?? 0) != 0) {
                if (this.fileID?._id == undefined || this.fileID?._id == '') { await this.createFile() }
                else { await this.updateFile() }
            }
            if (this.template?._id == undefined || this.template?._id == '') { await this.createCategory() }
            else { await this.updateCategory() }
        },
        action_Close() {
            this.$emit('close')
        },

        // MARK: - Emit
        reloadData() {
            this.$emit('reloadData')
        }
    }
}
</script>