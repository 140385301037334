<template>
    <li class="settings_list flex_column gap_10">
        <div class="flex_column gap_10">
            <div class="settings_list_title flex_aic_spb gap_10">
                <span>({{ code }}) {{ name }}<br>
                    <small class="lgray_txt">({{ iso_2 }}) {{ enName }}</small></span>
                <button class="btn_xs btn_blue" @click="action_Save" :disabled="!auth.electron_w">저장</button>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="5" name="1" id="" placeholder="첫번째 메시지 내용 입력" v-model="firstMessage"></textarea>
                <textarea class="w_full" rows="5" name="2" id="" placeholder="두번째 메시지 내용 입력" v-model="secondMessage"></textarea>
                <textarea class="w_full" rows="5" name="3" id="" placeholder="세번째 메시지 내용 입력" v-model="thirdMessage"></textarea>
                <small class="red_txt" v-show="false">내용을 입력해 주세요.</small>
            </div>
        </div>
    </li>
</template>


<script>
export default {
    props: {
        item: Object
    },
    data() {
        return {
            defaultMessage: undefined,

            firstMessage: undefined,
            secondMessage: undefined,
            thirdMessage: undefined,
        }
    },
    computed: {
        code: function() {
            return this.item?.internationalDialingCodes ?? '-'
        },
        name: function() {
            return this.item?.koreanCountryName ?? '-'
        },
        isEmpty: function() {
            return this.defaultMessage == undefined || this.defaultMessage == ''
        },
        iso_2: function() {
            return this.item?.countryCodeISOAlpha2 ?? '-'
        },
        enName: function() {
            return this.item?.englishCountryName ?? '-'
        }
    },
    watch: {
        item: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.defaultMessage = newValue?.defaultMessage ?? undefined
                this.firstMessage = newValue.firstMessage ?? undefined
                this.secondMessage = newValue.secondMessage ?? undefined
                this.thirdMessage = newValue.thirdMessage ?? undefined
            }
        }
    },
    methods: {
        // MARK: - Network
        async updateCountry() {
            let model = {
                _id: this.item?._id,
                defaultMessage: this.defaultMessage,
                firstMessage: this.firstMessage,
                secondMessage: this.secondMessage,
                thirdMessage: this.thirdMessage,
            }
            let result = await this.API_V3.Country.update(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('loadCountries')
        },

        // MARK: - Actions
        action_Save() {
            this.updateCountry()
        }
    },
}
</script>