<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="delete_pop_wrap">
            <div class="pop_title">
                <span class="title">{{ title }}</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon :size="24">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <b>{{ body }}</b>
            </div>
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Confirm">네</button>
                <button class="btn_l btn_gray" @click="action_Cancel">아니오</button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        title: String,
        body: String,
        isShow: Boolean,
        alertCallback: {
            type: Function,
            required: false
        }
    },
    methods: {
        action_Confirm() {
            if (this.alertCallback) { this.alertCallback(true) }
            else { this.$emit('alertCallback', true) }
            
        },
        action_Cancel() {
            if (this.alertCallback) { this.alertCallback(false) }
            else { this.$emit('alertCallback', false) }
        },
        action_Close() {
            if (this.alertCallback) { this.alertCallback(false) }
            else { this.$emit('alertCallback', false) }
        },
    }
}
</script>