const transforListToReact = (htmlString) => {
    // Transform <ul> (bullet points)
    htmlString = htmlString.replace(/<ul>.*?<\/ul>/gs, (match) => {
        return match.replace(/<li>.*?<\/li>/gs, (li) => {
            const textContent = li
                .replace(/<li>|<\/li>/g, '') // Remove <li> tags
                .replace(/<p>\s*<\/p>/g, '') // Remove empty <p> tags
                .replace(/<p>|<\/p>/g, '') // Remove remaining <p> tags
                .trim()
            if (!textContent) return ''

            return `<li data-list="bullet"><span class="ql-ui" contenteditable="false"></span> ${textContent}</li>`
        }).replace('<ul>', '<ol>').replace('</ul>', '</ol>') // Replace <ul> with <ol>
    })

    // Transform <ol> (numbered list)
    htmlString = htmlString.replace(/<ol>.*?<\/ol>/gs, (match) => {
        return match.replace(/<li>.*?<\/li>/gs, (li) => {
            const textContent = li
                .replace(/<li>|<\/li>/g, '') // Remove <li> tags
                .replace(/<p>\s*<\/p>/g, '') // Remove empty <p> tags
                .replace(/<p>|<\/p>/g, '') // Remove remaining <p> tags
                .trim()
            if (!textContent) return ''

            return `<li data-list="ordered"><span class="ql-ui" contenteditable="false"></span> ${textContent}</li>`
        })
    })

    return htmlString
}

const transformBulletPointToEditor = (htmlString) => {
    // Replace <ol> with <ul>
    htmlString = htmlString.replace(/<ol>/g, '<ul>').replace(/<\/ol>/g, '</ul>')
    htmlString = htmlString.replace(/<li[^>]*data-list="bullet"[^>]*>.*?<\/li>/gs, (match) => {
        const textContent = match
            .replace(/<li[^>]*>/, '') // Remove opening <li> tag
            .replace(/<\/li>/, '') // Remove closing </li> tag
            .replace(/<span[^>]*>.*?<\/span>/, '') // Remove <span> block
            .trim()
            
        if (!textContent) return ''
        return `<li><p>${textContent}</p></li>`
    })
    return htmlString
}
const transformNumberLineToEditor = (htmlString) => {
    // replace -> 
    return htmlString.replace(/<li[^>]*data-list="ordered"[^>]*>.*?<\/li>/gs, (match) => {
        const textContent = match
            .replace(/<li[^>]*>/, '') // Remove opening <li> tag
            .replace(/<\/li>/, '') // Remove closing </li> tag
            .replace(/<span[^>]*>.*?<\/span>/, '') // Remove <span> block
            .trim()
        if (!textContent) return ''
        return `<li><p>${textContent}</p></li>`
    })
}

const transformQuoteToReact = (htmlString) => {
    return htmlString.replace(/<blockquote>\s*<p>(.*?)<\/p>\s*<\/blockquote>/gs, (match, content) => {
        return `<blockquote>${content.trim()}</blockquote>`
    })
}
const transformQuoteToEditor = (htmlString) => {
    return htmlString.replace(/<blockquote>(.*?)<\/blockquote>/gs, (match, content) => {
        return `<blockquote><p>${content.trim()}</p></blockquote>`
    })
}

export const convertEditorToReact = (htmlString) => {
    htmlString = transforListToReact(htmlString)
    htmlString = transformQuoteToReact(htmlString).trim()
    console.log('converted string : ', htmlString)
    return htmlString
}

// 미완성 or 사용 가능성 없음
export const convertReactToEditor = (htmlString) => {
    htmlString = transformBulletPointToEditor(htmlString)
    htmlString = transformNumberLineToEditor(htmlString)
    htmlString = transformQuoteToEditor(htmlString).trim()
    console.log('converted string : ', htmlString)
    return htmlString
}