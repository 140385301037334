import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const count = (query) => {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_COUNT,
        params: query,
        headers: Util.getHeaders()
    })
}

export const find = (query) => {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_FIND,
        params: query,
        headers: Util.getHeaders()
    })
}

export const findOne = (query) => {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_FIND_ONE,
        params: query,
        headers: Util.getHeaders()
    })
}

export const update = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.HOSPITAL_UPDATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const create = (data) => {
    return axios({
        method: 'POST',
        url: URL.HOSPITAL_CREATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const findAccount = (m) => {
    return axios({
        method: 'GET',
        url: URL.HOSPITAL_ACCOUNT_FIND,
        params: m,
        headers: Util.getHeaders()
    })
}

export const createAccount = (m) => {
    return axios({
        method: 'POST',
        url: URL.HOSPITAL_ACCOUNT_CREATE,
        data: m,
        headers: Util.getHeaders()
    })
}

export const updateAccount = (m) => {
    return axios({
        method: 'PATCH',
        url: URL.HOSPITAL_ACCOUNT_UPDATE,
        data: m,
        headers: Util.getHeaders()
    })
}

export const removeAccount = (m) => {
    return axios({
        method: 'DELETE',
        url: URL.HOSPITAL_ACCOUNT_REMOVE,
        params: m,
        headers: Util.getHeaders()
    })
}