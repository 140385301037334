<template>
    <div class="form_list flex_column gap_15">
        <div class="title">
            적용할 태그<span class="red_txt">*</span>
        </div>
        <div class="h_scroll cont custom_tag_wrap pd_15 flex_column gap_20" style="max-height: 220px;">
            <div style="height:220px; position: relative; flex-shrink: 0" v-if="isLoading">
                <LoadingIndicate :is-loading="true" />
            </div>

            <template v-if="groupedData !== undefined">
                <div v-for="(folder, index) in groupedData.folders" :key="index"
                     class="custom_tag_group flex_column gap_15">
                    <!-- 폴더 -->
                    <label class="flex_aic checkbox_wrap">
                        <input type="checkbox"
                            :id="folder._id"
                            :checked="isFolderChecked(folder)"
                            @change="action_SelectFolder(folder)">
                        <span class="_treatment_tag_span">{{ folder.name }}</span>
                    </label>
                    <!-- 폴더 내 아이템 -->
                    <li v-for="item in folder.items" :key="item._id" class="custom_tag_list mgl_20">
                        <label class="flex_aic checkbox_wrap">
                            <input type="checkbox"
                                :id="item._id"
                                :checked="selectedTagIDs.includes(item._id)"
                                @change="action_SelectItem(item)">
                            <span class="_treatment_tag_span">{{ item.categoryName }}</span>
                        </label>
                    </li>
                </div>

                <!-- 폴더 없음 -->
                <div v-for="item in groupedData.noFolder" :key="item.id" class="custom_tag_group flex_column gap_15">
                    <ul class="custom_tag_list mgl_20">
                        <li class="custom_tag_item">
                            <label class="flex_aic checkbox_wrap">
                                <input type="checkbox"
                                    :id="item._id"
                                    @change="action_SelectItem(item)"
                                >
                                <span class="_treatment_tag_span">{{ item.categoryName }}</span>
                            </label>
                        </li>
                    </ul>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'

export default {
    name: 'TreatmentTagSetting',
    components: { LoadingIndicate },
    props: {
        hospital: Object,
        selectedTagIDs: {
            type: Array,
            default: () => []
        },
        usedTreatmentCategories: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            treatments: [],

            isLoading: false
        }
    },
    computed: {
        sortedTreatments: function() {
            return [...this.treatments].sort((a, b) => {
                if (a.sortNum === undefined) return 1
                if (b.sortNum === undefined) return -1
                return a.sortNum - b.sortNum
            })
        },
        groupedData: function() {
            if (!this.sortedTreatments || this.sortedTreatments.length === 0) {
                return undefined
            }

            const folders = []
            const noFolder = []

            for (const treatment of this.sortedTreatments) {
                if (treatment?.folderID && typeof treatment.folderID === 'object') {
                    const { _id, name, sortNum } = treatment.folderID

                    let folder = folders.find(f => f._id === _id)
                    if (!folder) {
                        folder = { _id, name, sortNum, items: [] }
                        folders.push(folder)
                    }

                    folder.items.push(treatment)

                } else {
                    noFolder.push(treatment)
                }
            }

            folders.sort((a, b) => {
                if (a.sortNum === undefined) return 1
                if (b.sortNum === undefined) return -1
                return b.sortNum - a.sortNum
            })

            return { folders, noFolder }
        }
    },
    mounted() {
        this.loadTreatmentTags()
    },
    methods: {
        async loadTreatmentTags() {
            try {
                this.isLoading = true
                const query = {
                    isRemoved: false,
                    hospitalID: this.hospital._id
                }
                const { data } = await this.API_V3.TreatmentCategory.find(query)
                if (data.code !== 0) {
                    throw new Error(data.message)
                }
                this.treatments = data.data
            } catch (error) {
                console.error('Error fetching countries:', error.message)
            } finally {
                this.isLoading = false
            }
        },
        action_SelectFolder(folder) {
            const folderItemIDs = folder.items.map(item => item._id)
            const isChecked = folderItemIDs.every(id => this.selectedTagIDs.includes(id))

            if (isChecked) {
                const newValue = this.selectedTagIDs.filter(id => !folderItemIDs.includes(id))
                this.$emit('setSelectedTagIDs', newValue)
            } else {
                const newValue = [...this.selectedTagIDs, ...folderItemIDs]
                this.$emit('setSelectedTagIDs', newValue)
            }
        },
        action_SelectItem(item) {
            const isChecked = this.selectedTagIDs.includes(item._id);
            const newValue = isChecked
                ? this.selectedTagIDs.filter(id => id !== item._id)
                : [...this.selectedTagIDs, item._id];

            this.$emit('setSelectedTagIDs', newValue);
        },
        
        // MARK: - Private
        isFolderChecked(folder) {
            const folderItemIDs = folder.items.map(item => item._id)
            return folderItemIDs.every(id => this.selectedTagIDs.includes(id))
        },
    }
}

</script>

<style>
._treatment_tag_span {
    word-break: break-all;
}
</style>

