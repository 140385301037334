<template>
    <div class="pop_full" v-if="isShow">
        <div class="content_wrap">
            <div class="page_title">
                <span>어드민 계정 설정</span>
                <a class="close mgl_auto" @click="action_Close"><v-icon>mdi-close</v-icon>닫기</a>
            </div>
            <div class="h_scroll adm_ac">
                <div class="set_row">
                    <div class="title"><b class="est">아이디</b></div>
                    <div class="content">
                        <input type="text" class="input_s w_full" placeholder="영문, 숫자 3~20자리" v-model="id">
                        <small class="red_txt" v-if="!hasID">아이디를 입력해 주세요.</small>
                    </div>
                </div>
                <div class="set_row">
                    <div class="title"><b class="est">비밀번호</b></div>
                    <div class="content">
                        <input type="password" class="input_s w_full" placeholder="영문, 숫자 3~20자리" v-model="password">
                    </div>
                </div>
                <div class="set_row">
                    <div class="title"><b class="est">이름</b></div>
                    <div class="content">
                        <input type="text" class="input_s w_full" placeholder="이름" v-model="realName">
                    </div>
                </div>
                <div class="set_row">
                    <div class="title"><b class="est">고객이 보는 이름</b></div>
                    <div class="content">
                        <input type="text" class="input_s w_full" placeholder="고객이 보는 이름" v-model="name">
                    </div>
                </div>
                <div class="set_row">
                    <div class="title"><b class="est">호칭</b></div>
                    <div class="content">
                        <input type="text" class="input_s w_full" placeholder="간호사" v-model="jobType">
                    </div>
                </div>
                <div class="set_row">
                    <div class="title">
                        <b class="est">유저타입</b>
                        <small class="lgray_txt">10000 : 기본</small>
                    </div>
                    <div class="content">
                        <input type="text" class="input_s w_full" placeholder="10000" v-model="type" disabled>
                    </div>
                </div>
                <div class="set_row">
                    <div class="title">
                        <b>문자 수신 번호</b>
                        <small class="lgray_txt">앱가입자 알림 문자받을 번호, 미설정시 발송X</small>
                    </div>
                    <div class="content">
                        <input type="text" class="input_s w_full" placeholder="01012345678" disabled>
                    </div>
                </div>
                <div class="set_row">
                    <div class="title">
                        <b>프로필 사진</b>
                    </div>
                    <div class="content">
                        <div class="hover_thumb">
                            <label for="img_upload" class="img_add btn_blue_line txt_center" @click="action_RegistPhoto">등록</label>
                            <small class="lgray_txt">현재 저장된 FileID : {{ photoID }}</small>
                            <FilePicker v-model="selectedFile" v-bind:only-image="true" v-bind:is-multiple="false" v-bind:is-open="isShowFilePicker" />
                            <div class="img_overlay">
                                <img :src="photoPath" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 권한 설정 시작 -->
                <b class="mgt_40 mgb_10">권한설정</b>
                <!-- v1 병원관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <div class="title">V1 병원 관리</div>
                    </div>
                    <div class="form_flex_cont col">
                        <div class="form_list">
                            <div class="title">병원 관리</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="hospitalManagement_v">
                                        <span class="checkmark"></span>
                                        <b>조회</b>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="hospitalManagement_w" :disabled="hospitalManagement_v == false">
                                        <span class="checkmark"></span>
                                        <b>수정</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form_list">
                            <div class="title">서비스 상태 변경</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="hospitalState_v">
                                        <span class="checkmark"></span>
                                        <b>조회</b>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="hospitalState_w" :disabled="hospitalState_v == false">
                                        <span class="checkmark"></span>
                                        <span>수정</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- v2 병원 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <div class="title">V2 병원 관리</div>
                    </div>
                    <div class="form_flex_cont col">
                        <div class="form_list">
                            <div class="title">병원 관리</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="v2HospitalManagement_v">
                                        <span class="checkmark"></span>
                                        <b>조회</b>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="v2HospitalManagement_w" :disabled="v2HospitalManagement_v == false">
                                        <span class="checkmark"></span>
                                        <b>수정</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form_list">
                            <div class="title">스마트닥터 연동 및 카카오톡 연동</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="v2HospitalIntegration_v">
                                        <span class="checkmark"></span>
                                        <b>조회</b>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="v2HospitalIntegration_w" :disabled="v2HospitalIntegration_v == false">
                                        <span class="checkmark"></span>
                                        <span>수정</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form_list">
                            <div class="title">서비스 상태 변경</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="v2HospitalState_v">
                                        <span class="checkmark"></span>
                                        <b>조회</b>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="v2HospitalState_w" :disabled="v2HospitalState_v == false">
                                        <span class="checkmark"></span>
                                        <span>수정</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 서비스 매니저 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">서비스 매니저 관리</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="serviceManager_v">
                                    <span class="checkmark"></span>
                                    <span>조회</span>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="serviceManager_w" :disabled="serviceManager_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 홈케어콘텐츠 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">홈케어콘텐츠 관리</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="videoContent_v">
                                    <span class="checkmark"></span>
                                    <span>조회</span>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="videoContent_w" :disabled="videoContent_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 온보딩 설정 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">온보딩 설정</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="onboarding_v">
                                    <span class="checkmark"></span>
                                    <b>조회</b>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="onboarding_w" :disabled="onboarding_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 앱 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">앱 관리</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="app_v">
                                    <span class="checkmark"></span>
                                    <span>조회</span>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="app_w" :disabled="app_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 일렉트론 설정 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">일렉트론 설정</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="electron_v">
                                    <span class="checkmark"></span>
                                    <b>조회</b>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="electron_w" :disabled="electron_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 연동 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">연동 관리</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="integration_v">
                                    <span class="checkmark"></span>
                                    <b>조회</b>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="integration_w" :disabled="integration_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 모니터링 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">모니터링</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="monitoring_v">
                                    <span class="checkmark"></span>
                                    <span>조회</span>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="monitoring_w" :disabled="monitoring_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 이벤트 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">이벤트 관리</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="eventManagement_v">
                                    <span class="checkmark"></span>
                                    <b>조회</b>
                                </label>
                                <span class="lgray_txt mgl_15 mgr_40">
                                    -
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 예약채팅 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">예약채팅 관리</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="reservedChat_v">
                                    <span class="checkmark"></span>
                                    <b>조회</b>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="reservedChat_w" :disabled="reservedChat_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 기본 템플릿 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title">
                        <div class="title">기본 템플릿</div>
                    </div>
                    <div class="form_flex_cont col">
                        <div class="form_list">
                            <div class="title">치료태그 매뉴얼</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="treatmentCategory_v">
                                        <span class="checkmark"></span>
                                        <span>조회</span>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="treatmentCategory_w" :disabled="treatmentCategory_v == false">
                                        <span class="checkmark"></span>
                                        <span>수정</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form_list">
                            <div class="title">상담자동화</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="algorithm_v">
                                        <span class="checkmark"></span>
                                        <span>조회</span>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="algorithm_w" :disabled="algorithm_v == false">
                                        <span class="checkmark"></span>
                                        <span>수정</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="form_list">
                            <div class="title">알림톡 메시지</div>
                            <div class="cont row">
                                <div class="mgl_auto">
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="alrimtalk_v">
                                        <span class="checkmark"></span>
                                        <span>조회</span>
                                    </label>
                                    <label class="check_wrap">
                                        <input type="checkbox" v-model="alrimtalk_w" :disabled="alrimtalk_v == false">
                                        <span class="checkmark"></span>
                                        <span>수정</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 예약채팅 관리 -->
                <div class="form_flex_wrap">
                    <div class="form_flex_title bd_0">
                        <div class="title">어드민 계정</div>
                        <div class="cont row">
                            <div class="mgl_auto">
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="adminAccount_v">
                                    <span class="checkmark"></span>
                                    <span>조회</span>
                                </label>
                                <label class="check_wrap">
                                    <input type="checkbox" v-model="adminAccount_w" :disabled="adminAccount_v == false">
                                    <span class="checkmark"></span>
                                    <span>수정</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 저장 버튼-->
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Save">저장</button>
            </div>
        </div>
    </div>
</template>


<script>
import { FileUploadController } from '@/controller/FileUploadController'
import FilePicker from '@/views/main/ShareComponents/FilePicker.vue'

export default {
    components: { FilePicker },
    props: {
        isShow: Boolean,
        selectedUser: {
            type: Object,
            default: () => undefined
        }
    },
    data() {
        return {
            id: undefined,
            password: undefined,
            name: undefined,
            realName: undefined,
            jobType: undefined,
            type: 10000,
            photo: undefined,

            selectedFile: undefined,
            isShowFilePicker: false,

            adminAuthorizationTypeID: undefined,
            hospitalManagement_v: true,
            hospitalManagement_w: true,
            hospitalState_v: true,
            hospitalState_w: false,
            v2HospitalManagement_v: true,
            v2HospitalManagement_w: true,
            v2HospitalState_v: true,
            v2HospitalState_w: false,
            v2HospitalIntegration_v: true,
            v2HospitalIntegration_w: false,
            serviceManager_v: false,
            serviceManager_w: false,
            videoContent_v: false,
            videoContent_w: false,
            onboarding_v: true,
            onboarding_w: false,
            app_v: false,
            app_w: false,
            electron_v: true,
            electron_w: false,
            integration_v: true,
            integration_w: false,
            monitoring_v: false,
            monitoring_w: false,
            eventManagement_v: true,
            algorithm_v: false,
            algorithm_w: false,
            reservedChat_v: true,
            reservedChat_w: false,
            treatmentCategory_v: false,
            treatmentCategory_w: false,
            alrimtalk_v: false,
            alrimtalk_w: false,
            adminAccount_v: false,
            adminAccount_w: false,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { return }
                console.log('here')
                this.loadData()
            }
        },
        hospitalManagement_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.hospitalManagement_w = false }
            }
        },
        hospitalState_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.hospitalState_w = false }
            }
        },
        v2HospitalManagement_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.v2HospitalManagement_w = false }
            }
        },
        v2HospitalState_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.v2HospitalState_w = false }
            }
        },
        v2HospitalIntegration_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.v2HospitalIntegration_w = false }
            }
        },
        serviceManager_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.serviceManager_w = false }
            }
        },
        videoContent_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.videoContent_w = false }
            }
        },
        onboarding_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.onboarding_w = false }
            }
        },
        app_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.app_w = false }
            }
        },
        electron_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.electron_w = false }
            }
        },
        integration_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.integration_w = false }
            }
        },
        monitoring_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.monitoring_w = false }
            }
        },
        algorithm_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.algorithm_w = false }
            }
        },
        reservedChat_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.reservedChat_w = false }
            }
        },
        treatmentCategory_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.treatmentCategory_w = false }
            }
        },
        alrimtalk_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.alrimtalk_w = false }
            }
        },
        adminAccount_v: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { this.adminAccount_w = false }
            }
        },
        selectedFile: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                if (newValue == undefined) { return }
                this.createFile(newValue)
                this.isShowFilePicker = false
            }
        }
    },
    computed: {
        hasID: function() {
            return this.id != undefined && this.id != ''
        },
        photoID: function() {
            return this.photo?._id ?? '-'
        },
        photoPath: function() {
            let paths = this.photo?.paths
            if (paths == undefined || paths?.length <= 0) { return }
            return paths[0]
        },
        
        userModel: function() {
            return {
                _id: this.selectedUser?._id,
                id: this.id,
                password: this.password,
                name: this.name,
                realName: this.realName,
                jobType: this.jobType,
                type: this.type,
                photo: this.photo?._id,
            }
        },
        authModel: function() {
            return {
                _id: this.adminAuthorizationTypeID,
                userID: this.selectedUser?._id,
                hospitalManagement_v: this.hospitalManagement_v,
                hospitalManagement_w: this.hospitalManagement_w,
                hospitalState_v: this.hospitalState_v,
                hospitalState_w: this.hospitalState_w,
                v2HospitalManagement_v: this.v2HospitalManagement_v,
                v2HospitalManagement_w: this.v2HospitalManagement_w,
                v2HospitalState_v: this.v2HospitalState_v,
                v2HospitalState_w: this.v2HospitalState_w,
                v2HospitalIntegration_v: this.v2HospitalIntegration_v,
                v2HospitalIntegration_w: this.v2HospitalIntegration_w,
                serviceManager_v: this.serviceManager_v,
                serviceManager_w: this.serviceManager_w,
                videoContent_v: this.videoContent_v,
                videoContent_w: this.videoContent_w,
                onboarding_v: this.onboarding_v,
                onboarding_w: this.onboarding_w,
                app_v: this.app_v,
                app_w: this.app_w,
                electron_v: this.electron_v,
                electron_w: this.electron_w,
                integration_v: this.integration_v,
                integration_w: this.integration_w,
                monitoring_v: this.monitoring_v,
                monitoring_w: this.monitoring_w,
                eventManagement_v: this.eventManagement_v,
                algorithm_v: this.algorithm_v,
                algorithm_w: this.algorithm_w,
                reservedChat_v: this.reservedChat_v,
                reservedChat_w: this.reservedChat_w,
                treatmentCategory_v: this.treatmentCategory_v,
                treatmentCategory_w: this.treatmentCategory_w,
                alrimtalk_v: this.alrimtalk_v,
                alrimtalk_w: this.alrimtalk_w,
                adminAccount_v: this.adminAccount_v,
                adminAccount_w: this.adminAccount_w,
            }
        }
    },
    methods: {
        setAuthorizationType(auth) {
            this.adminAuthorizationTypeID = auth?._id ?? undefined
            this.hospitalManagement_v = auth.hospitalManagement_v ?? true
            this.hospitalManagement_w = auth.hospitalManagement_w ?? true
            this.hospitalState_v = auth.hospitalState_v ?? true
            this.hospitalState_w = auth.hospitalState_w ?? false
            this.v2HospitalManagement_v = auth.v2HospitalManagement_v ?? true
            this.v2HospitalManagement_w = auth.v2HospitalManagement_w ?? true
            this.v2HospitalState_v = auth.v2HospitalState_v ?? true
            this.v2HospitalState_w = auth.v2HospitalState_w ?? false
            this.v2HospitalIntegration_v = auth.v2HospitalIntegration_v ?? true
            this.v2HospitalIntegration_w = auth.v2HospitalIntegration_w ?? false
            this.serviceManager_v = auth.serviceManager_v ?? true
            this.serviceManager_w = auth.serviceManager_w ?? false
            this.videoContent_v = auth.videoContent_v ?? false
            this.videoContent_w = auth.videoContent_w ?? false
            this.onboarding_v = auth.onboarding_v ?? true
            this.onboarding_w = auth.onboarding_w ?? false
            this.app_v = auth.app_v ?? false
            this.app_w = auth.app_w ?? false
            this.electron_v = auth.electron_v ?? true
            this.electron_w = auth.electron_w ?? false
            this.integration_v = auth.integration_v ?? true
            this.integration_w = auth.integration_w ?? false
            this.monitoring_v = auth.monitoring_v ?? false
            this.monitoring_w = auth.monitoring_w ?? false
            this.eventManagement_v = auth.eventManagement_v ?? true
            this.algorithm_v = auth.algorithm_v ?? false
            this.algorithm_w = auth.algorithm_w ?? false
            this.reservedChat_v = auth.reservedChat_v ?? true
            this.reservedChat_w = auth.reservedChat_w ?? false
            this.treatmentCategory_v = auth.treatmentCategory_v ?? false
            this.treatmentCategory_w = auth.treatmentCategory_w ?? false
            this.alrimtalk_v = auth.alrimtalk_v ?? false
            this.alrimtalk_w = auth.alrimtalk_w ?? false
            this.adminAccount_v = auth.adminAccount_v ?? false
            this.adminAccount_w = auth.adminAccount_w ?? false
        },
        // MARK: - Network
        async loadData() {
            await this.loadUser()
            await this.loadAuth()
        },
        async loadUser() {
            let result = undefined
            if (this.selectedUser?._id != undefined) {
                result = await this.API_V3.AdminUser.findOne({_id: this.selectedUser?._id})
            }
            if (this.selectedUser?._id != undefined && result?.data?.code != 0) {
                alert(result.data.message)
            }
            let user = result?.data?.data ?? {}
            this.id = user.id ?? undefined
            this.password = user.password ?? undefined
            this.name = user.name ?? undefined
            this.realName = user.realName ?? undefined
            this.jobType = user.jobType ?? undefined
            this.type = user.type ?? 10000
            this.photo = user.photo ?? undefined

        },
        async loadAuth() {
            let result = undefined
            if (this.selectedUser?._id != undefined) {
                result = await this.API_V3.AdminAuthorizationType.findOne({userID: this.selectedUser?._id})
            }
            if (this.selectedUser?._id != undefined && result?.data?.code != 0) {
                alert(result.data.message)
            }
            let auth = result?.data?.data ?? {}
            this.setAuthorizationType(auth)
        },
        async updateUser() {
            let result = undefined
            let userModel = this.userModel
            if (userModel._id == undefined) {
                result = await this.API_V3.AdminUser.create(userModel)
            }
            else {
                result = await this.API_V3.AdminUser.update(userModel)
            }
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let userID = result?.data?.data?._id
            let authModel = this.authModel
            authModel.userID = userID
            if (authModel._id == undefined) {
                result = await this.API_V3.AdminAuthorizationType.create(authModel)
            }
            else {
                result = await this.API_V3.AdminAuthorizationType.update(authModel)
            }
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.loadData()
        },

        // MARK: - File
        uploadFile(files) {
            return new Promise( (resolve, reject) => {
                FileUploadController.uploadFiles(files, [], async (results) => {
                    let datas = results.data
                    if (datas.length == 0) {
                        reject()
                        return
                    }

                    let result = datas[0]
                    if (result.data.code != 0) {
                        reject()
                        return
                    }
                    resolve(result.data.data)
                })
            })
        },
        async createFile(files) {
            if (files == undefined || files?.length == 0) { return }
            let fileModel = await this.uploadFile(files)
            this.photo = fileModel
        },

        
        // MARK: - Actions
        async action_Save() {
            this.isLoading = true
            await this.updateUser()
            this.isLoading = false
        },
        action_Close() {
            this.$emit('close')
        },
        action_RegistPhoto() {
            this.isShowFilePicker = true
        }
    }
}
</script>