/*
let HOSTURL = ''

if (process.env.NODE_ENV == 'development') { 
    HOSTURL = 'https://dev.api.afterdoc.net'
    console.log(process.env) 
}
if (process.env.NODE_ENV == 'production') { 
    HOSTURL = 'https://api.afterdoc.net' 
    console.log(process.env)
}

const HOST = HOSTURL
*/

/**
 *  HOST
 */

// API HOST
const HOST                                  = 'https://api.afterdoc.net'
// const HOST                                  = 'https://dev.api.afterdoc.net'
// const HOST                                  = 'http://192.168.1.15:8080'

// SI-API HOST
const AL_HOST                               = 'https://almighty.si.afterdoc.net'
// const AL_HOST                               = 'https://dev.almighty.si.afterdoc.net'
// const AL_HOST                               = 'http://localhost:8080'

// 3rd party - SmartDoctor HOST
const SD_HOST                               = 'https://reservation.api.receipt.smartdoctor.systems'


const URL_AUTH = HOST + '/v2/auth'

const AUTH_LOGIN = URL_AUTH + '/login/el'

const URL_V1_API = HOST + '/api'

const GET_FILE = URL_V1_API + '/file'
const UPLOAD_FILE = URL_V1_API + '/file/upload'
const CREATE_FILE = URL_V1_API + '/file/create'

// eslint-disable-next-line
const URL_API = HOST + '/v2/api'

const URL_ADMIN = HOST + '/v2/admin'

const URL_HOSPITAL = URL_ADMIN + '/hospital'
const HOSPITAL_FIND = URL_HOSPITAL + '/el/find'
const HOSPITAL_FIND_NURSE = URL_HOSPITAL + '/el/find/nurse'
const HOSPITAL_CREATE = URL_HOSPITAL + '/el/create'
const HOSPITAL_COUNT = URL_HOSPITAL + '/el/count/'
const HOSPITAL_UPDATE = URL_HOSPITAL + '/el/update'
const HOSPITAL_FIND_DOCTOR = URL_HOSPITAL + '/el/find/doctor'
const HOSPITAL_COUNT_DOCTOR = URL_HOSPITAL + '/el/count/doctor'
const HOSPITAL_CREATE_DOCTOR = URL_HOSPITAL + '/el/create/doctor'
const HOSPITAL_UPDATE_DOCTOR = URL_HOSPITAL + '/el/update/doctor'

const URL_HOSPITAL_AUTHTYPE = URL_ADMIN + '/authorizationtype'
const HOSPITAL_AUTHTYPE_FIND = URL_HOSPITAL_AUTHTYPE + '/el/find'

const URL_HSERVICE = URL_ADMIN + '/hservice'
const HSERVICE_UPDATE_SUSPEND_ALL_BY_HOSPITALID = URL_HSERVICE + '/el/update/suspend/all'
const HSERVICE_FIND_NOT_CLOSED_BY_HOSPITALID = URL_HSERVICE + '/el/find/isclosed'

const URL_SERVICESETTING = URL_ADMIN + '/servicesetting'
const SERVICESETTING_FIND = URL_SERVICESETTING + '/el/find'
const SERVICESETTING_FINDONE = URL_SERVICESETTING + '/el/find/one'
const SERVICESETTING_FINDUSINGVC = URL_SERVICESETTING + '/el/find/usingvc'
const SERVICESETTING_CREATE = URL_SERVICESETTING + '/el/create'
const SERVICESETTING_CREATE_SAMPLE = URL_SERVICESETTING + '/el/create/sample'
const SERVICESETTING_COUNT = URL_SERVICESETTING + '/el/count'
const SERVICESETTING_UPDATE = URL_SERVICESETTING + '/el/update'
const SERVICESETTING_UPDATE_SAMPLE = URL_SERVICESETTING + '/el/update/sample'

const URL_NURSE = URL_ADMIN + '/nurse'
const NURSE_FIND = URL_NURSE + '/el/find'
const NURSE_CREATE = URL_NURSE + '/el/create'
const NURSE_COUNT = URL_NURSE + '/el/count'
const NURSE_UPDATE = URL_NURSE + '/el/update'

const URL_TREATMENTCATEGORY = URL_API + '/treatmentcategory'
const TREATMENTCATEGORY_CREATE = URL_TREATMENTCATEGORY + '/el/create'
const TREATMENTCATEGORY_UPDATE = URL_TREATMENTCATEGORY + '/el/update'
const TREATMENTCATEGORY_REMOVE = URL_TREATMENTCATEGORY + '/el/remove'

const URL_TREATMENTCAUTION = URL_API + '/treatmentcaution/el'
const TREATMENTCAUTION_FIND = URL_TREATMENTCAUTION + '/find'
const TREATMENTCAUTION_CREATE = URL_TREATMENTCAUTION + '/create'
const TREATMENTCAUTION_UPDATE = URL_TREATMENTCAUTION + '/update'
const TREATMENTCAUTION_REMOVE = URL_TREATMENTCAUTION + '/remove'

const URL_HOMECARE = URL_ADMIN + '/homecare'

const URL_VIDEOCONTENT = URL_HOMECARE + '/videocontent'

const URL_HLSVIDEO = URL_VIDEOCONTENT + '/hlsvideo'
const HLSVIDEO_FIND = URL_HLSVIDEO + '/el/find'
const HLSVIDEO_CREATE = URL_HLSVIDEO + '/el/create'
const HLSVIDEO_UPDATE = URL_HLSVIDEO + '/el/update'
const HLSVIDEO_COUNT = URL_HLSVIDEO + '/el/count'

const URL_VIDEOCONTENTGLOBALSETTING = URL_VIDEOCONTENT + '/videocontentglobalsetting'
const VIDEOCONTENTGLOBALSETTING_FIND = URL_VIDEOCONTENTGLOBALSETTING + '/el/find'
const VIDEOCONTENTGLOBALSETTING_CREATE = URL_VIDEOCONTENTGLOBALSETTING + '/el/create'
const VIDEOCONTENTGLOBALSETTING_UPDATE = URL_VIDEOCONTENTGLOBALSETTING + '/el/update'

const URL_VCONTENT = URL_VIDEOCONTENT + '/videocontent'
const VCONTENT_FIND = URL_VCONTENT + '/el/find'
const VCONTENT_CREATE = URL_VCONTENT + '/el/create'
const VCONTENT_UPDATE = URL_VCONTENT + '/el/update'
const VCONTENT_REMOVE = URL_VCONTENT + '/el/remove'
const VCONTENT_COUNT = URL_VCONTENT + '/el/count'

const URL_VIDEOCONTENTHOSPITAL = URL_VIDEOCONTENT + '/videocontenthospital'
const VIDEOCONTENTHOSPITAL_FIND = URL_VIDEOCONTENTHOSPITAL + '/el/find'
const VIDEOCONTENTHOSPITAL_CREATE = URL_VIDEOCONTENTHOSPITAL + '/el/create'
const VIDEOCONTENTHOSPITAL_UPDATE = URL_VIDEOCONTENTHOSPITAL + '/el/update'
const VIDEOCONTENTHOSPITAL_COPY_BY_HOSPITAL_CATEGORY = URL_VIDEOCONTENTHOSPITAL + '/el/copy/hospitalcategory'
const VIDEOCONTENTHOSPITAL_COPY_BY_GLOBAL_CATEGORY = URL_VIDEOCONTENTHOSPITAL + '/el/copy/globalcategory'
const VIDEOCONTENTHOSPITAL_COPY_ALL_VCH = URL_VIDEOCONTENTHOSPITAL + '/el/copy/all'

const URL_VIDEOCONTENTCATEGORY = URL_VIDEOCONTENT + '/videocontentcategory'
const VIDEOCONTENTCATEGORY_FIND = URL_VIDEOCONTENTCATEGORY + '/el/find'
const VIDEOCONTENTCATEGORY_FINDONE = URL_VIDEOCONTENTCATEGORY + '/el/find/one'
const VIDEOCONTENTCATEGORY_FINDTOP = URL_VIDEOCONTENTCATEGORY + '/el/find/top'
const VIDEOCONTENTCATEGORY_FINDLAST = URL_VIDEOCONTENTCATEGORY + '/el/find/last'
const VIDEOCONTENTCATEGORY_FINDCONDITIONING = URL_VIDEOCONTENTCATEGORY + '/el/find/conditioning'
const VIDEOCONTENTCATEGORY_CREATE = URL_VIDEOCONTENTCATEGORY + '/el/create'
const VIDEOCONTENTCATEGORY_UPDATE = URL_VIDEOCONTENTCATEGORY + '/el/update'
const VIDEOCONTENTCATEGORY_UPDATE_HIDDEN = URL_VIDEOCONTENTCATEGORY + '/el/update/hidden'
const VIDEOCONTENTCATEGORY_UPDATE_TYPENAME = URL_VIDEOCONTENTCATEGORY + '/el/update/typename'


const URL_HEALTHCONTENT = URL_ADMIN + '/healthcontent'

const URL_HEALTHCONTENTPUSH = URL_HEALTHCONTENT + '/push'
const HEALTHCONTENTPUSH_FIND = URL_HEALTHCONTENTPUSH + '/el/find'
const HEALTHCONTENTPUSH_CREATE = URL_HEALTHCONTENTPUSH + '/el/create'
const HEALTHCONTENTPUSH_REMOVE = URL_HEALTHCONTENTPUSH + '/el/remove'

const URL_HCONTENT = URL_HEALTHCONTENT + '/content'
const HCONTENT_FIND = URL_HCONTENT + '/el/find'
const HCONTENT_CREATE = URL_HCONTENT + '/el/create'
const HCONTENT_UPDATE = URL_HCONTENT + '/el/update'
const HCONTENT_REMOVE = URL_HCONTENT + '/el/remove'

const URL_HEALTHCONTENTCATEGORY = URL_HEALTHCONTENT + '/category'
const HEALTHCONTENTCATEGORY_FIND = URL_HEALTHCONTENTCATEGORY + '/el/find'


const URL_HOSPITALCONTENT = URL_API + '/hospitalcontents'

const URL_HOCONTENT = URL_HOSPITALCONTENT + '/content'
const HOCONTENT_FIND = URL_HOCONTENT + '/el/find'
const HOCONTENT_CREATE = URL_HOCONTENT + '/el/create'
const HOCONTENT_UPDATE = URL_HOCONTENT + '/el/update'
const HOCONTENT_REMOVE = URL_HOCONTENT + '/el/remove'

const URL_HOSPITALCONTENTCATEGORY = URL_HOSPITALCONTENT + '/category'
const HOSPITALCONTENTCATEGORY_FIND_ONE = URL_HOSPITALCONTENTCATEGORY + '/el/find/one'

const URL_AFTERDOCAPPSETTING = URL_ADMIN + '/setting'

const URL_VERSIONINFO = URL_AFTERDOCAPPSETTING + '/version'
const APPVERSION_FIND = URL_VERSIONINFO + '/el/find'
const APPVERSION_UPDATE = URL_VERSIONINFO + '/el/update'

const URL_NOTIFICATION_SOUND = URL_API + '/notificationsound/el'
const NOTIFICATION_SOUND_COUNT = URL_NOTIFICATION_SOUND + '/count'
const NOTIFICATION_SOUND_FIND = URL_NOTIFICATION_SOUND + ''
const NOTIFICATION_SOUND_CREATE = URL_NOTIFICATION_SOUND + '/create'
const NOTIFICATION_SOUND_UPDATE = URL_NOTIFICATION_SOUND + '/update'

const ANSWERING_CHAT_SETTING = URL_ADMIN + '/answeringchatsetting/el'
const ANSWERING_CHAT_SETTING_FIND   = ANSWERING_CHAT_SETTING + '/find'
const ANSWERING_CHAT_SETTING_CREATE = ANSWERING_CHAT_SETTING + '/create'
const ANSWERING_CHAT_SETTING_UPDATE =ANSWERING_CHAT_SETTING  + '/update'

const ANSWERING_CHAT = URL_ADMIN + '/answeringchat/el'
const ANSWERING_CHAT_FIND = ANSWERING_CHAT + '/find'
const ANSWERING_CHAT_COUNT = ANSWERING_CHAT + '/count'

const URL_EVENT = URL_ADMIN + '/event/hospital/event/el'
const EVENT_FIND = URL_EVENT + '/find'
const EVENT_FIND_ONE = EVENT_FIND + '/one'

const URL_EVENT_STATS =  URL_ADMIN + '/stats/event/hospital/event/el'
const EVENT_STATS_FIND = URL_EVENT_STATS + '/find'
const EVENT_STATS_COUNT = URL_EVENT_STATS + '/count'

const URL_EVENT_PUSH =  URL_ADMIN + '/stats/event/hospital/eventpush/el'
const EVENT_PUSH_FIND = URL_EVENT_PUSH + '/find'
const EVENT_PUSH_COUNT = URL_EVENT_PUSH + '/count'

const URL_RESERVED_SMS = URL_ADMIN + '/reservedsms/el'
const RESERVED_SMS_FIND = URL_RESERVED_SMS + '/find'
const RESERVED_SMS_COUNT = URL_RESERVED_SMS + '/count'

const URL_CA_RESERVED_CHAT = URL_ADMIN + '/ca/careservedchat/el'
const CA_RESERVED_CHAT_FIND = URL_CA_RESERVED_CHAT + '/find'
const CA_RESERVED_CHAT_COUNT = URL_CA_RESERVED_CHAT + '/count'
const CA_RESERVED_CHAT_CREATE = URL_CA_RESERVED_CHAT + '/create'
const CA_RESERVED_CHAT_CREATE_MANY = URL_CA_RESERVED_CHAT + '/createmany'

// SD API
const SD_API                                = SD_HOST + '/reservation-api'
const SD_FIND_LABELS                        = SD_API + '/v2/reservations/labels'

// SI-API
const AL_URL                                = AL_HOST + '/almighty/admin/hospitalmap'
const AL_PATIENT_REQUEST                    = AL_HOST + '/almighty/admin/patientrequest/find'
const AL_PATIENT_REQUEST_COUNT              = AL_HOST + '/almighty/admin/patientrequest/count'

const AL_HOSPITAL_FIND                      = AL_URL + '/find/one'
const AL_HOSPITAL_CREATE                    = AL_URL + '/create'
const AL_HOSPITAL_UPDATE                    = AL_URL + '/update'

const AL_INTEGRATION_SETTING                = URL_ADMIN + '/integrationsetting/el/find/one'
const AL_INTEGRATION_SETTING_UPDATE         = URL_ADMIN + '/integrationsetting/el/update'
const AL_INTEGRATION_SETTING_CREATE         = URL_ADMIN + '/integrationsetting/el/create'


const EL_HOSPITAL_FIND = URL_API + '/hospital/el/find'
const EL_USER_FIND_ONE = URL_API + '/user/el/find/one'

export default {
    AUTH_LOGIN: AUTH_LOGIN,

    GET_FILE: GET_FILE,
    UPLOAD_FILE: UPLOAD_FILE,
    CREATE_FILE: CREATE_FILE,

    HOSPITAL_FIND: HOSPITAL_FIND,
    HOSPITAL_FIND_NURSE: HOSPITAL_FIND_NURSE,
    HOSPITAL_CREATE: HOSPITAL_CREATE,
    HOSPITAL_COUNT: HOSPITAL_COUNT,
    HOSPITAL_UPDATE: HOSPITAL_UPDATE,
    HOSPITAL_FIND_DOCTOR: HOSPITAL_FIND_DOCTOR,
    HOSPITAL_COUNT_DOCTOR: HOSPITAL_COUNT_DOCTOR,
    HOSPITAL_CREATE_DOCTOR: HOSPITAL_CREATE_DOCTOR,
    HOSPITAL_UPDATE_DOCTOR: HOSPITAL_UPDATE_DOCTOR,

    HOSPITAL_AUTHTYPE_FIND: HOSPITAL_AUTHTYPE_FIND,

    HSERVICE_UPDATE_SUSPEND_ALL_BY_HOSPITALID: HSERVICE_UPDATE_SUSPEND_ALL_BY_HOSPITALID,
    HSERVICE_FIND_NOT_CLOSED_BY_HOSPITALID: HSERVICE_FIND_NOT_CLOSED_BY_HOSPITALID,

    SERVICESETTING_FIND: SERVICESETTING_FIND,
    SERVICESETTING_FINDONE: SERVICESETTING_FINDONE,
    SERVICESETTING_FINDUSINGVC: SERVICESETTING_FINDUSINGVC,
    SERVICESETTING_CREATE: SERVICESETTING_CREATE,
    SERVICESETTING_CREATE_SAMPLE: SERVICESETTING_CREATE_SAMPLE,
    SERVICESETTING_COUNT: SERVICESETTING_COUNT,
    SERVICESETTING_UPDATE: SERVICESETTING_UPDATE,
    SERVICESETTING_UPDATE_SAMPLE: SERVICESETTING_UPDATE_SAMPLE,

    TREATMENTCAUTION_FIND: TREATMENTCAUTION_FIND,
    TREATMENTCAUTION_CREATE: TREATMENTCAUTION_CREATE,
    TREATMENTCAUTION_UPDATE: TREATMENTCAUTION_UPDATE,
    TREATMENTCAUTION_REMOVE: TREATMENTCAUTION_REMOVE,

    TREATMENTCATEGORY_CREATE: TREATMENTCATEGORY_CREATE,
    TREATMENTCATEGORY_UPDATE: TREATMENTCATEGORY_UPDATE,
    TREATMENTCATEGORY_REMOVE: TREATMENTCATEGORY_REMOVE,

    NURSE_FIND: NURSE_FIND,
    NURSE_CREATE: NURSE_CREATE,
    NURSE_COUNT: NURSE_COUNT,
    NURSE_UPDATE: NURSE_UPDATE,

    HLSVIDEO_FIND: HLSVIDEO_FIND,
    HLSVIDEO_CREATE: HLSVIDEO_CREATE,
    HLSVIDEO_UPDATE: HLSVIDEO_UPDATE,
    HLSVIDEO_COUNT: HLSVIDEO_COUNT,

    VIDEOCONTENTGLOBALSETTING_FIND: VIDEOCONTENTGLOBALSETTING_FIND,
    VIDEOCONTENTGLOBALSETTING_CREATE: VIDEOCONTENTGLOBALSETTING_CREATE,
    VIDEOCONTENTGLOBALSETTING_UPDATE: VIDEOCONTENTGLOBALSETTING_UPDATE,

    VCONTENT_FIND: VCONTENT_FIND,
    VCONTENT_CREATE: VCONTENT_CREATE,
    VCONTENT_UPDATE: VCONTENT_UPDATE,
    VCONTENT_REMOVE: VCONTENT_REMOVE,
    VCONTENT_COUNT: VCONTENT_COUNT,

    VIDEOCONTENTHOSPITAL_FIND: VIDEOCONTENTHOSPITAL_FIND,
    VIDEOCONTENTHOSPITAL_CREATE: VIDEOCONTENTHOSPITAL_CREATE,
    VIDEOCONTENTHOSPITAL_UPDATE: VIDEOCONTENTHOSPITAL_UPDATE,
    VIDEOCONTENTHOSPITAL_COPY_BY_HOSPITAL_CATEGORY: VIDEOCONTENTHOSPITAL_COPY_BY_HOSPITAL_CATEGORY,
    VIDEOCONTENTHOSPITAL_COPY_BY_GLOBAL_CATEGORY: VIDEOCONTENTHOSPITAL_COPY_BY_GLOBAL_CATEGORY,
    VIDEOCONTENTHOSPITAL_COPY_ALL_VCH: VIDEOCONTENTHOSPITAL_COPY_ALL_VCH,

    VIDEOCONTENTCATEGORY_FIND: VIDEOCONTENTCATEGORY_FIND,
    VIDEOCONTENTCATEGORY_FINDONE: VIDEOCONTENTCATEGORY_FINDONE,
    VIDEOCONTENTCATEGORY_FINDTOP: VIDEOCONTENTCATEGORY_FINDTOP,
    VIDEOCONTENTCATEGORY_FINDLAST: VIDEOCONTENTCATEGORY_FINDLAST,
    VIDEOCONTENTCATEGORY_FINDCONDITIONING: VIDEOCONTENTCATEGORY_FINDCONDITIONING,
    VIDEOCONTENTCATEGORY_CREATE: VIDEOCONTENTCATEGORY_CREATE,
    VIDEOCONTENTCATEGORY_UPDATE: VIDEOCONTENTCATEGORY_UPDATE,
    VIDEOCONTENTCATEGORY_UPDATE_HIDDEN: VIDEOCONTENTCATEGORY_UPDATE_HIDDEN,
    VIDEOCONTENTCATEGORY_UPDATE_TYPENAME: VIDEOCONTENTCATEGORY_UPDATE_TYPENAME,

    HEALTHCONTENTPUSH_FIND: HEALTHCONTENTPUSH_FIND,
    HEALTHCONTENTPUSH_CREATE: HEALTHCONTENTPUSH_CREATE,
    HEALTHCONTENTPUSH_REMOVE: HEALTHCONTENTPUSH_REMOVE,

    HCONTENT_FIND: HCONTENT_FIND,
    HCONTENT_CREATE: HCONTENT_CREATE,
    HCONTENT_REMOVE: HCONTENT_REMOVE,
    HCONTENT_UPDATE: HCONTENT_UPDATE,

    HEALTHCONTENTCATEGORY_FIND: HEALTHCONTENTCATEGORY_FIND,

    HOCONTENT_FIND: HOCONTENT_FIND,
    HOCONTENT_CREATE: HOCONTENT_CREATE,
    HOCONTENT_UPDATE: HOCONTENT_UPDATE,
    HOCONTENT_REMOVE: HOCONTENT_REMOVE,

    HOSPITALCONTENTCATEGORY_FIND_ONE: HOSPITALCONTENTCATEGORY_FIND_ONE,

    APPVERSION_FIND: APPVERSION_FIND,
    APPVERSION_UPDATE: APPVERSION_UPDATE,

    NOTIFICATION_SOUND_COUNT: NOTIFICATION_SOUND_COUNT,
    NOTIFICATION_SOUND_FIND: NOTIFICATION_SOUND_FIND,
    NOTIFICATION_SOUND_CREATE: NOTIFICATION_SOUND_CREATE,
    NOTIFICATION_SOUND_UPDATE: NOTIFICATION_SOUND_UPDATE,

    SD_FIND_LABELS: SD_FIND_LABELS, 
    AL_HOSPITAL_FIND: AL_HOSPITAL_FIND,
    AL_HOSPITAL_CREATE: AL_HOSPITAL_CREATE,
    AL_HOSPITAL_UPDATE: AL_HOSPITAL_UPDATE,
    AL_PATIENT_REQUEST: AL_PATIENT_REQUEST,
    AL_PATIENT_REQUEST_COUNT: AL_PATIENT_REQUEST_COUNT,
    AL_INTEGRATION_SETTING: AL_INTEGRATION_SETTING,
    AL_INTEGRATION_SETTING_CREATE: AL_INTEGRATION_SETTING_CREATE, 
    AL_INTEGRATION_SETTING_UPDATE: AL_INTEGRATION_SETTING_UPDATE,

    ANSWERING_CHAT_SETTING_FIND: ANSWERING_CHAT_SETTING_FIND,
    ANSWERING_CHAT_SETTING_CREATE: ANSWERING_CHAT_SETTING_CREATE,
    ANSWERING_CHAT_SETTING_UPDATE: ANSWERING_CHAT_SETTING_UPDATE,

    ANSWERING_CHAT_FIND: ANSWERING_CHAT_FIND,
    ANSWERING_CHAT_COUNT: ANSWERING_CHAT_COUNT,

    EVENT_FIND: EVENT_FIND,
    EVENT_FIND_ONE: EVENT_FIND_ONE,
    EVENT_STATS_FIND : EVENT_STATS_FIND,
    EVENT_STATS_COUNT: EVENT_STATS_COUNT,
    EVENT_PUSH_FIND: EVENT_PUSH_FIND,
    EVENT_PUSH_COUNT: EVENT_PUSH_COUNT,

    RESERVED_SMS_FIND: RESERVED_SMS_FIND,
    RESERVED_SMS_COUNT: RESERVED_SMS_COUNT,

    CA_RESERVED_CHAT_FIND: CA_RESERVED_CHAT_FIND,
    CA_RESERVED_CHAT_COUNT: CA_RESERVED_CHAT_COUNT,
    CA_RESERVED_CHAT_CREATE: CA_RESERVED_CHAT_CREATE,
    CA_RESERVED_CHAT_CREATE_MANY: CA_RESERVED_CHAT_CREATE_MANY,

    EL_HOSPITAL_FIND: EL_HOSPITAL_FIND,
    EL_USER_FIND_ONE: EL_USER_FIND_ONE
}
