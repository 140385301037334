import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const count = (query) => {
    return axios({
        method: 'GET',
        url: URL.AUTOMATION_COUNT,
        params: query,
        headers: Util.getHeaders()
    })
}

export const find = (query) => {
    return axios({
        method: 'GET',
        url: URL.AUTOMATION,
        params: query,
        headers: Util.getHeaders()
    })
}

export const create = (data) => {
    return axios({
        method: 'POST',
        url: URL.AUTOMATION,
        data: data,
        headers: Util.getHeaders()
    })
}

export const update = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.AUTOMATION,
        data: data,
        headers: Util.getHeaders()
    })
}

export const remove = (data) => {
    return axios({
        method: 'DELETE',
        url: URL.AUTOMATION,
        params: data,
        headers: Util.getHeaders()
    })
}