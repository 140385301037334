<template>
    <div class="v2_content_wrap">
        <!-- 추가, 수정 버튼 클릭 시 나타나는 팝업 -->
        <TreatmentTagManualEditPopup
            :isShow="isShowEditPopup"
            :template="selectedTreatmentCategory"
            @close="closeEditPopup"
            @reloadData="reloadData"
        />        

        <div class="page_title">
            <span>치료태그 매뉴얼</span>
        </div>
        
        <div class="h_scroll">
            <div class="table_title mgb_20">
                <p>
                    총 <span class="black_txt">{{ totalCount }}</span>개
                </p>
                <div class="table_title_btn">
                    <select name="타입" id="" class="input_s" v-model="keyType">
                        <option value="keyword">태그명</option>
                        <option value="descriptionKeyword">메모</option>
                    </select>
                    <input type="text" class="input_s" placeholder="검색어 입력"  v-model="keyword" @keydown.enter="action_Search"/>
                    <button class="btn_s btn_blue_line mgl_5" @click="action_Search">검색</button>
                    <a class="btn btn_s btn_blue" @click="action_Create" :disabled="!auth.treatmentCategory_w">추가</a>
                </div>
            </div>
            <div class="table_header_fix">
                <!-- 검색 데이터 없을 때 -->
                <table class="table_default" v-if="isEmpty">
                    <thead>
                        <tr>
                            <th width="300" class="txt_left">태그명</th>
                            <th width="" class="txt_left">메모</th>
                            <th width="150" class="txt_left">최종 수정일시</th>
                            <th width="150">삭제/수정</th>
                        </tr>
                    </thead>
                    <tr>
                        <td colspan="4">검색된 내용이 없습니다.</td>
                    </tr>
                </table>
                <!-- hospital data table -->
                <table class="table_default" v-if="!isEmpty">
                    <thead>
                        <tr>
                            <th width="300" class="txt_left">태그명</th>
                            <th width="" class="txt_left">메모</th>
                            <th width="150" class="txt_left">최종 수정일시</th>
                            <th width="150">삭제/수정</th>
                        </tr>
                    </thead>
                    <TreatmentCategoryItem
                        v-for="(item, index) in list"
                        :key="index"
                        :item="item"
                        @reloadData="reloadData"
                        @showEditPopup="showEditPopup"
                    />
                </table>
            </div>
            <Pagenation
                v-bind:totalCount="totalCount"
                v-bind:currentPage="pageIndex"
                v-bind:countPerPage="countPerPage"
                v-bind:countPerRow="5"
                v-on:changePage="loadTreatmentCategory"
            />
        </div>
    </div>
</template>


<script>
import PageController from '../../../../../../controller/PageController'

import Pagenation from '../../../../ShareComponents/PagenationComponent.vue'
import TreatmentCategoryItem from './TreatmentTagManulItem.vue'
import TreatmentTagManualEditPopup from './TreatmentTagManualEditPopup.vue'

export default {
    components: { Pagenation, TreatmentCategoryItem, TreatmentTagManualEditPopup },
    data() {
        return {
            isShowEditPopup: false,
            selectedTreatmentCategory: undefined,
            keyType: 'keyword',
            keyword: '',

            totalCount: 0,
            countPerPage: 20,
            pageIndex: 0,

            list: [],
            pageController: new PageController()
        }
    },
    computed: {
        isEmpty: function() {
            return (this.list?.length ?? 0) == 0
        },
        filter: function() {
            let model = {
                isCommon: true,
                isRemoved: false,
            }
            if (this.keyword != undefined || this.keyword != '' || this.descriptionKeyword != undefined || this.descriptionKeyword != '') {
                if (this.keyType == 'keyword') { model.keyword = this.keyword }
                else { model.descriptionKeyword = this.keyword }
            }
            return model
        }
    },
    mounted() {
        this.loadTreatmentCategory(0)
    },
    methods: {
        // MARK: - Network
        async loadTreatmentCategoryCount() {
            let result = await this.API_V3.TreatmentCategory.count(this.filter)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.totalCount = result.data.data
            this.pageController.totalCount = this.totalCount
        },
        async loadTreatmentCategory(pageIndex) {
            await this.loadTreatmentCategoryCount()
            this.pageController.api = this.API_V3.TreatmentCategory.find
            this.pageController.filter = this.filter
            this.pageController.countPerPage = this.countPerPage
            await this.pageController.loadPageIndex(pageIndex)
            this.pageIndex = pageIndex
            this.list = this.pageController.data
        },

        // MARK: - Actions
        action_Search() {
            this.loadTreatmentCategory(this.pageIndex)
        },

        action_Create() {
            this.isShowEditPopup = true
        },

        // MARK: - Emit
        showEditPopup(category) {
            console.log(category)
            this.selectedTreatmentCategory = category
            this.isShowEditPopup = true
        },
        closeEditPopup() {
            this.isShowEditPopup = false
            this.selectedTreatmentCategory = undefined
        },
        reloadData() {
            this.loadTreatmentCategory(0)
        }
    }
}
</script>