<template>
    <div v-if="isShow == true">
        <div class="toast_error" v-if="noticeMessage == errorMessage">
            {{ noticeMessage }}
        </div>
        <div class="toast_error" v-if="noticeMessage == duplicateMessage">
            {{ noticeMessage }}
        </div>
        <div class="toast_success" v-if="noticeMessage == successMessage">
            {{ noticeMessage }}
        </div>
        <div class="toast_success" v-if="isTimedShow">
            {{ timedMessage }}
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            errorMessage: '이름이 입력되지 않았습니다.',
            duplicateMessage: '이미 등록된 치료 유형입니다.',
            successMessage: '저장되었습니다.',

            isTimedShow: false,
        }
    },

    props: {
        isShow: Boolean,
        noticeMessage: String,

        timedMessage: String
    },
    watch: {
        timedMessage: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                if (newValue != undefined && newValue != "") {
                    this.isTimedShow = true
                    setTimeout(() => {
                        this.isTimedShow = false
                    }, 5000);
                }
            }
        }
    },
}
</script>
