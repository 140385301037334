<template>
    <div class="item" @click="action_ClickItem">
        <span class="color_circle" :style="styleString"></span>
        <span class="txt">{{ title }}</span>
    </div>
</template>


<script>
export default {
    props: {
        item: Object
    },
    computed: {
        title: function() {
            return this.item?.name ?? '-'
        },
        color: function() {
            return this.item?.color ?? '#FFFFFF'
        },
        styleString: function() {
            return 'background-color: ' + this.color + ';'
        }
    },
    methods: {
        action_ClickItem() {
            this.$emit('selectItem', this.item)
        }
    }
}
</script>