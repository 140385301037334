<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <LoadingIndicate
            :isLoading="isLoading"
        />
        <div class="alrimtalk_template_pop_wrap">
            <div class="pop_title">
                <span class="title">알림톡 선택</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon :size="24">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="h_scroll">
                    <div class="flex_aic gap_15 mgb_10">
                        <div >
                            <select class="input_s" v-model="profileType">
                                <option value="G">그룹</option>
                                <option value="S" v-if="!isOnlyGroups">발신프로필</option>
                            </select>
                            <input type="text" :value="selectedSenderKeyTitle" disabled class="input_s">
                            <button class="btn_s btn_blue_line" v-if="profileType=='G'" @click="action_ShowGroupPopup">선택</button>
                            <button class="btn_s btn_blue_line" v-if="profileType=='S'" @click="action_ShowProfilePopup">선택</button>
                        </div>
                        <div class="flex" style="flex-grow: 1;">
                            <select class="input_s" v-model="templateStatus">
                                <option :value="undefined">검수상태(전체)</option>
                                <option
                                    v-for="(status, index) in statusKind" :key="index"
                                    :value="status.key"
                                >{{ status.name }}</option>
                            </select>
                            <input type="text" class="input_s" placeholder="템플릿명 검색" v-model="keyword" @keydown.enter="loadPageIndex(0)" style="flex-grow: 1;">
                            <button class="btn btn_s btn_blue_line" @click="loadPageIndex(0)">검색</button>
                        </div>
                    </div>
                    <div class="table_scroll_wrap table_header_fix">
                        <table class="table_default">
                            <thead>
                                <tr>
                                    <th width="">발신프로필/그룹</th>
                                    <th width="">템플릿명</th>
                                    <th width="">템플릿코드</th>
                                    <th width="80px">검수상태</th>
                                    <th width="140px">등록일시</th>
                                    <th width="140px">수정일시</th>
                                    <th width="80px">선택</th>
                                </tr>
                            </thead>
                            <KakaoTemplateListItem
                                v-for="(item, index) in templates" :key="index"
                                :item="item"
                                :selectedProfile="selectedProfile"
                                :groups="groups"
                                @selectTemplate="selectTemplate"
                            />
                        </table>
                    </div>
                    <PagenationComponent
                        v-bind:totalCount="totalCount"
                        v-bind:currentPage="currentPage"
                        v-bind:countPerPage="countPerPage"
                        v-bind:countPerRow="5"
                        v-on:changePage="loadPageIndex"
                    />
                </div>
            </div>
        </div>
        
        <AlrimtalkMessageProfilePopup style="z-index: 99999;"
            :isShowSelectGroupPopup="isShowGroupSelectPopup"
            :isShowSelectProfilePopup="isShowProfileSelectPopup"
            :selectableKakaoProfiles="selectableKakaoProfiles"
            @close="closePopups"
            @updateSelectedSenderKey="updateSelectedSenderKey"
        />
    </div>
</template>


<script>
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import AlrimtalkMessageProfilePopup from '../../AlrimtalkMessage/AlrimtalkMessageProfilePopup'
import KakaoTemplateListItem from './KakaoTemplateListItem.vue'
import PagenationComponent from '@/views/main/ShareComponents/PagenationComponent.vue'

export default {
    components: {
        LoadingIndicate,
        AlrimtalkMessageProfilePopup,
        KakaoTemplateListItem,
        PagenationComponent,
    },
    props: {
        isShow: Boolean,
        isOnlyGroups: {type: Boolean, default: () => true},
        selectableKakaoProfiles: {type: Array, default: () => [] },
    },
    data() {
        return {
            isLoading: false,
            isShowProfileSelectPopup: false,
            isShowGroupSelectPopup: false,
            
            profileType: 'G',
            groups: [],

            // for filter
            keyword: '',
            templateStatus: undefined,
            senderKey: undefined,

            templates: [],

            totalCount: 0,
            currentPage: 0,
            countPerPage: 20,
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { return }
                this.profileType = 'G'
                this.reloadData()
            }
        },
        profileType: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                this.senderKey = undefined
            }
        },
    },
    computed: {
        statusKind: function() {
            return [
                {name: '등록',      key: 'REG'},
                {name: '검수요청',  key: 'REQ'},
                {name: '반려',      key: 'REJ'},
                {name: '차단',      key: 'STP'},
                {name: '발송전',    key: 'RDY'},
                {name: '정상',      key: 'ACT'},
                {name: '휴면',      key: 'DMT'},
                {name: '차단',      key: 'BLK'},
            ]
        },
        selectedProfile: function() {
            if (this.senderKey?.senderKey != undefined) { return this.senderKey }
            return undefined
        },
        selectedSenderKeyTitle: function() {
            return this.senderKey?.name ?? '-'
        },
        selectedSenderKey: function() {
            if (this.senderKey?.groupKey != undefined) { return this.senderKey.groupKey }
            else if (this.senderKey?.senderKey != undefined) { return this.senderKey.senderKey }
            return undefined
        },
    },
    methods: {
        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },
        action_ShowGroupPopup() {
            this.isShowGroupSelectPopup = true
        },
        action_ShowProfilePopup() {
            this.isShowProfileSelectPopup = true
        },

        // MARK: Network
        async reloadData() {
            this.isLoading = true
            await this.loadGroups()
            await this.loadPageIndex(0)
            this.isLoading = false
        },
        async loadGroups() {
            let result = await this.API_V3.KakaoTemplate.findGroup()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.groups = result.data.data
            this.senderKey = result.data.data[0]
        },
        async loadPageIndex(index) {
            this.isLoading = true
            this.currentPage = index
            let model = {
                senderKey: this.selectedSenderKey == '' ? undefined : this.selectedSenderKey,
                senderKeyType: this.profileType == '' ? undefined : this.profileType,
                templateStatus: this.templateStatus == '' ? undefined : this.templateStatus,
                keyword: this.keyword == '' ? undefined : this.keyword,
                page: this.currentPage + 1,
                count: this.countPerPage
            }
            let result = await this.API_V3.KakaoTemplate.findTemplate(model)
            this.isLoading = false
            if (result.data.code == '508') {
                this.totalCount = 0
                this.templates = []
                return
            }
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let data = result.data.data
            this.totalCount = data.totalCount
            this.templates = data.list
        },
        async loadDetail(template) {
            this.isLoading = true
            let model = {
                senderKeyType: template.senderKeyType,
                senderKey: template.senderKey,
                templateCode: template.templateCode
            }
            let result = await this.API_V3.KakaoTemplate.findDetail(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.isLoading = false
            return result.data.data
        },

        // MARK: - Emit
        closePopups() {
            this.isShowGroupSelectPopup = false
            this.isShowProfileSelectPopup = false
        },
        updateSelectedSenderKey(senderKey) {
            if (senderKey?.group != undefined) { this.senderKey = senderKey.group }
            else if (senderKey?.profile != undefined) { this.senderKey = senderKey.profile }
        },
        async selectTemplate(template) {
            let detail = await this.loadDetail(template) 
            detail.serviceStatus = template.serviceStatus
            this.$emit('select', detail)
        }
    }
}
</script>