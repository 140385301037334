<template>
    <div class="toast_background" v-if="isTimedShow">
        <div class="toast_wrap">
            <div class="toast_content">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
    },
    data() {
        return {
            isTimedShow: false
        }
    },
    watch: {
        message: {
            immediate: true,
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }
                if (newValue != undefined && newValue != "") {
                    this.isTimedShow = true
                    setTimeout(() => {
                        this.isTimedShow = false
                        this.$emit('clear', undefined)
                    }, 5000);
                }
            }
        }
    },
}
</script>


<style scoped>
/* 하다희 - 애니메이션 넣어주세요 */
.toast_background {
    position: fixed;
    z-index: 999;
    position: absolute;
}
.toast_wrap {
    display: table;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}
.toast_content {
    position: fixed;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    padding: 30px 40px;
    color: white !important;
    font: normal 18px "Material Design Icons";
    background: #1f8fe5;
}

</style>