<template>
    <ul v-if="isShow" class="settings_wrap flex_column" >
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                용도<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="용도" :value="false" v-model="isTest">실거래처
                </label>
                <label class="flex_aic">
                    <input type="radio" name="용도" :value="true" v-model="isTest">테스트
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                병원유형
                <div class="title_description">선택 시 유형에 따라 기본적으로 제공하는 채팅문구,가입문자 내용을 불러옵니다.</div>
            </div>
            <div class="settings_list_option flex_aic">
                <select name="병원유형" id="" v-model="selectedSetting">
                    <option :value="undefined" disabled>--선택--</option>
                    <option
                        v-for="(setting, index) in sampleSettings"
                        :key="index"
                        :value="setting._id"
                   >{{ setting.name }}</option>
                    <option value="직접설정">직접설정</option>
                </select>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                병원명<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <input type="text" placeholder="최대 30자" maxlength="30" v-model="shortName">
                <small class="red_txt" v-if="!isValidShortName">병원명을 입력해 주세요.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                건강관리 뉴스<small class="red_txt">*</small>
                <div class="title_description">건강관리 뉴스 사용여부 선택, 사용으로 선택 시 모바일 앱 홈화면에 노출될 뉴스 카테고리 선택</div>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="건강관리 뉴스" :value="false" v-model="usingHealthContent">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="건강관리 뉴스" :value="true" v-model="usingHealthContent">사용
                    </label>
                </div>
                <select name="뉴스" id="" v-model="healthContentCategory" :disabled="!usingHealthContent">
                    <option :value="[]" disabled>--선택--</option>
                    <option
                        v-for="(category, index) in sampleHCCategory"
                        :key="index"
                        :value="[category._id]"
                       >{{ category.name }}</option>
                </select>
                <small class="red_txt" v-if="!isValidHealthContentcategory">앱 메인에 노출할 뉴스 카테고리를 선택해 주세요.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                병원소식 사용 여부<small class="red_txt">*</small>
                <div class="title_description">병원소식(블로그) 사용 여부</div>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="병원소식" :value="false" v-model="usingHospitalContent">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="병원소식" :value="true" v-model="usingHospitalContent">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                담당자
                <div class="title_description">담당자 변경을 원할 시, 프로덕트팀에 요청해 주세요.</div>
            </div>
            <div class="settings_list_option flex_aic">
                <select name="담당자" id="" v-model="nurseID._id" :disabled="hospitalID != undefined">
                    <option :value="undefined">담당자 없음</option>
                    <option 
                        v-for="(nurse, index) in sampleNurses"
                        :key="index"
                        :value="nurse._id"
                   >{{nurse.name}}({{nurse.id}})</option>
                </select>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                가입문자 로고 발송
                <div class="title_description">
                    가입문자 발송 시 같이 전송되는 이미지 파일
                </div>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="가입문자 로고" :value="false" v-model="isUsingLogo">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="가입문자 로고" :value="true" v-model="isUsingLogo">사용
                    </label>
                </div>
                <div>
                    <button class="input_s btn_s" @click="action_ShowFilePicker">파일선택</button>
                    <FilePicker v-model="selectFile" acceptExt="image/JPEG, image/jpg" v-bind:is-multiple="false" v-bind:is-open="isShowFilePicker"/>
                </div>
                <small class="img_preview_wrap lgray_txt" style="width: fit-content;">현재 저장된 File ID: {{ logoImageID }}
                    <div class="img_preview">
                        <img
                            v-if="logoImageURL"
                            :src="logoImageURL"
                            alt=""
                        />
                    </div>
                </small>
                <small class="red_txt" v-if="!isValidLogo">파일을 업로드해주세요.</small>
            </div>
        </li>
        <BasicOptionsInitialChatList
            v-model="initialChats"
        />
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                고객등록 가입문자<small class="red_txt">*</small>
                <div class="title_description">고객 휴대폰으로 발송되는 가입 문자 내용
                <br>
                <br>
                [치환문자]<br>
                <span v-pre>{{병원명}} 1회 이상</span><br>
                <span v-pre>{{고객명 1회}}</span><br>
                <span class="red_txt" v-pre>{{링크}} 1회</span><br>
                </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="smsBasicMessage"></textarea>
                <small class="red_txt" v-if="!isValidDefaultSmsBasicMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValidDefaultSmsBasicMessageLink">{{ linkErrorMessage }}</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                예약 가입문자<small class="red_txt">*</small>
                <div class="title_description">예약에서 환자 등록시 발송되는 가입 문자 내용
                <br>
                <br>
                [치환문자]<br>
                <span v-pre>{{병원명}} 1회 이상</span><br>
                <span v-pre>{{고객명}} 1회 이상</span><br>
                <span class="red_txt" v-pre>{{링크}} 1회</span>
            </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="smsReservation"></textarea>
                <small class="red_txt" v-if="!isValidSMSReservation">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValidSMSReservationLink">{{ linkErrorMessage }}</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                재발송 가입문자<small class="red_txt">*</small>
                <div class="title_description">
                    이미 등록된 환자에게 문자 재발송 버튼을 클릭했을 때 발송되는 가입 문자 내용
                    <br>
                    <br>
                    [치환문자]<br>
                    <span v-pre>{{병원명}} 1회</span><br>
                    <span v-pre>{{고객명}} 1회</span><br>
                    <span class="red_txt"><span v-pre>{{링크}} 1회</span></span><br>
                </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="smsResendMessage"></textarea>
                <small class="red_txt" v-if="!isValidSMSResendMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValidSMSResendMessageLink">{{ linkErrorMessage }}</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                가입문자 자동 재발송<small class="red_txt">*</small>
                <div class="title_description">
                    환자 등록 후 미가입 상태인 경우 설정된 일시에 해당 내용으로 가입 문자를 자동 재발송함 (등록 당일: 0일)
                    <br>
                    <br>
                    [치환문자]<br>
                    <span v-pre>{{병원명}} 1회</span><br>
                    <span v-pre>{{고객명}} 1회</span><br>
                    <span class="red_txt"><span v-pre>{{링크}} 1회</span></span><br></div>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="가입문자 자동 재발송" :value="false" v-model="usingAutoReservedSMS">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="가입문자 자동 재발송" :value="true" v-model="usingAutoReservedSMS">사용
                    </label>
                </div>
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" :disabled="!usingAutoReservedSMS" v-model="autoReservedSMSMessage"></textarea>
                <small class="red_txt" v-if="!isValidAutoReservedSMSMessage">내용을 입력해 주세요.</small>
                <small class="red_txt" v-if="!isValidAutoReservedSMSMessageLink">{{ linkErrorMessage }}</small>
                <div class="flex_aic gap_5">등록일로부터
                    <select name="day" id="" :disabled="!usingAutoReservedSMS" v-model="autoReservedSMSDelayDay" style="width: 60px;">
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                    </select>
                    일 후
                    <select class="mg_0" name="time" id="" :disabled="!usingAutoReservedSMS" v-model="autoReservedSMSHour" style="width: 60px;">
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="21">21</option>
                        <option value="22">22</option>
                        <option value="23">23</option>
                    </select>
                    시
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                문자 발신 전화번호<small class="red_txt">*</small>
                <div class="title_description">병원 문자 발신 번호 등록/변경 시, 프로덕트팀에게 요청해 주세요.</div>
            </div>
            <div class="settings_list_option flex_aic">
                <input type="text" placeholder="예)07051804070" disabled :value="smsPhone">
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                홈케어 콘텐츠<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_10">
                    <label class="flex_aic">
                        <input type="radio" name="홈케어 콘텐츠" :value="false" v-model="usingVideoContent">사용안함
                    </label>
                    <label class="flex_aic">
                        <input type="radio" name="홈케어 콘텐츠" :value="true" v-model="usingVideoContent">사용
                    </label>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                홈케어 콘텐츠 전송 시 채팅<small class="red_txt">*</small>
                <div class="title_description">
                    홈케어 콘텐츠 발송 시, 채팅방 내에 전송되는 채팅 내용입니다.
                    <br>
                    <br>
                    [치환문자]<br>
                    <span v-pre>{{병원명}} 없음</span><br>
                    <span v-pre>{{고객명}} 1회</span><br>
                    <span v-pre>{{링크}} 없음</span><br>
                </div>
            </div>
            <div class="settings_list_option flex_column">
                <textarea class="w_full" rows="10" name="" id="" placeholder="내용 입력" v-model="defaultVCMessage" :disabled="!usingVideoContent"></textarea>
                <small class="red_txt" v-if="!isValidDefaultVCMessage">내용을 입력해 주세요.</small>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                이벤트/홈케어 상품<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="이벤트/홈케어 상품" :value="false" v-model="usingHospitalEvent">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="이벤트/홈케어 상품" :value="true" v-model="usingHospitalEvent">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                번역<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="번역" :value="false" v-model="usingChatTranslate">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="번역" :value="true" v-model="usingChatTranslate">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                예약시트<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_aic">
                <label class="flex_aic">
                    <input type="radio" name="예약시트" :value="false" v-model="usingSaaSReservation">사용안함
                </label>
                <label class="flex_aic">
                    <input type="radio" name="예약시트" :value="true" v-model="usingSaaSReservation">사용
                </label>
            </div>
        </li>
        <li class="settings_list flex_aic_fle">
            <button class="btn_l btn_blue" @click="action_Save" :disabled="!auth.v2HospitalManagement_w">저장</button>
        </li>
    </ul>
</template>


<script>
import FilePicker from '../../../../../../ShareComponents/FilePicker.vue'
import { FileUploadController } from './../../../../../../../../controller/FileUploadController'
import BasicOptionsInitialChatList from './BasicOptionsInitialChatList.vue'
const moment = require('../../../../../../../../util/MPMoment')

export default {
    components: {
        BasicOptionsInitialChatList,
        FilePicker,
    },
    props: {
        isShow: Boolean,
        hospital: Object,
    },
    data() {
        return {
            linkErrorMessage: '{{링크}}는 필수값 입니다.',
            isLoading: false,

            selectFile: undefined,
            isShowFilePicker: false,

            sampleSettings: [],
            selectedSetting: undefined,

            serviceSetting: undefined,
            sampleHCCategory: [],
            sampleNurses: [],

            isTest: false,
            shortName: '',
            usingHealthContent: false,
            healthContentCategory: undefined,
            usingHospitalContent: false,
            nurseID: {},
            isUsingLogo: false,
            smsLogoImage: undefined,
            initialChats: [],
            smsBasicMessage: '',
            smsReservation: '',
            smsResendMessage: '',
            usingAutoReservedSMS: false,
            autoReservedSMSMessage: '',
            autoReservedSMSDelayDay: 0,
            autoReservedSMSHour: 20,
            smsPhone: undefined,
            usingVideoContent: false,
            usingHospitalEvent: false,

            defaultVCMessage: '',
            usingChatTranslate: false,

            usingSaaSReservation: true,
        }
    },
    watch: {
        hospital: {
            immediate: true,
            handler: function(newValue) {
                // TODO: - load from network
                this.loadInitialInformation()

                this.isTest = newValue.isTest ?? false
                this.shortName = newValue.shortName ?? ''
                this.usingHealthContent = newValue.usingHealthContent ?? false
                this.healthContentCategory = newValue.healthContentCategory ?? undefined
                this.usingHospitalContent = newValue.usingHospitalContent ?? false
                this.nurseID = newValue.nurseID ?? {}
                this.isUsingLogo = newValue.isUsingLogo ?? false
                this.smsLogoImage = newValue.smsLogoImage ?? undefined
                this.initialChats = newValue.initialChats ?? []
                this.smsBasicMessage = newValue.smsBasicMessage ?? ''
                this.smsReservation = newValue.smsReservation ?? ''
                this.smsResendMessage = newValue.smsResendMessage ?? ''
                this.usingAutoReservedSMS = newValue.usingAutoReservedSMS ?? false
                this.autoReservedSMSMessage = newValue.autoReservedSMSMessage ?? ''
                this.autoReservedSMSDelayDay = newValue.autoReservedSMSDelayDay ?? 0
                this.autoReservedSMSHour = newValue.autoReservedSMSHour ?? 20
                this.smsPhone = newValue.smsPhone
                this.usingVideoContent = newValue.usingVideoContent ?? false
                this.usingHospitalEvent = newValue.usingHospitalEvent ?? false
                this.usingSaaSReservation = newValue.usingSaaSReservation ?? true
            }
        },
        selectedSetting: {
            handler: function(newValue, oldValue) {
                if (newValue == oldValue) { return }

                let setting = this.sampleSettings.find( s => s._id == newValue)
                this.initialChats = setting?.initialChats
                this.smsBasicMessage = setting?.smsBasicMessage
                this.smsReservation = setting?.smsReservation
                this.smsResendMessage = setting?.smsResendMessage
                this.usingAutoReservedSMS = setting?.usingAutoReservedSMS
                this.autoReservedSMSMessage = setting?.autoReservedSMSMessage
                this.autoReservedSMSDelayDay = setting?.autoReservedSMSDelayDay
                this.autoReservedSMSHour = setting?.autoReservedSMSHour
            }
        },
        selectFile: {
            immediate: true,
            deep: true,
            handler: async function(newValue) {
                if (newValue == undefined) { return }
                await this.changeImageFile(newValue)
                this.isShowFilePicker = false
            }
        }
    },
    computed: {
        hospitalID: function() {
            return this.hospital?._id ?? undefined
        },
        logoImageID: function() {
            if (this.smsLogoImage == undefined || this.smsLogoImage._id == undefined) { return undefined }
            return this.smsLogoImage._id
        },
        logoImageURL: function() {
            if (this.smsLogoImage == undefined || this.smsLogoImage._id == undefined) { return undefined }
            return this.smsLogoImage.paths[0]
        },

        careSchedule: function() {
            let startedAt = moment(0).startOf('days').add(9, 'hours')
            let endedAt = moment(0).startOf('days').add(20, 'hours')
            let array = []
            for (let i=1 ; i<7 ; i++) {
                array.push({
                    dayIndex: i,
                    startedAt: startedAt,
                    endedAt: endedAt
                })
            }
            return array
        },

        // MARK: Validations
        isValidShortName: function() {
            if (this.shortName == undefined || this.shortName == '') { return false }
            return true
        },
        isValidHealthContentcategory: function() {
            if (this.usingHealthContent == true && this.healthContentCategory == undefined) { return false }
            return true
        },
        isValidLogo: function() {
            if (this.isUsingLogo == true && this.logoImageID == undefined) { return false }
            return true
        },
        isValidDefaultSmsBasicMessage: function() {
            if (this.smsBasicMessage == undefined || this.smsBasicMessage == '') { return false }
            return true
        },
        isValidDefaultSmsBasicMessageLink: function() {
            if (this.isValidDefaultSmsBasicMessage == true && !this.smsBasicMessage.includes('{{링크}}')) { return false }
            return true
        },
        isValidSMSReservation: function() {
            if (this.smsReservation == undefined || this.smsReservation == '') { return false }
            return true
        },
        isValidSMSReservationLink: function() {
            if (this.isValidSMSReservation == true && !this.smsReservation.includes('{{링크}}')) { return false }
            return true
        },
        isValidSMSResendMessage: function() {
            if (this.smsResendMessage == undefined || this.smsResendMessage == '') { return false }
            return true
        },
        isValidSMSResendMessageLink: function() {
            if (this.isValidSMSResendMessage == true && !this.smsResendMessage.includes('{{링크}}')) { return false }
            return true
        },
        isValidAutoReservedSMSMessage: function() {
            if (this.usingAutoReservedSMS == true) {
                if (this.autoReservedSMSMessage == undefined || this.autoReservedSMSMessage == '') { return false}
            }
            return true
        },
        isValidAutoReservedSMSMessageLink: function() {
            if (this.usingAutoReservedSMS == true) {
                if (this.isValidAutoReservedSMSMessage == true && !this.autoReservedSMSMessage.includes('{{링크}}')) { return false }
            }
            return true
        },
        isValidDefaultVCMessage: function() {
            if (this.usingVideoContent == true) {
                if (this.defaultVCMessage == undefined || this.defaultVCMessage == '') { return false }
            }
            return true
        },
        isValidChat: function() {
            let isValid = true
            for (let i=0 ; i<this.initialChats.length ; i++) {
                let chat = this.initialChats[i]
                if (chat.chatType == 100) {
                    if (chat.content == undefined || chat.content == '') { isValid = false }
                }
                else if (chat.chatType == 300) {
                    if (chat.fileIDs == undefined || chat.fileIDs.length == 0) { isValid = false}
                }
            }
            return isValid
        },
        isValid: function() {
            return this.isValidShortName
                   && this.isValidHealthContentcategory
                   && this.isValidLogo
                   && this.isValidDefaultSmsBasicMessage
                   && this.isValidDefaultSmsBasicMessageLink
                   && this.isValidSMSReservation
                   && this.isValidSMSReservationLink
                   && this.isValidSMSResendMessage
                   && this.isValidSMSResendMessageLink
                   && this.isValidAutoReservedSMSMessage
                   && this.isValidAutoReservedSMSMessageLink
                   && this.isValidDefaultVCMessage
                   && this.isValidChat
        }
    },
    methods: {
        // MARK: - Network
        async loadInitialInformation() {
            await this.loadServiceSetting()
            await this.loadSampleServiceSetting()
            await this.loadHealthContentCategory()
            await this.loadNurses()
        },
        async loadServiceSetting() {
            if (this.hospital?._id == undefined) { return }
            let filter = {
                hospitalID: this.hospital?._id
            }

            let result = await this.API_V3.ServiceSetting.findOne(filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.serviceSetting = result.data.data

            this.defaultVCMessage = this.serviceSetting?.defaultVCMessage ?? ''
            this.usingChatTranslate = this.serviceSetting?.usingChatTranslate ?? false
        },
        async loadSampleServiceSetting() {
            let filter = {
                isSample: true,
                isEnabledSaaS: true,
                isRemoved: false
            }

            let results = await this.API_V3.ServiceSetting.find(filter)
            if (results.data.code != 0) {
                console.log(results)
                return
            }
            this.sampleSettings = results.data.data
        },
        async loadHealthContentCategory() {
            let result = await this.API_V3.HealthContentCategory.find()
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.sampleHCCategory = result.data.data
        },
        async loadNurses() {
            let result = await this.API_V3.Nurse.findSimple()
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.sampleNurses = result.data.data
        },

        async createHospital(model) {
            let result = await this.API_V3.Hospital.create(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }

            // generate integration setting
            let hospitalID = result.data.data._id
            let integrationSettingModel = {
                hospitalID: hospitalID,
                integrationType: 'ALM',
                customObject : {
                    defaultLabelID : [],
                    defaultCancelID : []
                },
                usingRegist: false,
                usingReservation: false,
                usingDashboard: false,
                isRemoved: true,
            }
            await this.API_V3.IntegrationSetting.upsert(integrationSettingModel)

            return result.data.data
        },
        async createServiceSetting(model) {
            let result = await this.API_V3.ServiceSetting.create(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            return result.data.data
        },
        async createAnsweringChatSetting(hospitalID) {
            let result = await this.API_V3.AnsweringChatSetting.create({hospitalID: hospitalID})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            return result.data.data
        },
        async updateServiceSetting(model) {
            let result = await this.API_V3.ServiceSetting.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            return result.data.data
        },
        async updateHospital(model) {
            let result = await this.API_V3.Hospital.update(model)
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            return result.data.data
        },

        // MARK: - Actions
        action_ShowFilePicker() {
            this.isShowFilePicker = true
        },
        async action_Save() {
            if (this.isValid == false) { return }
            let hospitalModel = {
                isEnabledSaaS: true,
                isTest: this.isTest,
                nurseID: this.nurseID._id,
                name: this.shortName,
                shortName: this.shortName,
                usingHealthContent: this.usingHealthContent,
                healthContentCategory: this.healthContentCategory,
                usingHospitalContent: this.usingHospitalContent,
                isUsingLogo: this.isUsingLogo,
                smsLogoImage: this.smsLogoImage,
                initialChats: this.initialChats,
                smsBasicMessage: this.smsBasicMessage,
                smsReservation: this.smsReservation,
                smsResendMessage: this.smsResendMessage,
                usingAutoReservedSMS: this.usingAutoReservedSMS,
                autoReservedSMSMessage: this.autoReservedSMSMessage,
                autoReservedSMSDelayDay: this.autoReservedSMSDelayDay,
                autoReservedSMSHour: this.autoReservedSMSHour,
                usingVideoContent: this.usingVideoContent,
                usingHospitalEvent: this.usingHospitalEvent,
                careSchedule: this.careSchedule,
                usingSaaSReservation: this.usingSaaSReservation
            }
            console.log('careSchedule : ', this.careSchedule)
            let serviceSettingModel = {
                defaultVCMessage: this.defaultVCMessage,
                usingChatTranslate: this.usingChatTranslate,
            }
            if (this.hospitalID == undefined) {
                this.isLoading = true
                let h = await this.createHospital(hospitalModel)
                serviceSettingModel.hospitalID = h._id
                await this.createServiceSetting(serviceSettingModel)
                await this.createAnsweringChatSetting(h._id)
                this.$emit('updateHospitalID', h._id)
                this.isLoading = false
            }
            else {
                hospitalModel._id = this.hospitalID
                serviceSettingModel._id = this.serviceSetting._id

                this.isLoading = true
                await this.updateServiceSetting(serviceSettingModel)
                await this.updateHospital(hospitalModel)
                this.isLoading = false
            }
            this.$emit('reloadHospital')
        },

        // MARK: - File Upload
        async changeImageFile(file) {
            if (file == undefined || file.length == 0) {
                return
            }
            // check
            const fileSizeInBytes = file.size;
            const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2);
            if (fileSizeInKB > 300) {
                alert('첨부 가능한 파일 크기를 초과하였습니다. (300kb)')
                return
            }
            let fileName = file.name
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1)
            if (fileExtension != 'JPEG' && fileExtension != 'jpg') {
                alert('첨부 가능한 파일 확장자는 "jpg", "JPEG" 두가지 입니다.')
                return
            }

            this.isUploading = true
            let fileModel = await this.uploadFile(file)
            this.smsLogoImage = fileModel
            this.isUploading = false
        },
        async uploadFile(file) {
            return new Promise(function (resolve, reject) {
                let files = [file]
                FileUploadController.uploadFiles(files, [], async (results) => {
                    let datas = results.data
                    if (datas.length == 0) {
                        reject()
                        return
                    }

                    let result = datas[0]
                    if (result.data.code != 0) {
                        reject()
                        return
                    }
                    resolve(result.data.data)
                })
            })
        },
    }
}
</script>