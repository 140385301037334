<template>
    <div class="editor_wrap">
        <editor-menu-bar v-slot="{ commands, isActive }" :editor="editor">
            <div class="menubar">
                <span v-for="buttonAction in menuButtons" :key="buttonAction">
                    <button v-if="buttonAction == 'undo'" @click="commands.undo">
                        <v-icon>mdi-undo</v-icon>
                    </button>
                    <button v-if="buttonAction === 'redo'" @click="commands.redo" class="menubar__button">
                        <v-icon>mdi-redo</v-icon>
                    </button>
                    <button v-if="buttonAction == 'bold'" class="menubar__button" @click="commands.bold" :class="{ 'is-active': isActive.bold() }">
                        <v-icon>mdi-format-bold</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'underline'"
                        @click="commands.underline"
                        class="menubar__button"
                        :class="{ 'is-active': isActive.underline() }"
                    >
                        <v-icon>mdi-format-underline</v-icon>
                    </button>
                    <button v-if="buttonAction === 'strike'" @click="commands.strike" class="menubar__button" :class="{ 'is-active': isActive.strike() }">
                        <v-icon>mdi-format-strikethrough</v-icon>
                    </button>
                    <button v-if="buttonAction === 'italic'" @click="commands.italic" class="menubar__button" :class="{ 'is-active': isActive.italic() }">
                        <v-icon>mdi-format-italic</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'bullet_list'"
                        @click="commands.bullet_list"
                        class="menubar__button"
                        :class="{ 'is-active': isActive.bullet_list() }"
                    >
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'ordered_list'"
                        @click="commands.ordered_list"
                        class="menubar__button"
                        :class="{ 'is-active': isActive.ordered_list() }"
                    >
                        <v-icon>mdi-format-list-numbered</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'h1'"
                        class="menubar__button"
                        @click="commands.heading({ level: 1 })"
                        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                    >
                        <v-icon>mdi-format-header-1</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'h2'"
                        class="menubar__button"
                        @click="commands.heading({ level: 2 })"
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                    >
                        <v-icon>mdi-format-header-2</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'h3'"
                        class="menubar__button"
                        @click="commands.heading({ level: 3 })"
                        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                    >
                        <v-icon>mdi-format-header-3</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'blockquote'"
                        class="menubar__button"
                        @click="commands.blockquote"
                        :class="{ 'is-active': isActive.blockquote() }"
                    >
                        <v-icon>mdi-format-quote-close</v-icon>
                    </button>
                    <button
                        v-if="buttonAction === 'horizontal_rule'"
                        class="menubar__button"
                        @click="commands.horizontal_rule"
                        :class="{ 'is-active': isActive.horizontal_rule() }"
                    >
                        <v-icon>mdi-minus</v-icon>
                    </button>
                    <button v-if="buttonAction === 'code'" class="menubar__button" @click="changedCodeEditor()" :class="{ 'is-active': isCodeEditView }">
                        <v-icon>mdi-xml</v-icon>
                    </button>
                </span>
            </div>
        </editor-menu-bar>
        <v-row v-show="!isCodeEditView">
            <v-col>
                <div @click="moveEditorFocus()"><editor-content :editor="editor" /></div>
            </v-col>
        </v-row>
        <v-row v-show="isCodeEditView">
            <v-col
                ><div @click="moveEditorFocus()"><textarea v-model="content_TEXTAREA" /></div
            ></v-col>
        </v-row>
    </div>
</template>

<script>
import { Editor, EditorMenuBar, EditorContent } from 'tiptap'
import {
    Blockquote,
    // CodeBlock,
    HardBreak,
    Heading,
    HorizontalRule,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline,
    History,
    Code
} from 'tiptap-extensions'

export default {
    components: { EditorMenuBar, EditorContent },
    props: {
        value: { type: String },
        message: String,
    },
    data() {
        return {
            editor: undefined,
            isCodeEditView: false,

            content_HTML: undefined,
            content_TEXTAREA: undefined,

            menuButtons: [
                'undo',
                'redo',
                'bold',
                'underline',
                'strike',
                'italic',
                'bullet_list',
                'ordered_list',
                'h1',
                'h2',
                'h3',
                'blockquote',
                'horizontal_rule',
                // 'code_block', //이거 미사용인데 공백만 차지해서 주석처리함
                'code'
            ]
        }
    },
    beforeDestroy() {
        this.editor.destroy()
    },

    mounted() {
        this.editor = new Editor({
            extensions: [
                new Blockquote(),
                new BulletList(),
                // new CodeBlock(),
                new HardBreak(),
                new Heading({ levels: [1, 2, 3] }),
                new HorizontalRule(),
                new ListItem(),
                new OrderedList(),
                new TodoItem(),
                new TodoList(),
                new Link(),
                new Bold(),
                new Code(),
                new Italic(),
                new Strike(),
                new Underline(),
                new History()
            ],
            autofocus: false,
            content: this.value
        })

        this.content_HTML = this.value
        this.editor.on('update', () => {
            this.content_HTML = this.editor.getHTML()
            this.$emit('input', this.content_HTML)
        })
    },
    watch: {
        isCodeEditView: {
            immediate: true,
            handler: function (newValue) {
                if (newValue == true) {
                    this.content_TEXTAREA = this.content_HTML
                } else if (newValue == false) {
                    this.content_HTML = this.content_TEXTAREA
                }
            }
        },
        content_TEXTAREA: {
            deep: true,
            handler: function (newValue) {
                console.log('content_TextAREA : ', newValue)
                this.editor.setContent(newValue)    
                this.$emit('input', newValue)
            }
        }
    },
    methods: {
        moveEditorFocus() {
            this.editor.focus()
        },
        changedCodeEditor() {
            this.isCodeEditView = !this.isCodeEditView
            this.editor.focus()
        }
    }
}
</script>

<style>
</style>
