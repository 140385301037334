import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const count = (query) => {
    return axios({
        method: 'GET',
        url: URL.KAKAOLINKAGE_COUNT,
        params: query,
        headers: Util.getHeaders()
    })
}

export const find = (query) => {
    return axios({
        method: 'GET',
        url: URL.KAKAOLINKAGE,
        params: query,
        headers: Util.getHeaders()
    })
}

export const findOne = (query) => {
    return axios({
        method: 'GET',
        url: URL.KAKAOLINKAGE_ONE,
        params: query,
        headers: Util.getHeaders()
    })
}

export const detail = (query) => {
    return axios({
        method: 'GET',
        url: URL.KAKAOLINKAGE_DETAIL,
        params: query,
        headers: Util.getHeaders()
    })
}

export const update = (data) => {
    return axios({
        method: 'PATCH',
        url: URL.KAKAOLINKAGE_UPDATE,
        data: data,
        headers: Util.getHeaders()
    })
}

export const clear = (params) => {
    return axios({
        method: 'DELETE',
        url: URL.KAKAOLINKAGE_CLEAR,
        params: params,
        headers: Util.getHeaders()
    })
}