<template>
    <ul v-if="isShow" class="settings_wrap flex gap_20">
        <TreatmentTagSettingTemplate
            :selectedTags="selectedTags"
            @updateSelectedTags="updateSelectedTags"
        />
        <TreatmentTagSettingHospitalList
            :hospitalID="hospitalID"
            :selectedTags="selectedTags"
            @updateSelectedTags="updateSelectedTags"
        />
    </ul>
</template>


<script>
import TreatmentTagSettingTemplate from './TreatmentTagSettingTemplateList.vue'
import TreatmentTagSettingHospitalList from './TreatmentTagSettingHospitalList.vue'

export default {
    components: {
        TreatmentTagSettingTemplate,
        TreatmentTagSettingHospitalList
    },
    props: {
        isShow: Boolean,
        hospital: Object
    },
    data() {
        return {
            originalTags: [],
            selectedTags: [],
        }
    },
    watch: {},
    computed: {
        hospitalID: function() { return this.hospital?._id }
    },
    mounted() {
        this.selectedTags = []
    },
    methods: {
        updateSearchedTags(array) {
            this.originalTags = array
        },
        // MARK: - Emit
        updateSelectedTags(selectedTags) {
            this.selectedTags = selectedTags
        }
    }
}
</script>