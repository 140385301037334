<template>
    <div class="flex_column gap_10">
        <div class="flex_column gap_5">
            <span class="large_txt">주의사항</span>
            <small class="lgray_txt">
                치료태그와 관련된 주의사항이나 이미지를 10장까지 등록할 수 있습니다.
            </small>
        </div>
        <div class="manual_img_wrap flex gap_10">
            <TreatmentTagManualEditPopupImageListItem
                v-for="(item, index) in aws"
                :key="index"
                :index="index"
                :item="item"
                @updateItem="updateItem"
            />
        </div>
    </div>
</template>


<script>
import TreatmentTagManualEditPopupImageListItem from './TreatmentTagManualEditPopupImageListItem.vue'
export default {
    components: { TreatmentTagManualEditPopupImageListItem },
    props: {
        value: {
            type: Object,
            default: () => undefined
        },
    },
    data() {
        return {
            aws: [],
        }
    },
    watch: {
        value: {
            immediate: true,
            handler: function(newValue) {
                this.aws = JSON.parse(JSON.stringify(newValue?.aws ?? []))
                let emptyLength = 10 - this.aws.length
                for (let i=0 ; i<emptyLength ; i++) {
                    this.aws.push({})
                }
            }
        }
    },
    computed: {},
    methods: {
        // MARK: - Emit
        updateItem(item) {
            let index = item.index
            delete item.index
            this.aws.splice(index, index, item)

            let paths = []
            let newAws = []
            for (let i=0 ; i<this.aws.length ; i++) {
                let item = this.aws[i]
                if (item?.Location == undefined || item?.Location == '') { 
                    console.log('is empty')
                    continue
                }
                console.log(item)
                paths.push(item.Location)
                newAws.push(item)
            }
            console.log('newAws : ', newAws)
            let newModel = {
                paths: paths,
                aws: newAws,
            }
            if (this.value?._id != undefined) {
                newModel._id = this.value._id
            }
            console.log('new file model : ', newModel)
            this.$emit('input', newModel)
        }
    },
}
</script>