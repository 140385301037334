<template>
    <div class="snb">
        <div class="snb_title">어드민 계정</div>
            <a 
                @click="action_Account"
                class="snb_menu"
                :class="{ on: $router.currentRoute.path.includes('admin') }"
            >어드민 계정 관리</a>
        </div>
</template>


<script>
export default {
    methods: {
        action_Account: function() {
            this.$router.push({name: 'TreatmentTagManual'})
        },
    }    
}
</script>