<template>
    <div class="snb">
        <div class="snb_title">병원 설정</div>
        <a @click="action_V2HospitalSetting" class="snb_menu" :class="{ on: $router.currentRoute.path.includes('v2hospitallist') }">V2 병원 설정</a>
    </div>
</template>


<script>
export default {
    methods: {
        action_V2HospitalSetting() {

        }
    }
}
</script>