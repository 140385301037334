<template>
    <div class="pop_overlay_bg" v-if="isShow">
        <div class="add_group_pop_wrap">
            <div class="pop_title">
                <span class="title">유형 추가</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon :size="24">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="form_list">
                    <div class="title">
                        유형 명칭<span class="red_txt">*</span>
                </div>
                    <div class="cont">
                        <input class="input_s w_full" type="text" placeholder="사용할 명칭 입력(최대 30자)" maxlength="20" v-model="name">
                        <small class="red_txt" v-if="!isValid_Name">명칭을 입력해 주세요.</small>
                        <!-- <small class="red_txt">사용 중인 명칭 입니다.</small> -->
                    </div>
                </div>
            </div>
            <div class="btn_wrap">
                <button class="btn_l btn_blue" @click="action_Create">저장</button>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    props: {
        isShow: Boolean
    },
    data() {
        return {
            name: undefined,
        }
    },
    computed: {
        isValid_Name: function() {
            return this.name != undefined && this.name != ''
        },
    },
    methods: {
        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },
        async action_Create() {
            if (this.isValid_Name == false) { return }
            let model = {
                name: this.name,
                isSample: true,
                isEnabledSaaS: true,
            }
            let result = await this.API_V3.ServiceSetting.create(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('loadDatas')
            this.$emit('close')
        }
    }
}

</script>