<template>
    <div class="pop_overlay_bg" v-if="isShowPopup">
        <div class="pop_wrap">
            <template v-if="dept === 1">
                <BasicTemplateList
                    @closePopup="closePopup"
                    @setSelectedAlgorithm="setSelectedAlgorithm"
                />
            </template>
            <template v-else-if="dept === 2">
                <BaseConfiguration
                    :hospital="hospital"
                    :algorithmName.sync="algorithmName"
                    :selectedTagIDs="selectedTagIDs"
                    :selectedCountryIDs="selectedCountryIDs"
                    @closePopup="closePopup"
                    @setSelectedTagIDs="setSelectedTagIDs"
                    @setSelectedCountryIDs="setSelectedCountryIDs"
                    @goToPreviousDept="goToPreviousDept"
                    @goToNextDept="goToNextDept"
                />
            </template>
            <template v-else-if="dept === 3">
                <RevisitMessageConfiguration
                    :commonAlgorithm="commonAlgorithm"
                    @setCommonAlgorithm="setCommonAlgorithm"
                    @closePopup="closePopup"
                    @goToPreviousDept="goToPreviousDept"
                    @goToNextDept="goToNextDept"
                />
            </template>
            <template v-else-if="dept === 4">
                <FinalMessageConfiguration
                    v-model="selectedAlgorithm.messages"
                    :serviceSetting="serviceSetting"
                    :kakaoGroups="kakaoGroups"
                    :kakaoProfiles="kakaoProfiles"
                    :kakaoCategories="kakaoCategories"
                    @closePopup="closePopup"
                    @goToPreviousDept="goToPreviousDept"
                    @createAlgorithm="createAlgorithm"
                />
            </template>
        </div>
    </div>
</template>

<script>
import BaseConfiguration
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/BaseConfiguration/BaseConfiguration.vue'
import RevisitMessageConfiguration
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/RevisitMessageConfiguration/RevisitMessageConfiguration.vue'
import FinalMessageConfiguration
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/FinalMessageConfiguration/FinalMessageConfiguration.vue'
import BasicTemplateList
    from '@/views/main/SubViews/SaaS/V2HospitalSetting/views/EditHospitalPopup/Subviews/AutomationBasicTemplate/Popup/BasicTemplateList/BasicTemplateList.vue'
import { convertEditorToReact } from '@/util/HTMLEditorConvertor'

export default {
    components: {
        BasicTemplateList,
        FinalMessageConfiguration,
        RevisitMessageConfiguration,
        BaseConfiguration,
    },
    props: {
        isShowPopup: Boolean,
        serviceSetting: { type: Object, default: () => { return {} } },
        hospital: Object,
        
        templates: { type: Array, default: () => [] },
        kakaoGroups: { type: Array, default: () => [] },
        kakaoProfiles: { type: Array, default: () => [] },
        kakaoCategories: { type: Array, default: () => [] },
    },
    data() {
        return {
            isShowRemoveConfirmPopup: false,
            dAlgorithm: undefined,

            dept: 1,

            selectedAlgorithm: undefined,
            algorithmName: '',
            selectedTagIDs: [],
            selectedCountryIDs: [],

            commonAlgorithm: {
                enabled: false,
                sendingDaysAfterStart: 60
            },
        }
    },
    watch: {
        selectedAlgorithm: {
            deep: true,
            handler: function(newValue) {
                this.algorithmName = newValue?.name ?? ''
                console.info('selected algorithm : ', newValue._id)
            }
        },
        algorithmName: function(newValue) {
            console.info('selected algorithm name : ', newValue)
        },
        selectedTagIDs: function(newValue) {
            console.info('selected tag ids : ', newValue)
        },
        selectedCountryIDs: function(newValue) {
            console.info('selected country id : ', newValue)
        },
        commonAlgorithm: function(newValue) {
            console.info('selected common algorithm : ', newValue)
        }
    },
    computed: {},
    methods: {
        // MARK: - 
        closePopup() {
            this.$emit('loadTemplates')
            this.$emit('update:isShowPopup', false)
        },
        goToPreviousDept() {
            if (this.dept > 1) {
                this.dept -= 1
            }
        },
        goToNextDept() {
            if (this.dept == 2) {
                if (this.canConfirmTreatmentNationality() == false) {
                    alert('이미 적용된 태그 & 국적입니다. ')
                    return
                }
            }
            if (this.dept < 4) {
                this.dept += 1
            }
        },
        setSelectedAlgorithm(algorithm) {
            this.selectedAlgorithm = JSON.parse(JSON.stringify(algorithm))
            if (this.selectedAlgorithm && this.dept === 1) {
                // check has kakao linkage
                if (this.serviceSetting.kakaoProfileSenderKeyAtReady == undefined) {
                    this.selectedAlgorithm.messages.forEach( (message) => {
                        message.kakaoTemplateHistoryId = undefined
                    } )
                }
                this.goToNextDept()
            }
        },
        setSelectedTagIDs(ids) {
            this.selectedTagIDs = ids ?? []
        },
        setSelectedCountryIDs(ids) {
            this.selectedCountryIDs = ids ?? []
        },
        setCommonAlgorithm(value) {
            this.commonAlgorithm = value
        },
        async createAlgorithm() {
            this.selectedAlgorithm.messages.forEach( (m) => {
                delete m._id
                if (m.showConsultationGuide) { m.consultationGuide = convertEditorToReact(m.consultationGuide) }
            })
            let model = {
                ...this.selectedAlgorithm,
                commonAlgorithm: this.commonAlgorithm,
                hospitalID: this.hospital._id,
                targetTreatmentTags: this.selectedTagIDs,
                appliedNationalities: this.selectedCountryIDs,
                isActive: true,
            }
            delete model._id
            this.isLoading = true
            let result = await this.API_V3.AutomationHospital.create(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.isLoading = false
            this.closePopup()
        },

        // MARK: - Emit
        canConfirmTreatmentNationality() {
            const hasItem = (l, r) => {
                const lSet = new Set(l)
                const rSet = new Set(r.map( r=> r._id))
                for (const value of lSet) { if (rSet.has(value)) { return true } }
                return false
            }

            for (const template of this.templates) {
                // console.log(this.selectedTagIDs.length, '-', this.selectedCountryIDs.length, ' : ', template. template.targetTreatmentTags.length, '-', template.appliedNationalities.length)
                let hasTag = hasItem(this.selectedTagIDs, template.targetTreatmentTags)
                let hasNationality = true
                if (this.selectedCountryIDs.length > 0) {
                    hasNationality = hasItem(this.selectedCountryIDs, template.appliedNationalities)
                }
                console.log('hasTag : ', hasTag, 'hasNationality : ', hasNationality, this.selectedCountryIDs)
                if (hasTag && hasNationality) return false
            }
            return true
        },
    }
}

</script>

