<template>
    <div class="mgt_15" v-if="isShow">
        <b class="dgray_txt">질문</b>
        <textarea v-model="question" class="w_full" placeholder="질문을 입력해 주세요."
                  maxlength="1000"></textarea>
        <small class="red_txt mgb_5" v-if="questionErrorMessage">{{ questionErrorMessage }}</small>

        <div class="var_sample">
            <b><small class="dgray_txt">치환문자</small></b>
            <span class="label lgray" v-pre>{{고객명}}</span>
            <span class="label lgray" v-pre>{{병원명}}</span>
            <span class="label lgray" v-pre>{{시술명}}</span>
            <span class="label lgray" v-pre>{{시술일+N일}}</span>
            <span class="label lgray" v-pre>{{경과일}}</span>
        </div>
        <b class="dgray_txt mgt_15">답변</b>

        <div class="msg_survey_ans">
            <input type="text" v-model="newAnswer" class="input_s" placeholder="답변을 입력해 주세요."
                   @keyup.enter="action_AddAnswer">
            <button class="btn btn_s btn_blue_line" @click="action_AddAnswer" :disabled="!newAnswer">추가
            </button>
        </div>

        <div
            v-for="(_, index) in answers"
            :key="index"
            class="msg_survey_ans mgt_5"
        >
            <div class="msg_survey_ans mgt_5">
                <input type="text"
                       class="input_s"
                       placeholder="답변을 입력해 주세요."
                       v-model="answers[index]"
                >
                <button class="btn btn_s btn_gray_line" @click="action_RemoveAnswer(index)">삭제</button>
            </div>
        </div>

        <small class="red_txt mgb_5" v-if="answerErrorMessage">
            {{ answerErrorMessage }}
        </small>
    </div>
</template>


<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {
                return {}
            }
        },
        isShow: Boolean,
        questionErrorMessage: String,
        answerErrorMessage: String
    },
    data() {
        return {
            question: '',
            answers: [],

            newAnswer: ''
        }
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler: function(newValue) {
                this.question = newValue?.question ?? ''
                this.answers = newValue?.answers ?? []
            }
        },
        question: function(newValue, oldValue) {
            if (newValue == oldValue) return
            this.$emit('update:questionErrorMessage', '')
            this.$emit('input', {
                question: newValue,
                answers: this.answers
            })
        },
        answers: {
            deep: true,
            handler: function(newValue) {
                this.$emit('update:answerErrorMessage', '')
                this.$emit('input', {
                    question: this.question,
                    answers: newValue
                })
            }
        }
    },
    computed: {},
    methods: {
        action_AddAnswer() {
            if (this.newAnswer) {
                this.answers.push(this.newAnswer)
                this.newAnswer = ''
            }
        },
        action_RemoveAnswer(index) {
            this.answers.splice(index, 1)
        }
    }
}
</script>