<template>
    <tr @click.stop="action_Select">
        <td width="280">{{ title }}</td>
        <td width="80">
            <button class="table_btn btn_gray" @click.stop="action_Delete" :disabled="!auth.onboarding_w">삭제</button>
        </td>
    </tr>
</template>


<script>
export default {
    props: {
        index: Number,
        item: Object,
    },
    data() {
        return {

        }
    },
    computed: {
        title: function() {
            return this.item?.name ?? '-'
        }
    },
    methods: {
        action_Select() {
            this.$emit('setSelectedSetting', this.item)
        },
        action_Delete() {
            this.$emit('removeItem', this.index)
        }
    },
}
</script>