import axios from "axios"
import * as URL from '../../url'
import Util from '../../../api/Util'


export const find = (model) => {
    return axios({
        method: 'GET',
        url: URL.VIDEO_CONTENT_CATEGORY_FIND,
        params: model,
        headers: Util.getHeaders()
    })
}

export const findOne = (model) => {
    return axios({
        method: 'GET',
        url: URL.VIDEO_CONTENT_CATEGORY_FIND_ONE,
        params: model,
        headers: Util.getHeaders()
    })
}