<template>
    <tr>
        <td>
            <p :title="groupTitle">{{ title }}</p>
        </td>
        <td>
            <p>
                {{ templateName }}
            </p>
        </td>
        <td>
            <p :title="templateCode">{{ templateCode }}</p>
        </td>
        <td><small class="service_status" :class="currentClass">{{ templateStatus }}</small></td>
        <td>
            <span>{{ createdAtDate }}</span>
            <span class="mgl_5">{{ createdAtTime }}</span>
        </td>
        <td>
            <span>{{ updatedAtDate }}</span>
            <span class="mgl_5">{{ updatedAtTime }}</span>
        </td>
        <td>
            <div class="flex_aic_jcc">
                <button class="btn_gray btn_s">삭제</button>
                <button class="btn_blue_line btn_s" @click="action_Select">수정</button>
            </div>
        </td>
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        template: {
            type: Object,
            default: () => { return {} }
        },
        groups: {
            type: Array,
            default: () => []
        },
        selectedProfile: Object
    },
    computed: {
        statusKind: function() {
            return [
                {name: '등록',      key: 'REG', class: 'lgray'},
                {name: '검수요청',  key: 'REQ', class: 'lgray'},
                {name: '반려',      key: 'REJ', class: 'lred'},
                {name: '차단',      key: 'STP', class: 'lred'},
                {name: '발송전',    key: 'RDY', class: 'lgreen'},
                {name: '정상',      key: 'ACT', class: 'lgreen'},
                {name: '휴면',      key: 'DMT', class: 'lgray'},
                {name: '차단',      key: 'BLK', class: 'lgray'},
            ]
        },
        currentClass: function() {
            if (this.template?.serviceStatus == undefined) { return undefined }
            let kind = this.statusKind.find( (s) => s.key == this.template.serviceStatus ) 
            return kind?.class
        },
        groupTitle: function() {
            let group = this.groups.find( (g) => g.groupKey == this.template?.senderKey)
            return group?.name ?? '-'
        },
        profileTitle: function() {
            return this.selectedProfile?.name ?? '-'
        },
        title: function() {
            if (this.template.senderKeyType == 'G') { return this.groupTitle }
            else { return this.profileTitle }
        },
        templateName: function() {
            return this.template?.templateName ?? '-'
        },
        templateCode: function() {
            return this.template?.templateCode ?? '-'
        },
        templateStatus: function() {
            if (this.template?.serviceStatus == undefined) { return '-' }
            let kind = this.statusKind.find( (s) => s.key == this.template.serviceStatus ) 
            return kind?.name ?? '-'
        },
        createdAtDate: function() {
            if (this.template?.createdAt == undefined) { return '-' }
            return moment(this.template?.createdAt).format('YYYY-MM-DD')
        },
        createdAtTime: function() {
            if (this.template?.createdAt == undefined) { return '-' }
            return moment(this.template?.createdAt).format('HH:mm')
        },
        updatedAtDate: function() {
            if (this.template?.modifiedAt == undefined) { return '-' }
            return moment(this.template?.modifiedAt).format('YYYY-MM-DD')
        },
        updatedAtTime: function() {
            if (this.template?.modifiedAt == undefined) { return '-' }
            return moment(this.template?.modifiedAt).format('HH:mm')
        },
    },
    methods: {
        action_Select() {
            this.$emit('setSelectedEditTemplate', this.template)
        },
        action_Delete() {
            this.$emit('setSelectedDeleteTemplate', this.template)
        }
    },
}
</script>