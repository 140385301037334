<template>
    <tr>
        <td>{{ name }}</td>
        <td>{{ memo }}</td>
        <td>{{ messageCount }}</td>
        <td>{{ updatedAt }}</td>
        <td><button class="btn_s btn_gray" @click="action_Remove">삭제</button></td>
        <td><button class="btn_s btn_blue_line" @click="action_Modify">수정</button></td>
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        algorithm: Object
    },
    watch: {},
    computed: {
        name: function() { return this.algorithm.name ?? '-' },
        memo: function() { return this.algorithm.memo ?? '-' },
        messageCount: function() { return (this.algorithm?.messages ?? []).length },
        updatedAt: function() {
            if (this.algorithm.updatedAt == undefined && this.algorithm.createdAt == undefined) { return '-' }
            return moment(this.algorithm.updatedAt ?? this.algorithm.createdAt).format('YYYY-MM-DD HH:mm')
        },
    },
    methods: {
        action_Remove() {
            this.$emit('remove', this.algorithm)
        },
        action_Modify() {
            this.$emit('modify', this.algorithm)
        },
    },
}
</script>