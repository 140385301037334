<template>
    <div class="account_add_set">
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                이름<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_column gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="최대 15자" maxlength="15" v-model="realName">
                    <small class="red_txt" v-if="!isValid_Name">이름을 입력해 주세요.</small>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                휴대폰 번호
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_aic gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="010-1234-5678" v-model="phone">
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                아이디<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_column gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="영문, 숫자 포함 3~25자" maxlength="25" v-model="id">
                    <small class="red_txt" v-if="!isValid_ID">아이디를 입력해 주세요.</small>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                비밀번호<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_column gap_5">
                    <input type="text" class="w_full" name="" id="" placeholder="영문, 숫자 포함 3~25자" maxlength="25" v-model="password">
                    <small class="red_txt" v-if="!isValid_Password">비밀번호를 입력해 주세요.</small>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                직책/직급<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_column gap_5">
                    <div class="flex_aic gap_5">
                        <select name="직책/직급" id="" class="w_full" v-model=jobType>
                            <option :value="undefined" disabled>--선택--</option>
                            <option value="대표원장님">대표원장님</option>
                            <option value="원장님">원장님</option>
                            <option value="팀장">팀장</option>
                            <option value="상담실장">상담실장</option>
                            <option value="실장">실장</option>
                            <option value="치료사">치료사</option>
                            <option value="(병원)">(병원)</option>
                            <option value="직접입력">직접입력</option>
                        </select>
                        <input type="text" placeholder="직접입력" :disabled="jobType!='직접입력'" class="w_full" v-model="additionalJobType">
                    </div>
                    <small class="red_txt" v-if="!isValid_JobType">직책/직급을 선택해 주세요.</small>
                </div>
            </div>
        </li>
        <li class="settings_list flex_spb">
            <div class="settings_list_title">
                권한<small class="red_txt">*</small>
            </div>
            <div class="settings_list_option flex_column">
                <div class="flex_column gap_5">
                    <select name="권한" id="" class="w_full" v-model="authorizationTypeID">
                        <option :value="undefined" disabled>--선택--</option>
                        <option 
                            v-for="(type, index) in authorizationTypes"
                            :key="index"
                            :value="type._id"
                        >{{ type.name }}</option>
                    </select>
                    <small class="red_txt" v-if="!isValid_AuthorizationType">권한을 선택해 주세요.</small>
                </div>
            </div>
        </li>
        <div class="flex_aic_fle" style="grid-column: 2;"><button class="btn_s btn_blue" @click="action_Add" :disabled="!auth.v2HospitalManagement_w">추가</button></div>
    </div>
</template>


<script>
export default {
    props: {
        hospitalID: String,
        authorizationTypes: Array,
    },
    data() {
        return {
            phone: undefined,
            id: undefined,
            password: undefined,
            realName: undefined,
            type: undefined,
            jobType: undefined,
            additionalJobType: undefined,
            authorizationTypeID: undefined,
        }
    },
    watch: {},
    computed: {
        isValid_Name: function() {
            if (this.realName == undefined || this.realName == '') { return false }
            return true
        },
        isValid_ID: function() {
            if (this.id == undefined || this.id =='') { return false }
            if (this.id?.length < 5) { return false }
            return true
        },
        isValid_Password: function() {
            if ((this.password?.length ?? 0) < 5) { return false }
            let reg = /[^a-zA-Z0-9!@#$%^&+=]/gi
            if (reg.test(this.password) == true) { return false }
            return true
        },
        isValid_JobType: function() {
            if (this.jobType == undefined || this.jobType == '') { return false }
            if (this.jobType == '직접입력') {
                if (this.additionalJobType == undefined || this.additionalJobType == '') { return false }
            }
            return true
        },
        isValid_AuthorizationType: function() {
            if (this.authorizationTypeID == undefined || this.authorizationTypeID == '') { return false }
            return true
        },
    },
    methods: {
        initProperty() {
            this.realName = undefined
            this.phone =  undefined
            this.id =  undefined
            this.password =  undefined
            this.type =  undefined
            this.jobType =  undefined
        },

        // MARK: - Network
        async createDoctor(model) {
            let result = await this.API_V3.Hospital.createAccount(model)
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.$emit('loadDatas')
        },

        // MARK: - Actions
        action_Add() {
            let jobType = this.jobType == '직접입력' ? this.additionalJobType : this.jobType
            let model = {
                hospitalID: this.hospitalID,
                id: this.id,
                password: this.password,
                phone: this.phone,
                name: this.realName,
                realName: this.realName,
                type: 2200,
                jobType: jobType,
                authorizationTypeID: this.authorizationTypeID,
                isDoctor: true,
            }
            this.createDoctor(model)
        }
    }
}
</script>