import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const count = (m) => {
    return axios({
        method: 'get',
        url: URL.ADMINUSER_COUNT,
        params: m,
        headers: Util.getHeaders()
    })
}

export const find = (m) => {
    return axios({
        method: 'get',
        url: URL.ADMINUSER,
        params: m,
        headers: Util.getHeaders()
    })
}

export const findOne = (m) => {
    return axios({
        method: 'get',
        url: URL.ADMINUSER_FIND_ONE,
        params: m,
        headers: Util.getHeaders()
    })
}

export const create = (m) => {
    return axios({
        method: 'post',
        url: URL.ADMINUSER,
        data: m,
        headers: Util.getHeaders()
    })
}

export const update = (m) => {
    return axios({
        method: 'patch',
        url: URL.ADMINUSER,
        data: m,
        headers: Util.getHeaders()
    })
}

export const remove = (m) => {
    return axios({
        method: 'delete',
        url: URL.ADMINUSER,
        params: m,
        headers: Util.getHeaders()
    })
}