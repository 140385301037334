<template>
    <div class="consultauto_add_nav">
        <!-- 템플릿 명칭 입력 영역 -->
        <div class="consultauto_title">
            <div class="input_wrap">
                <input type="text" class="input_s" placeholder="상담자동화 명칭 입력" v-model="name">
            </div>
            <div class="input_wrap">
                <textarea class="w_full" placeholder="템플릿 메모를 입력해 주세요." v-model="memo"></textarea>
            </div>
        </div>
        <!-- 메시지 리스트 -->
        <div class="msg_list_wrap">
            <div class="scroll_wrap">
                <div class="scroll">
                    <!-- 메시지 리스트 항목 -->
                    <ConsultAutomationEditPopupMessageListItem
                        v-for="(message, index) in messages" :key="index"
                        :message="message"
                        :index="index"
                        :selectedMessage="selectedMessage"
                        @setSelectMessage="setSelectMessage"
                        @removeMessage="removeMessage"
                    />
                </div>
            </div>
            <!-- 메시지 추가 버튼 영역 -->
            <div class="btn_wrap">
                <button class="btn_s btn_blue_line" @click="action_AddMessage">메시지 추가</button>
            </div>
        </div>
    </div>
</template>


<script>
import ConsultAutomationEditPopupMessageListItem from './ConsultAutomationEditPopupMessageListItem.vue'

export default {
    components: {
        ConsultAutomationEditPopupMessageListItem
    },
    props: {
        nameModel: String,
        memoModel: String,
        messagesModel: Array,
        selectedMessage: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            name: '',
            messages: [],
            memo: ''
        }
    },
    watch: {
        nameModel: {
            immediate: true,
            handler: function(newValue) {
                this.name = newValue
            }
        },
        memoModel: {
            immediate: true,
            handler: function(newValue) {
                this.memo = newValue
            }
        },
        messagesModel: {
            immediate: true,
            handler: function(newValue) {
                this.messages = newValue ?? []
            }
        },
        name: function() {
            this.updateName()
        },
        memo: function() {
            this.updateMemo()
        }
    },
    computed: {},
    methods: {
        // MARK: - Actions
        action_AddMessage() {
            // message 생성 및 초기화
            this.messages.push({
                sendingType: undefined,
                name: '',
                content: {
                    text: undefined,
                    image: undefined,
                    video: undefined,
                    survey: {
                        question: '',
                        answers: []
                    }
                },
                daysAfter: 1,
                timeOfDay: undefined,
                showConsultationGuide: false,
                consultationGuide: '',
                kakaoTemplateHistoryId: undefined,
                hasApprovedAlimtalkTemplate: false
            })
            this.updateMessages()
        },
        setSelectMessage(index) {
            let message = this.messages[index]
            console.log('select : ', index, message)
            let messageObject = {
                index: index,
                message: message
            }
            this.$emit('setSelectMessage', messageObject)
        },
        removeMessage(index) {
            let message = this.messages[index]
            if (this.selectedMessage === message) {
                this.$emit('setSelectMessage', { index: 0, message: undefined })
            }
            this.messages.splice(index, 1)
        },

        // MARK: - Emit
        updateMessages() {
            this.$emit('updateMessages', this.messages)
        },
        updateName() {
            this.$emit('updateName', this.name)
        },
        updateMemo() {
            this.$emit('updateMemo', this.memo)
        }
    }
}
</script>