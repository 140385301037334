import axios from "axios"
import * as URL from '../url'
import Util from '../../api/Util'

export const findOne = (m) => {
    return axios({
        method: 'get',
        url: URL.INTEGRATION_SETTING_FIND_ONE,
        params: m,
        headers: Util.getHeaders()
    })
}

export const upsert = (m) => {
    return axios({
        method: 'post',
        url: URL.INTEGRATION_SETTING_UPSERT,
        data: m,
        headers: Util.getHeaders()
    })
}