import * as Hospital from "./Hospital/Hospital"
import * as Account from './Hospital/Account'
import * as ServiceSetting from './ServiceSetting/ServiceSetting'
import * as HealthContentCategory from './HealthContent/HealthContentCategory'
import * as Nurse from './Nurse/Nurse'
import * as AnsweringChatSetting from './AnsweringChatSetting/AnsweringChatSetting'
import * as VideoContentCategory from "./VideoContent/VideoContentCategory/VideoContentCategory"
import * as IntegrationSetting from './IntegrationSetting/IntegrationSetting'
import * as TreatmentCategory from './TreatmentCategory/TreatmentCategory'
import * as File from './File/File'
import * as Country from './Country/Country'
import * as AdminUser from './AdminUser/AdminUser'
import * as AdminAuthorizationType from './AdminAuthorizationType/AdminAuthorizationType'
import * as KakaoLinkageHistory from './KakaoLinkageHistory/KakaoLinkageHistory'
import * as KakaoTemplate from './KakaoTemplate/KakaoTemplate'
import * as Automation from './Automation/Automation'
import * as AutomationHospital from './Automation/AutomationHospital'

import Public from './Public/Public'


export default {
    Hospital,
    Account,
    ServiceSetting,
    HealthContentCategory,
    Nurse,
    AnsweringChatSetting,
    VideoContentCategory,
    IntegrationSetting,
    TreatmentCategory,
    File,
    Country,
    AdminUser,
    AdminAuthorizationType,
    KakaoLinkageHistory,
    KakaoTemplate,
    Automation,
    AutomationHospital,
    Public
}