<!-- 자동화 선택 후 다음 버튼 클릭 시 나타나는 팝업 -->
<template>
    <div class="basic_template_pop_wrap">
        <div class="pop_title">
            <span class="title">템플릿 등록</span>
            <a class="mgl_auto" @click="action_ClosePopup">
                <v-icon :size="24">mdi-close</v-icon>
            </a>
        </div>
        <div class="pop_cont">
            <div class="template_tag_wrap">
                <div class="form_list">
                    <div class="title">
                        공통 재내원 메시지 사용 여부<span class="red_txt">*</span>
                    </div>
                    <div class="cont">
                        <div class="toggle_btn_wrap">
                            <input
                                class="toggle_input"
                                type="checkbox"
                                :checked="localCommonAlgorithm.enabled"
                                @change="updateEnabled($event)"
                            >
                            <span class="toggle_label"></span>
                        </div>
                    </div>
                </div>
                <div class="form_list pdt_15" style="border-top: 1px solid #dfdfe0;">
                    <div class="title">
                        공통 재내원 메시지 발송 일자<span class="red_txt">*</span>
                    </div>
                    <div class="cont">
                        <div class="flex_aic gap_5">
                            <span>알고리즘 실행 후 </span>
                            <input
                                type="number"
                                min="1"
                                max="365"
                                class="input_s"
                                style="max-width: 60px;"
                                :disabled="!localCommonAlgorithm.enabled"
                                :value="localCommonAlgorithm.sendingDaysAfterStart"
                                @input="updateSendingDays($event)"
                            >
                            <span>일 이후에 공통 재내원 메시지를 발송합니다.
                                        </span>
                            <small class="lgray_txt">(최대 365일)</small>
                        </div>
                    </div>
                </div>
                <div class="flex_aic_jcc mgt_15">
                    <button class="btn_gray_line btn_s" @click="action_PreviousStep">이전</button>
                    <button class="btn_blue_line btn_s" @click="action_NextStep">다음</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RevisitMessageConfiguration',
    components: {},
    props: {
        commonAlgorithm: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            localCommonAlgorithm: {}
        }
    },
    watch: {
        commonAlgorithm: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.localCommonAlgorithm = { ...newValue }
            }
        }
    },
    computed: {},
    methods: {
        action_ClosePopup() {
            this.$emit('closePopup')
        },
        action_PreviousStep() {
            this.$emit('goToPreviousDept')
        },
        action_NextStep() {
            this.$emit('goToNextDept')
        },
        updateEnabled(event) {
            this.localCommonAlgorithm.enabled = event.target.checked
            this.$emit('setCommonAlgorithm', { ...this.localCommonAlgorithm })
        },
        updateSendingDays(event) {
            this.localCommonAlgorithm.sendingDaysAfterStart = Number(event.target.value)
            this.$emit('setCommonAlgorithm', { ...this.localCommonAlgorithm })
        }
    }
}

</script>

