import Vue from 'vue'
import Vuetify from 'vuetify'
import App from './App.vue'
import Router from './router'

import store from './store'
import VueMoment from 'vue-moment'
import API from './api/API'
import API_V3 from './api_v3/API_V3'
import MPAWS from './util/MPAWS'
import { mapState } from 'vuex'
import ThemeProvider from './providers/ThemeProvider'

Vue.config.productionTip = false

Vue.mixin({
    data: function () {
        return {
            API: API,
            MPAWS: MPAWS,
            API_V3: API_V3
        }
    },
    computed: {
        ...mapState(['user', 'auth'])
    }, // none
    components: {
        'theme-provider': ThemeProvider
    },

    methods: {}
})

Vue.use(Vuetify)

new Vue({
    router: Router,
    store: store,
    VueMoment: VueMoment,
    render: (h) => h(App),
    components: {
        'theme-provider': ThemeProvider
    },
    vuetify: new Vuetify({
        icons: {
            iconfont: 'mdi'
        }
    })
}).$mount('#app')

// export { ThemeProvider }
