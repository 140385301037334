<template>
    <tr>
        <td class="txt_center">
            <small class="service_status" :class="statusClass">{{ status }}</small>
        </td>
        <td>
            <div class="flex_column">
                <p style=" max-width: 250px;">{{ shortName }}</p>
                <small class="lgray_txt">{{ hospitalID }}</small>
            </div>
        </td>
        <td>
            <div class="flex_column">
                <p style="max-width: 100px;">{{ nurseName }}</p>
                <small class="lgray_txt">{{ nurseID }}</small>
            </div>
        </td>
        <td class="txt_center">
            {{ createdAt }}
        </td>
        <td class="txt_center">
            {{ currentAccountCount }}/{{ limitAccountCount }}
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': hasManual}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': hasAutomation}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': usingTranslation}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': hasKakaoConsult}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': hasKakaoFriend}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': isIntegrationAL}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': usingEvent}"></div>
        </td>
        <td class="txt_center">
            <div class="active_status" :class="{'active': usingVideoContent}"></div>
        </td>
        <td class="txt_center">
            <button class="table_btn btn_blue_line" @click="action_EditHospital">수정</button>
        </td>
    </tr>
</template>


<script>

const moment = require('@/util/MPMoment')

export default {
    props: {
        hospital: Object
    },
    computed: {
        status: function() {
            return this.hospital?.serviceSetting?.status ?? '-'
        },
        shortName: function() {
            return this.hospital?.shortName ?? '-'
        },
        hospitalID: function() {
            return this.hospital?._id ?? '-'
        },
        nurseName: function() {
            return this.hospital?.nurseID?.name ?? '-'
        },
        nurseID: function() {
            return this.hospital?.nurseID?.id ?? '-'
        },
        createdAt: function() {
            if (this.hospital?.createdAt != undefined) {
                let date = moment(this.hospital?.createdAt)
                return date.format('YYYY-MM-DD')
            }
            return '-'
        },
        limitAccountCount: function() {
            return this.hospital?.serviceSetting?.accountCount ?? '-'
        },
        currentAccountCount: function() {
            return this.hospital?.userCount ?? '-'
        },
        hasManual: function() {
            return (this.hospital?.manualCount ?? 0) > 0
        },
        hasAutomation: function() {
            return (this.hospital?.algorithmCount ?? 0) > 0
        },
        usingTranslation: function() {
            return this.hospital?.serviceSetting?.usingChatTranslate ?? false
        },
        hasKakaoConsult: function() {
            return this.hospital?.kakaoConsult?._id != undefined
        },
        hasKakaoFriend: function() {
            return this.hospital?.serviceSetting.isReadyForKakaoAlimAndFriendTalkLinkage
        },
        isIntegrationAL: function() {
            return this.hospital?.integrationSetting?.isRemoved == false && this.hospital?.integrationSetting?.integrationType == 'ALM'
        },
        usingEvent: function() {
            return this.hospital?.usingHospitalEvent == true
        },
        usingVideoContent: function() {
            return this.hospital?.usingVideoContent == true
        },

        statusClass: function() {
            switch (this.status) {
                case '대기':
                    return 'lyellow'
                case '진행':
                    return 'lblue'
                case '종료':
                    return 'lgray'
                default: return undefined
            }
        },
    },
    methods: {
        action_EditHospital() {
            this.$emit('editHospital', this.hospital._id)
        }
    }
}
</script>