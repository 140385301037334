const state = {
    hospitalManagement_v: false,
    hospitalManagement_w: false,
    hospitalState_v: false,
    hospitalState_w: false,
    v2HospitalManagement_v: false,
    v2HospitalManagement_w: false,
    v2HospitalState_v: false,
    v2HospitalState_w: false,
    v2HospitalIntegration_v: false,
    v2HospitalIntegration_w: false,
    serviceManager_v: false,
    serviceManager_w: false,
    videoContent_v: false,
    videoContent_w: false,
    onboarding_v: false,
    onboarding_w: false,
    app_v: false,
    app_w: false,
    electron_v: false,
    electron_w: false,
    integration_v: false,
    integration_w: false,
    monitoring_v: false,
    monitoring_w: false,
    eventManagement_v: false,
    algorithm_v: false,
    algorithm_w: false,
    reservedChat_v: false,
    reservedChat_w: false,
    treatmentCategory_v: false,
    treatmentCategory_w: false,
    alrimtalk_v: false,
    alrimtalk_w: false,
    adminAccount_v: false,
    adminAccount_w: false,
}
const getters = {}
const actions = {}
const mutations = {
    MU_SetAdminAuthorization: (state, params) => {
        let keys = Object.keys(state)
        for (let i=0 ; i<keys.length ; i++) {
            let key = keys[i]
            state[key] = params[key] ?? false
        }
    }
}

export default { state, getters, actions, mutations }