<template>
    <div class="v2_content_wrap">
        <CustomToast
            :message="toastMessage"
            @clear="setToastMessage"
        />
        <LoadingIndicate
            :isLoading="isLoading"
        />
        <AlrimtalkMessageEditPopup
            :isShow="isShowEditPopup"
            :template="selectedTemplate"
            :groups="groups"
            @setToastMessage="setToastMessage"
            @setSelectedEditTemplate="setSelectedEditTemplate"
            @close="closeEditPopup"
        />
        <AlrimtalkMessageProfilePopup
            :isShowSelectGroupPopup="isShowGroupPopup"
            :isShowSelectProfilePopup="isShowProfilePopup"
            @close="closeEditPopup"
            @updateSelectedSenderKey="updateSelectedSenderKey"
        />
        
        <div class="page_title">
            <span>알림톡 메시지</span>
        </div>
        
        <div class="h_scroll">
            <div class="table_title mgb_20">
                <div class="status_filter flex_aic">
                    <select name="isShowInList" id="" v-model="profileType" class="input_s">
                        <option value="G">그룹</option>
                        <option value="S">프로필</option>
                    </select>
                    <input type="text" :value="selectedSenderKeyTitle" disabled class="input_s">
                    <button class="btn_s btn_blue_line" @click="action_ShowProfilePopup">선택</button>
                </div>
                <div>
                    <!-- 검색 안됨 제거 필요 -->
                    <!-- <select name="카테고리그룹" id="" class="input_s" v-model="selectedCategoryGroup">
                        <option :value="undefined" selected>없음</option>
                        <option v-for="(c, index) in parentCategories" :key="index" :value="c.groupName">{{ c.groupName }}</option>
                    </select>
                    <select name="카테고리" id="" class="input_s" v-model="selectedCategoryCode">
                        <option :value="undefined" selected>없음</option>
                        <option v-for="(c, index) in computedCategories" :key="index" :value="c.code">{{ c.name }}</option>
                    </select> -->
                </div>
                <div class="table_title_btn">
                    <select name="상태" id="" class="input_s" v-model="templateStatus">
                        <option :value="undefined" selected>없음</option>
                        <option v-for="(s, index) in statusKind" :key="index" :value="s.key">{{ s.name }}</option>
                    </select>
                    <input type="text" class="input_s" placeholder="검색어 입력" v-model="keyword" @keydown.enter="action_Search" />
                    <button class="btn_s btn_blue_line mgl_5" @click="action_Search">검색</button>
                    <a class="btn btn_s btn_blue" @click="action_Create">추가</a>
                </div>
            </div>
            <AlrimtalkMessageList
                :templates="templates"
                :groups="groups"
                :selectedProfile="selectedProfile"
                @setSelectedEditTemplate="setSelectedEditTemplate"
            />
        </div>
        <PagenationComponent
            v-bind:totalCount="totalCount"
            v-bind:currentPage="currentPage"
            v-bind:countPerPage="countPerPage"
            v-bind:countPerRow="5"
            v-on:changePage="loadPageIndex"
        />
    </div>
</template>


<script>
import CustomToast from '@/views/main/ShareComponents/CustomToast.vue'
import LoadingIndicate from '@/views/main/ShareComponents/LoadingIndicate.vue'
import AlrimtalkMessageEditPopup from './AlrimtalkMessageEditPopup.vue'
import AlrimtalkMessageProfilePopup from './AlrimtalkMessageProfilePopup.vue'
import AlrimtalkMessageList from './AlrimtalkMessageList.vue'
import PagenationComponent from '@/views/main/ShareComponents/PagenationComponent.vue'
export default {
    components: {
        LoadingIndicate,
        AlrimtalkMessageEditPopup,
        AlrimtalkMessageProfilePopup,
        AlrimtalkMessageList,
        PagenationComponent,
        CustomToast,
    },
    data() {
        return {
            isLoading: false,
            toastMessage: undefined,

            isShowEditPopup: false,

            isShowGroupPopup: false,
            isShowProfilePopup: false,
            
            selectedTemplate: undefined,

            groups: [],
            categories: [],
            templates: [],
            selectedGroup: undefined,
            selectedProfile: undefined,

            selectedCategoryGroup: undefined,
            selectedCategoryCode: undefined,
            templateStatus: undefined,
            keyword: undefined,

            profileType: 'G',

            totalCount: 0,
            currentPage: 0,
            countPerPage: 12,

            selectedGroupProfileTitle: undefined,
        }
    },
    watch: {
        selectedCategoryGroup: {
            handler: function() {
                this.selectedCategoryCode = undefined
            }
        }
    },
    computed: {
        statusKind: function() {
            return [
                {name: '등록',      key: 'REG'},
                {name: '검수요청',  key: 'REQ'},
                {name: '반려',      key: 'REJ'},
                {name: '차단',      key: 'STP'},
                {name: '발송전',    key: 'RDY'},
                {name: '정상',      key: 'ACT'},
                {name: '휴면',      key: 'DMT'},
                {name: '차단',      key: 'BLK'},
            ]
        },
        selectedSenderKeyTitle: function() {
            if (this.selectedGroup != undefined) { return this.selectedGroup?.name ?? '-' }
            else if (this.selectedProfile != undefined) { return this.selectedProfile?.name ?? '-' }
            return '-'
        },
        parentCategories: function() {
            if (this.categories == undefined) { return [] }
            return Array.from(
                this.categories.reduce( (map, c) => map.set(c.groupName, c), new Map()).values()
            )
        },
        computedCategories: function() {
            if (this.selectedCategoryGroup != undefined) {
                return this.categories.filter( (c) => c.groupName == this.selectedCategoryGroup )
            }
            return []
        },
        senderKey: function() {
            if (this.selectedGroup != undefined) { return this.selectedGroup?.groupKey }
            else if (this.selectedProfile != undefined) { return this.selectedProfile?.senderKey }
            return undefined
        },
        senderKeyType: function() {
            if (this.selectedGroup != undefined) { return 'G' }
            return 'S'
        }
    },
    mounted() {
        this.initTable()
    },
    methods: {
        async initTable() {
            this.isLoading = true
            await this.loadGroup()
            await this.loadCategories()
            await this.loadPageIndex(0)
            this.isLoading = false
        },

        // MARK: - Network
        async loadGroup() {
            let result = await this.API_V3.KakaoTemplate.findGroup()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.groups = result.data.data
            if (this.groups.length > 0) {
                this.selectedGroup = this.groups[0]
            }
        },
        async loadCategories() {
            let result = await this.API_V3.KakaoTemplate.findCategory()
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.categories = result.data.data
        },
        async loadPageIndex(index) {
            this.currentPage = index
            let model = {
                senderKey: this.senderKey == '' ? undefined : this.senderKey,
                senderKeyType: this.senderKeyType == '' ? undefined : this.senderKeyType,
                templateStatus: this.templateStatus == '' ? undefined : this.templateStatus,
                keyword: this.keyword == '' ? undefined : this.keyword,
                page: this.currentPage + 1,
                count: this.countPerPage,
                categoryCodeList: this.selectedCategoryCode == '' ? undefined : [this.selectedCategoryCode],
            }
            let result = await this.API_V3.KakaoTemplate.findTemplate(model)
            if (result.data.code == '508') {
                this.totalCount = 0
                this.templates = []
                return
            }
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            let data = result.data.data
            this.totalCount = data.totalCount
            this.templates = data.list
        },


        // MARK: - Actions
        action_Create() {
            this.isShowEditPopup = true
            this.selectedTemplate = undefined
        },
        action_ShowProfilePopup() {
            if (this.profileType == 'G') {
                this.isShowGroupPopup = true
            }
            else {
                this.isShowProfilePopup = true
            }
        },
        action_Search() {
            this.loadPageIndex(0)
        },

        // MARK: - Emit
        setToastMessage(message) {
            this.toastMessage = message
        },
        closeEditPopup() {
            this.loadPageIndex(this.currentPage)
            this.isShowEditPopup = false

            this.selectedTemplate = undefined

            this.isShowProfilePopup = false
            this.isShowGroupPopup = false
        },
        updateSelectedSenderKey(obj) {
            this.selectedGroup = obj.group
            this.selectedProfile = obj.profile
        },
        setSelectedEditTemplate(template) {
            this.selectedTemplate = template
            this.isShowEditPopup = true
        },
        setSelectedDeleteTemplate(template) {
            this.deleteTemplate = template
        }
    }
}
</script>