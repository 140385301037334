<template>
    <tr>
        <td>
            {{ hospitalName }}
        </td>
        <td>
            {{ pf_id }}
        </td>
        <td>
            <span class="day_list">
                <span v-if="possibleDays[0]==true">월</span>
                <span v-if="possibleDays[1]==true">화</span>
                <span v-if="possibleDays[2]==true">수</span>
                <span v-if="possibleDays[3]==true">목</span>
                <span v-if="possibleDays[4]==true">금</span>
                <span v-if="possibleDays[5]==true">토</span>
                <span v-if="possibleDays[6]==true">일</span>
            </span> 
            <span class="day_time">{{ possibleTimes }}</span>
        </td>
        <td>{{ createdAt }}</td>
        <td class="txt_center">
            <small class="lblue service_status" v-if="isLinked">사용중</small>
        </td>
        
    </tr>
</template>


<script>
const moment = require('@/util/MPMoment')
export default {
    props: {
        index: Number,
        history: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {}
    },
    computed: {
        hospitalName: function() {
            if (this.history.hospitalName) { return this.history.hospitalName }
            if (this.history.tempHospitalName) { return this.history.tempHospitalName }
            return '-'
        },
        pf_id: function() {
            return this.history.searchableChannelId ?? '-'
        },
        consultationInformation: function() {
            return this.history.consultationInformation
        },
        possibleDays: function() {
            let {mondayAvailable, tuesdayAvailable, wednesdayAvailable, thursdayAvailable, fridayAvailable, saturdayAvailable, sundayAvailable} = this.consultationInformation
            return [mondayAvailable, tuesdayAvailable, wednesdayAvailable, thursdayAvailable, fridayAvailable, saturdayAvailable, sundayAvailable]
        },
        possibleTimes: function() {
            let {consultationStartTime, consultationEndTime} = this.consultationInformation
            let s = consultationStartTime ? moment(consultationStartTime).format('HH:mm') : '-'
            let e = consultationEndTime ? moment(consultationEndTime).format('HH:mm') : '-'
            return s + '~' + e
        },
        createdAt: function() {
            return moment(this.history.createdAt).format('YYYY-MM-DD')
        },
        isLinked: function() { return this.history.isLinked },
    },
}
</script>