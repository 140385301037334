import { render, staticRenderFns } from "./CategorySettingChildTypenamePopup.vue?vue&type=template&id=2441483e"
import script from "./CategorySettingChildTypenamePopup.vue?vue&type=script&lang=js"
export * from "./CategorySettingChildTypenamePopup.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports