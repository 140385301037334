<template>
    <div class="hospital_edit_page" v-if="isShow">
        <div class="page_title flex_aic_spb">
            <div class="flex_aic gap_10">
                <span>V2 애프터닥</span>
                <span>- {{ hospitalName }}</span>
            </div>
            <button @click="action_Close" class="close_btn large_txt lgray_txt">
                <span class="flex_aic"><img src="https://afterdoc.s3.ap-northeast-2.amazonaws.com/613aa681cb516152eed8fdb3/20241007113664/Group%201876.png">닫기</span>
            </button>
        </div>
        <div class="settings_edit_wrap flex_spb">
            <!-- 저장 후 나타나는 탭 메뉴 -->
            <LeftMenu
                :hospital="currentHospital"
                :page="currentPage"
                @changePage="changePage"
            />
            <!-- 기본설정 -->
            <BasicOptions
                :isShow="currentPage=='BasicOptions'"
                :hospital="currentHospital"
                @updateHospitalID="updateHospitalID"
                @reloadHospital="reloadHospital"
            />
            <!-- 업무시간 외 팝업 -->
            <NonBusinessHour
                :isShow="currentPage=='NonBusinessHour'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 자동응대 메세지 -->
            <ResponseAutomation
                :isShow="currentPage=='ResponseAutomation'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 홈케어 콘텐츠 설정 -->
            <HomecareContents
                :isShow="currentPage=='HomecareContents'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 스마트닥터 연동 -->
            <SmartDoctorIntegration
                :isShow="currentPage=='SmartDoctorIntegration'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 카카오톡 연동 -->
            <KakaoIntegration
                :isShow="currentPage=='KakaoIntegration'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 계정 설정 -->
            <AccountSetting
                :isShow="currentPage=='AccountSetting'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 치료태그 설정 -->
            <TreatmentTagSetting
                :isShow="currentPage=='TreatmentTagSetting'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 상담자동화 기본 템플릿 -->
            <AutomationBasicTemplate
                :isShow="currentPage=='AutomationBasicTemplate'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
            <!-- 서비스 상태 변경 -->
            <ServiceStatus
                :isShow="currentPage=='ServiceStatus'"
                :hospital="currentHospital"
                @reloadHospital="reloadHospital"
            />
        </div>
    </div>
</template>


<script>
import LeftMenu from './LeftMenu.vue'

import BasicOptions from './Subviews/BasicOptions.vue'
import NonBusinessHour from './Subviews/NonBusinessHour.vue'
import ResponseAutomation from './Subviews/ResponseAutomation.vue'
import HomecareContents from './Subviews/HomecareContents.vue'
import SmartDoctorIntegration from './Subviews/SmartDoctorIntegration.vue'
import KakaoIntegration from './Subviews/KakaoIntegration.vue'
import AccountSetting from './Subviews/AccountSetting.vue'
import TreatmentTagSetting from './Subviews/TreatmentTagSetting.vue'
import AutomationBasicTemplate from './Subviews/AutomationBasicTemplate/AutomationBasicTemplate.vue'
import ServiceStatus from './Subviews/ServiceStatus.vue'

export default {
    components: {
        LeftMenu,
        BasicOptions,
        NonBusinessHour,
        ResponseAutomation,
        HomecareContents,
        SmartDoctorIntegration,
        KakaoIntegration,
        AccountSetting,
        TreatmentTagSetting,
        AutomationBasicTemplate,
ServiceStatus,
    },
    props: {
        isShow: Boolean,
        hospitalID: String
    },
    data() {
        return {
            currentHospital: {},
            currentPage: 'BasicOptions'
        }
    },
    watch: {
        hospitalID: {
            immediate: true,
            handler: function() {
                this.reloadHospital()
            }
        },
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == true) {
                    this.currentPage = 'BasicOptions'
                }
            }
        }
    },
    computed: {
        hospitalName: function() {
            if (this.currentHospital?._id == undefined) { return '' }
            return this.currentHospital?.shortName ?? '-'
        }
    },
    methods: {
        // MARK: - Network
        async reloadHospital() {
            if (this.hospitalID == undefined || this.hospitalID == '') {
                this.currentHospital = {}
                return
            }
            let result = await this.API_V3.Hospital.findOne({_id: this.hospitalID})
            if (result.data.code != 0) {
                console.log(result)
                return
            }
            this.currentHospital = result.data.data
            // this.currentPage = 'BasicOptions'
        },
        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        },


        // Emits
        updateHospitalID(hospitalID) {
            this.$emit('editHospital', hospitalID)
        },
        changePage(page) {
            this.currentPage = page
        },
    }
}
</script>