<template>
    <div class="table_header_fix">
        <!-- 검색 데이터 없을 때 -->
        
        <!-- hospital data table -->
        <table class="table_default" v-if="isEmpty">
            <thead>
                <tr>
                    <th width="140" class="txt_left">작성자</th>
                    <th width="140" class="txt_left">등록일시</th>
                    <th width="80" class="txt_left">검수상태</th>
                    <th width="" class="txt_left">내용</th>
                    <th width="70" class="txt_left">첨부파일</th>
                </tr>
            </thead>
            <tr>
                <td colspan="5">
                    내용이 없습니다.
                </td>
            </tr>
        </table>

        <table class="table_default" v-if="!isEmpty">
            <thead>
                <tr>
                    <th width="140" class="txt_left">작성자</th>
                    <th width="140" class="txt_left">등록일시</th>
                    <th width="80" class="txt_left">검수상태</th>
                    <th width="" class="txt_left">내용</th>
                    <th width="70" class="txt_left">첨부파일</th>
                </tr>
            </thead>
            <AlrimtalkMessageEditCommentCell
                v-for="(comment, index) in comments"
                :key="index"
                :comment="comment"
            />
        </table>
    </div>
</template>


<script>
import AlrimtalkMessageEditCommentCell from './AlrimtalkMessageEditCommentCell.vue';
export default {
    components: {
        AlrimtalkMessageEditCommentCell
    },
    props: {
        comments: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isEmpty: function() {
            return this.comments.length == 0
        }
    }
}
</script>