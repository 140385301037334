<template>
    <div class="pop_overlay_bg" v-show="isShow">
        <div class="change_history_pop_wrap">
            <div class="pop_title">
                <span class="title">계정 수 제한 변경내역</span>
                <a class="mgl_auto" @click="action_Close">
                    <v-icon :size="24">mdi-close</v-icon>
                </a>
            </div>
            <div class="pop_cont">
                <div class="h_scroll" >
                    <div class="table_header_fix">
                        <!-- 변경 내역 없을 때 -->
                        <table class="table_default consult_talk" v-if="isEmpty">
                            <thead>
                                <tr>
                                    <th width="150" class="txt_left">변경일시</th>
                                    <th width="100" class="txt_left">최대 계정 수</th>
                                    <th width="" class="txt_left">사유</th>
                                    <th width="90" class="">수정자</th>
                                </tr>
                            </thead>
                            <tr>
                                <td colspan="4">변경 내역이 없습니다.</td>
                            </tr>
                        </table>
                        <!-- hospital data table -->
                        <table class="table_default consult_talk" v-if="!isEmpty">
                            <thead>
                                <tr>
                                    <th width="150" class="txt_left">변경일시</th>
                                    <th width="100" class="txt_left">최대 계정 수</th>
                                    <th width="" class="txt_left">사유</th>
                                    <th width="90" class="">수정자</th>
                                </tr>
                            </thead>
                            <AccountSettingHistoryItem
                                v-for="(item, index) in list"
                                :key="index"
                                :item="item"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import AccountSettingHistoryItem from './AccountSettingHistoryItem.vue'
export default {
    components: { AccountSettingHistoryItem },
    props: {
        isShow: Boolean,
        hospitalID: String,
    },
    data() {
        return {
            count: 0,
            list: [],
        }
    },
    watch: {
        isShow: {
            immediate: true,
            handler: function(newValue) {
                if (newValue == false) { return }
                this.loadAccountSettingHistory()
            }
        }
    },
    computed: {
        isEmpty: function() {
            return (this.list ?? []).length == 0
        }
    },
    methods: {
        // MARK: - Network
        async loadAccountSettingHistory() {
            if (this.hospitalID == undefined) { return }
            let result = await this.API_V3.Account.count({hospitalID: this.hospitalID})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            result = await this.API_V3.Account.find({hospitalID: this.hospitalID, limit: 100})
            if (result.data.code != 0) {
                alert(result.data.message)
                return
            }
            this.list = result.data.data
        },

        // MARK: - Actions
        action_Close() {
            this.$emit('close')
        }
    }
}
</script>