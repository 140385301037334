<template>
    <div>
        <div class="content_wrap">
            <div class="page_title"><span>전능 연동 정보 관리</span></div>
            <div class="h_scroll" style="justify-content: space-between">
                <div>
                    <div class="table_title">
                        <div class="cont">
                            <label class="check_wrap">
                                <input type="checkbox" v-model="isCheckList" />
                                <span class="checkmark"></span>연동 기록이 존재하는 병원 리스트만 보기
                            </label>
                        </div>
                        <div class="table_title_btn">
                            <input type="text" class="input_s" placeholder="병원명 검색" v-model="keyword" @keyup.enter="searchLoadHospitals(0)"/>
                            <button class="btn_s btn_blue_line mgl_5" @click="searchLoadHospitals(0)">검색</button>
                        </div>
                    </div>
                    <table class="table_default">
                        <thead>
                            <tr><th v-for="title in tableList" :key="title">{{title}}</th></tr>
                        </thead>
                        <thead v-if="isLoading == true">
                            <tr><td colspan="9">데이터 로드 중...</td></tr>
                        </thead>
                        <thead v-if="!isLoading">
                            <tr v-if="hData.length == 0"><td colspan="9">검색된 내역이 없습니다.</td></tr>
                            <tr v-for="h in hData" :key="h._id">
                                <td>
                                    {{h.shortName}}<div><small class="lgray_txt">({{ h.name }})</small></div>
                                </td>
                                <td>
                                    {{ h._id }}
                                </td>
                                <td>
                                    <label class="label" :class="{'lblue': isConnectAl(h) == '연동','lgray': isConnectAl(h) == '해제'}">{{isConnectAl(h)}}</label>
                                </td>
                                <td>
                                    <label class="label" :class="{'lblue': isUsingRegist(h) == '사용','lgray': isUsingRegist(h) == '미사용'}">{{isUsingRegist(h)}}</label>
                                </td>
                                <td>
                                    <label class="label" :class="{'lblue': isUsingReservation(h) == '사용','lgray': isUsingReservation(h) == '미사용'}">{{isUsingReservation(h)}}</label>
                                </td>
                                <td>
                                    <label class="label" :class="{'lblue': isUsingDashboard(h) == '사용','lgray': isUsingDashboard(h) == '미사용'}">{{isUsingDashboard(h)}}</label>
                                </td>
                                <td>
                                    <button class="btn btn_s btn_gray" @click="actionPopupOpen('isHospitalInfoOpen', h)" :disabled="!auth.integration_w">연동 설정</button>
                                </td>
                                <td>
                                    <button class="btn btn_s btn_gray" @click="actionPopupOpen('isFeatureConfigOpen', h)" :disabled="!auth.integration_w">기능 설정</button>
                                </td>
                                <td>
                                    <button class="btn btn_s btn_gray" @click="actionPopupOpen('isMessageLogViewerOpen', h)" :disabled="!auth.integration_w">기록 조회</button>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <Pagenation 
                    v-bind:totalCount="hTotalCount"
                    v-bind:currentPage="hCurrentPage"
                    v-bind:countPerPage="hCountPerPage"
                    v-bind:countPerRow="5"
                    v-on:changePage="hChangePage"
                />
            </div>
        </div>
        <HospitalInfoPopup @action_Close="action_Close" />
        <FeatureConfigPopup @action_Close="action_Close" />
        <MessageLogViewer />
    </div>
</template>

<script>
import {mapActions} from 'vuex';
import PageController from '../../../../../controller/PageController';
import HospitalInfoPopup from './Views/Popup/HospitalInfoPopup.vue';
import FeatureConfigPopup from './Views/Popup/FeatureConfigPopup.vue'
import MessageLogViewer from './Views/Popup/MessageLogViewer.vue'
import Pagenation from '../../../ShareComponents/PagenationComponent.vue';

export default {
    components: { HospitalInfoPopup, FeatureConfigPopup, MessageLogViewer, Pagenation },
    data() {
        return {
            isLoading: false,
            isCheckList: false,
            keyword: '', 

            // page controller
            alPageController: undefined,
            hData: [],
            hTotalCount: 0,
            hCurrentPage: 0,
            hCountPerPage: 10,
        }
    },
    watch: {
        currentPath: {
            immediate: true,
            handler: function(newValue) {
                if(newValue == '/syncmanagement') {
                    this.setAlMessageLogViewer({
                        isShow: false,
                        selectedHospital: undefined
                    })
                }}
        },
        keyword: {
            handler: function (newValue) {
                if (newValue == '') {
                    if(this.isCheckList == true) {
                        this.loadAlHospitals(0)
                    }
                    if(this.isCheckList == false) {
                        this.loadHospitals(0)
                    }
                }}
        },
        isCheckList: {
            handler: function(newValue) {
                this.hCurrentPage = 0;
                if(newValue == true) {
                    this.loadAlHospitals(0)
                } 
                if(newValue == false) {
                    this.loadHospitals(0)
                }
        }}
    },
    computed: {
        currentPath: function() {
            return this.$route.path
        },
        tableList: function() {
            return ['병원명', '애프터닥 병원 ID', '전능연동', '문자발송기능', '예약시트', '대시보드', '연동 사용 설정', '기능 사용 설정', '연동 문자 기록 조회']
        }
    },
    mounted() {
        this.alPageController = new PageController(this.API.Hospital.getHospitals)
        this.alPageController.countPerPage = this.hCountPerPage
        this.loadHospitals(this.hCurrentPage)
    },
    methods: {
        ...mapActions(['setAlhospitalInfoPopupState', 'setAlFeatureConfigPopupState', 'setAlMessageLogViewer']),

        // MARK: - Action
        hChangePage(page) {
            this.hCurrentPage = page
            if(this.isCheckList == true) {
             this.loadAlHospitals(this.hCurrentPage)
            }
            if(this.isCheckList == false) {
             this.loadHospitals(this.hCurrentPage)
            }
        },
        actionPopupOpen(r, hospital) {
            let model = { 
                isShow: true, 
                selectedHospital: hospital
            }
            let f_model = {
                isShow: true, 
                selectedHospital: hospital,
                token: hospital?.integrationSetting?.clientToken
            }
            if (r == 'isHospitalInfoOpen') {
                this.setAlhospitalInfoPopupState(model)
            }
            if (r == 'isFeatureConfigOpen') {
                this.setAlFeatureConfigPopupState(f_model)
            }
            if (r == 'isMessageLogViewerOpen') {
                this.setAlMessageLogViewer(model)
            }
        },
        action_Close() {
            if(this.isCheckList == false) { this.loadHospitals(this.hCurrentPage) } 
            else { this.loadAlHospitals(this.hCurrentPage) } 
        },
        
        // MARK: - Network
        async loadHospitals(page) {
            this.isLoading = true
            this.alPageController.filter = {}
            if (this.keyword != '') { this.alPageController.filter.keyword = this.keyword }   

            await this.alPageController.loadPageIndex(page)
            await this.loadHospitalsCount()
            
            this.hData = this.alPageController.data
            this.isLoading = false
        },
        async loadAlHospitals(page) {
            this.isLoading = true       
            this.alPageController.filter = {}
            if (this.keyword != '') { this.alPageController.filter.keyword = this.keyword } 
        
            await this.alPageController.loadPageIntegrationSetting(page)
            
            let result = await this.API.Hospital.getHospitals()
            if (result.data.code != 0) { return }
            result = result.data.data
            result = result.filter(obj => Object.prototype.hasOwnProperty.call(obj, 'integrationSetting'))
    
            if(this.keyword != '') {
                this.hTotalCount = this.alPageController.totalCount
            } else {
                this.hTotalCount = result.length
            }

            this.hData = this.alPageController.data
            this.isLoading = false
        },
        async loadHospitalsCount() {
            let result = await this.API.Hospital.countHospitals(this.alPageController.filter)
            if (result.data.code != 0) {
                console.log(result)
                return
            } 
            this.hTotalCount = result.data.data
        },
        async searchLoadHospitals(page) {
            if(this.isCheckList == false) {
                this.loadHospitals(page)
            }
            if(this.isCheckList == true) {
                this.loadAlHospitals(page)
            }
        },

        // MARK: - variables
        isConnectAl(data) {
            if(data == undefined ) {return}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting.isRemoved == undefined) {return '-'}

            return data.integrationSetting.isRemoved == true ? '해제' : '연동'
        },
        isUsingRegist(data) {
            if(data == undefined ) {return}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting.usingRegist == undefined) {return '-'}

            return data.integrationSetting.usingRegist == true ? '사용' : '미사용'
        },
        isUsingReservation(data) {
            if(data == undefined ) {return}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting.usingReservation == undefined) {return '-'}

            return data.integrationSetting.usingReservation == true ? '사용' : '미사용'
        },
        isUsingDashboard(data) {
            if(data == undefined ) {return}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting == undefined) {return '-'}
            if(data.integrationSetting.usingDashboard == undefined) {return '-'}

            return data.integrationSetting.usingDashboard == true ? '사용' : '미사용'
        },
    }
}
</script>

<style>
</style>

